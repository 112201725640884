
<template>
    <div v-if="seasonsDataAvailable" class="w-full relative">
        <button
            :class="['absolute top-6 left-14 w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isAddingDraggable, 'bg-slate-400 text-black': isAddingDraggable }]"
            @click="addDraggableDiv">
            <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${addBarSvg})` }"></div>
        </button>
        <div id="d3-bar-chart-season" class="flex"></div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, watch, ref, onMounted } from 'vue';
import { Season, SeasonData, SeasonClusterData } from '@/types'
import * as d3 from 'd3';
export default defineComponent({
    name: 'BarSeasonsD3',
    props: {
        seasonsData: {
            type: Array as PropType<Season[]>,
            required: true,
        },
        seasonClusters: {
            type: Array as PropType<SeasonClusterData[][]>,
            required: true
        },
        barChartSeasonTrigger: {
            type: Number,
            required: true
        }
    },
    emits: ['update-season-cluster'],
    setup(props, { emit }) {
        // UI element references
        const draggableDivPosition = ref(0);
        const addBarSvg = require('@/assets/add_bar.svg');

        // Responsive data variables
        const seasonsDataAvailable = computed(() => props.seasonsData && props.seasonsData.length > 0);
        const seasonOrder = ref<string[]>(['Winter', 'Spring', 'Summer', 'Autumn']);
        const seasonClusters = ref<SeasonData[][]>([]);
        const isAddingDraggable = ref<boolean>(false);
        const shorthandSeasons = (season: string): string => {
            const seasonMap: { [key: string]: string } = {
                winter: 'Winter',
                spring: 'Lente',
                summer: 'Zomer',
                autumn: 'Herfst',
            };
            return seasonMap[season.toLowerCase()] || season;
        }
        const margin = { top: 20, right: 30, bottom: 40, left: 50 };
        const height = 280 - margin.top - margin.bottom;
        const dividerPositions = ref<{ id: string, position: number }[]>([]);
        const xGlobal = ref<any>(null);
        const yGlobal = ref<any>(null);
        let originalYDomain: [number, number];


        // Determining the data for the bars
        const seasonsBarData = computed<SeasonData[]>(() => {
            if (!seasonsDataAvailable.value) return [];

            const sortedSeasonsData = [...props.seasonsData].sort((a, b) => {
                return seasonOrder.value.indexOf(a.season as string) - seasonOrder.value.indexOf(b.season as string);
            });

            return sortedSeasonsData.map((season) => {
                return {
                    season: season.season,
                    seconds: season.average_time
                };
            });
        });

        const updateClusters = () => {
            if (!seasonsBarData.value.length || !xGlobal.value) return;

            const clusters: SeasonData[][] = [];

            // Define the order of days in a week
            const seasonOrder = ["Winter", "Spring", "Summer", "Autumn"];

            // Filter out bars without a valid day and sort by the day order
            const sortedBars = [...seasonsBarData.value]
                .filter((bar): bar is SeasonData & { day: string } => bar.season !== undefined) // Ensure day is defined
                .sort((a, b) => seasonOrder.indexOf(a.day!) - seasonOrder.indexOf(b.day!));
            // Sort divider positions by `position`
            const sortedDivs = [...dividerPositions.value]
                .map(div => div.position)
                .sort((a, b) => a - b);

            let currentCluster: SeasonData[] = [];
            let dividerIndex = 0; // Track the current divider we're processing


            sortedBars.forEach((bar) => {
                const barPosition = xGlobal.value(bar.season) + xGlobal.value.bandwidth() / 2 // Use the index of the bar in sortedBars as barPosition

                if (dividerIndex >= sortedDivs.length || barPosition < sortedDivs[dividerIndex]) {
                    // Add bar to the current cluster if it’s before the current divider or no more dividers
                    currentCluster.push(bar);
                } else {
                    // If we've reached a divider, push the current cluster and start a new one
                    while (dividerIndex < sortedDivs.length && barPosition >= sortedDivs[dividerIndex]) {
                        if (currentCluster.length) {
                            clusters.push(currentCluster);
                        }
                        currentCluster = [];
                        dividerIndex++; // Move to the next divider
                    }
                    currentCluster.push(bar);
                }
            });

            // Push the last cluster if it has any bars
            if (currentCluster.length) {
                clusters.push(currentCluster);
            }

            seasonClusters.value = clusters;
            handleUpdateSettings();
        };

        const handleUpdateSettings = () => {
            emit('update-season-cluster', seasonClusters.value)
        }

        let dividerIdCounter = 1;
        const generateDividerId = () => {
            return `div-${dividerIdCounter++}`;
        };

        const addDraggableDiv = () => {
            isAddingDraggable.value = !isAddingDraggable.value;
        };

        // Function to place dividers
        const onGraphLeftClick = (event: MouseEvent) => {
            if (event.button !== 0) return; // Only proceed if the left button is clicked
            event.preventDefault();

            if (isAddingDraggable.value) {
                const svgContainer = d3.select('#d3-bar-chart-season').select('svg');
                const xPosition = event.offsetX; // Get the x-position of the click

                if (xPosition < margin.left || xPosition > svgContainer.attr('width') - margin.right) {
                    return; // Don't add the divider if clicked outside the graph boundaries
                }

                // Generate a unique ID for the divider
                const dividerId = generateDividerId();

                // Add a gray rectangle for hover highlight
                const highlightArea = svgContainer
                    .append('rect')
                    .attr('x', xPosition - 10) // Adjust x to center the gray bar
                    .attr('y', margin.top) // Start at the top margin
                    .attr('width', 20) // Width of 20px for highlighting area
                    .attr('height', height + margin.top) // Full height of the chart
                    .attr('fill', '#e5e7eb') // Tailwind gray-200
                    .attr('opacity', 0) // Set opacity to 0.7
                    .attr('pointer-events', 'all') // Enable pointer events for this highlight
                    .attr('clip-path', 'url(#clip-dividers)')
                // Bring the highlight area to the front

                // Append a vertical line (divider) at the clicked position
                const divider = svgContainer
                    .append('line')
                    .attr('x1', xPosition)
                    .attr('x2', xPosition)
                    .attr('y1', margin.top)
                    .attr('y2', height + margin.top)
                    .attr('stroke', 'black')
                    .attr('stroke-width', 2)
                    .attr('clip-path', 'url(#clip-dividers)')
                    .attr('pointer-events', 'none'); // Disable pointer events on the divider

                // Add the x-position to dividerPositions with the generated ID
                dividerPositions.value.push({ id: dividerId, position: xPosition - margin.left });
                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position

                // Add right-click listener to remove both the divider and highlight area
                highlightArea.on('contextmenu', (event: MouseEvent) => {
                    event.preventDefault();
                    highlightArea.remove(); // Remove the highlight area
                    divider.remove(); // Remove the divider line

                    // Remove the corresponding divider from the array by ID
                    dividerPositions.value = dividerPositions.value.filter(divider => divider.id !== dividerId);
                    // Re-cluster trips after removal
                    updateClusters();
                });

                // D3 drag behavior for dragging the highlight area
                const dragBehavior = d3.drag()
                    .on('start', function () {
                        highlightArea.raise().attr('opacity', 0.7); // Bring the dragged highlight to the front and make it visible
                    })
                    .on('drag', function (event: any) {
                        const newXPosition = event.x;

                        // Ensure the new x position stays within the left and right margin boundaries
                        if (newXPosition >= margin.left && newXPosition <= svgContainer.attr('width') - margin.right) {
                            highlightArea.attr('x', newXPosition - 10); // Adjust position while dragging
                            divider.attr('x1', newXPosition).attr('x2', newXPosition); // Move the divider line with the highlight

                            // Update the corresponding divider position by ID
                            const dividerIndex = dividerPositions.value.findIndex(d => d.id === dividerId);
                            if (dividerIndex !== -1) {
                                dividerPositions.value[dividerIndex].position = newXPosition - margin.left;
                                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position
                            }
                        }
                    })
                    .on('end', function () {
                        highlightArea.attr('opacity', 0); // Set opacity back after dragging is done
                        // Re-cluster trips after dragging
                        updateClusters();
                    });
                highlightArea.call(dragBehavior); // Attach drag behavior to the highlight area

                // Track hover state with a debounce to avoid flickering
                let isHovering = false;
                let debounceTimeout: any = null;

                const setHoverState = (opacity: number, delay = 0) => {
                    clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                        highlightArea.attr('opacity', opacity);
                        isHovering = opacity > 0; // Set hover state based on opacity
                    }, delay);
                };

                // Add hover effect to the highlight area
                highlightArea
                    .on('mouseenter', () => {
                        if (!isHovering) {
                            setHoverState(0.5); // Show the highlight
                        }
                    })
                    .on('mouseleave', () => {
                        if (isHovering) {
                            setHoverState(0, 50); // Hide the highlight with a short delay
                        }
                    });

                // Cluster trips based on dividers and emit
                updateClusters();
            }
            renderLegend();
        };

        const placeDividersFromClusters = () => {
            const svgContainer = d3.select('#d3-bar-chart-season').select('svg');

            // Loop over clusters to determine where dividers should be placed
            for (let i = 1; i < props.seasonClusters.length; i++) {
                const previousClusterLastDay = props.seasonClusters[i - 1][props.seasonClusters[i - 1].length - 1].season;
                const currentClusterFirstDay = props.seasonClusters[i][0].season;

                // Calculate x-position between the previous and current cluster based on day order
                const seasonOrder = ["Winter", "Spring", "Summer", "Autumn"];
                const previousDayIndex = seasonOrder.indexOf(previousClusterLastDay!);
                const currentDayIndex = seasonOrder.indexOf(currentClusterFirstDay!);

                if (previousDayIndex === -1 || currentDayIndex === -1) continue; // Skip if day is not in dayOrder

                // Calculate divider position as midpoint between days
                const dividerX = margin.left + (xGlobal.value(previousClusterLastDay) + xGlobal.value(currentClusterFirstDay) + xGlobal.value.bandwidth()) / 2;

                // Generate a unique ID for the divider
                const dividerId = generateDividerId();

                // Add highlight area for the divider
                const highlightArea = svgContainer
                    .append('rect')
                    .attr('x', dividerX - 10)
                    .attr('y', margin.top)
                    .attr('width', 20)
                    .attr('height', height)
                    .attr('fill', '#e5e7eb')
                    .attr('opacity', 0)
                    .attr('pointer-events', 'all')
                    .attr('clip-path', 'url(#clip-dividers)')
                    .raise();

                // Add the divider line at the calculated x position
                const divider = svgContainer
                    .append('line')
                    .attr('x1', dividerX)
                    .attr('x2', dividerX)
                    .attr('y1', margin.top)
                    .attr('y2', height + margin.top)
                    .attr('stroke', 'black')
                    .attr('stroke-width', 2)
                    .attr('clip-path', 'url(#clip-dividers)')
                    .attr('pointer-events', 'none');

                // Add the divider position to dividerPositions array
                dividerPositions.value.push({ id: dividerId.toString(), position: dividerX - margin.left });
                dividerPositions.value.sort((a, b) => a.position - b.position);

                // Right-click to remove divider
                highlightArea.on('contextmenu', (event: MouseEvent) => {
                    event.preventDefault();
                    highlightArea.remove();
                    divider.remove();
                    dividerPositions.value = dividerPositions.value.filter(div => div.id !== dividerId.toString());
                    updateClusters();
                });

                // D3 drag behavior to move the divider
                const dragBehavior = d3.drag()
                    .on('start', () => highlightArea.raise().attr('opacity', 0.7))
                    .on('drag', (event: any) => {
                        const newXPosition = event.x;

                        if (newXPosition >= margin.left && newXPosition <= svgContainer.attr('width') - margin.right) {
                            highlightArea.attr('x', newXPosition - 10);
                            divider.attr('x1', newXPosition).attr('x2', newXPosition);

                            const dividerIndex = dividerPositions.value.findIndex(d => d.id === dividerId.toString());
                            if (dividerIndex !== -1) {
                                dividerPositions.value[dividerIndex].position = newXPosition - margin.left;
                                dividerPositions.value.sort((a, b) => a.position - b.position);
                            }
                        }
                    })
                    .on('end', function () {
                        highlightArea.attr('opacity', 0); // Set opacity back after dragging is done
                        // Re-cluster trips after dragging
                        updateClusters();
                    });

                highlightArea.call(dragBehavior);

                // Track hover state with a debounce to avoid flickering
                let isHovering = false;
                let debounceTimeout: any = null;

                const setHoverState = (opacity: number, delay = 0) => {
                    clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                        highlightArea.attr('opacity', opacity);
                        isHovering = opacity > 0; // Set hover state based on opacity
                    }, delay);
                };

                // Add hover effect to the highlight area
                highlightArea
                    .on('mouseenter', () => {
                        if (!isHovering) {
                            setHoverState(0.5); // Show the highlight
                        }
                    })
                    .on('mouseleave', () => {
                        if (isHovering) {
                            setHoverState(0, 50); // Hide the highlight with a short delay
                        }
                    });
            }
            renderLegend();
        };


        const svgContainer = d3.select('#d3-bar-chart-season').select('svg');
        svgContainer.on('mousemove', function (event: MouseEvent) {
            const xPosition = event.offsetX;
            const withinBounds = xPosition >= margin.left && xPosition <= svgContainer.attr('width') - margin.right;

            // Only change the cursor when isAddingDraggable mode is active
            if (isAddingDraggable.value) {
                svgContainer.style('cursor', withinBounds ? 'crosshair' : 'default');
            } else {
                svgContainer.style('cursor', 'default'); // Set cursor to default when not in addDraggable mode
            }
        });

        const renderBarChart = () => {
            const chartContainer = document.getElementById('d3-bar-chart-season');
            if (!chartContainer) return;

            // Use the full available width of the container
            const width = chartContainer.clientWidth - margin.left - margin.right;

            d3.select('#d3-bar-chart-season').selectAll('*').remove();

            // Create SVG container
            const svg = d3
                .select('#d3-bar-chart-season')
                .append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom);

            const chartGroup = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`)

            // Group for axes (this will be outside the clip path)
            const axesGroup = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`)

            const uniqueSeasons = [...new Set(seasonsBarData.value.map(entry => entry.season))];
            // Y scale and axis (in minutes and seconds)
            const maxTime = d3.max(seasonsBarData.value, (d: any) => d.seconds) as number;
            const minTime = d3.min(seasonsBarData.value, (d: any) => d.seconds) as number;
            const buffer = 60; // 1 minute in seconds
            const yMin = Math.floor(minTime / 60) * 60 - buffer; // Round down to nearest whole minute
            const yMax = Math.ceil(maxTime / 60) * 60 + buffer; // Round up to nearest whole minute and add buffer

            // Define scales for Y and X
            const timeParser = d3.timeParse('%H:%M:%S'); // Parse time in 'HH:mm:ss' format
            const parsedTrips = seasonsBarData.value.map(d => ({
                ...d,
                parsedTime: timeParser(d.seconds) as Date,
            }));
            // parsedTripsGlobal.value = parsedTrips;

            xGlobal.value = d3.scaleBand()
                .domain(uniqueSeasons)
                .range([0, width])
                .padding(0.1);

            const xAxis = d3.axisBottom(xGlobal.value)
                .tickFormat(shorthandSeasons);

            svg.append("g")
                .attr("transform", `translate(${margin.left}, ${height + margin.top})`)
                .call(xAxis)
                .call((g: any) => {
                    // Make the axis line and ticks gray
                    g.selectAll("path, line")
                        .attr("stroke", "#e5e7eb");

                    // Increase font size of the tick labels
                    g.selectAll("text")
                        .style("font-size", "14px")
                        .style('font-family', 'Inter, sans-serif'); // Set desired font size
                });
            originalYDomain = [yMin, yMax];
            yGlobal.value = d3.scaleLinear().domain(originalYDomain).range([height, 0]);

            // Add background gridlines (X and Y) inside the chart group
            const gridGroup = chartGroup.append('g').attr('class', 'grid');

            // Add Y-axis gridlines (horizontal gridlines)
            const yTickValues = d3.range(yMin, yMax, 60); // Ensure ticks are at 1-minute intervals

            gridGroup.append('g')
                .call(
                    d3.axisLeft(yGlobal.value)
                        .tickSize(-width) // Extend ticks to form horizontal gridlines
                        .tickValues(yTickValues) // Use the same tick values as Y-axis
                        .tickFormat('')   // Remove tick labels for gridlines
                )
                .selectAll('line')
                .attr('stroke', '#e5e7eb'); // Tailwind gray-200 for gridlines

            // Add X-axis gridlines (vertical gridlines)
            gridGroup.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(
                    d3.axisBottom(xGlobal.value)
                        .tickSize(-height) // Extend ticks to form vertical gridlines
                        .tickFormat('')    // Remove tick labels
                )
                .selectAll('line')
                .attr('stroke', '#e5e7eb'); // Tailwind gray-200 for gridlines

            // Add Y-axis with whole minute ticks and give it a class 'y-axis'
            axesGroup.append('g')
                .attr('class', 'y-axis') // Add class for later selection
                .call(
                    d3.axisLeft(yGlobal.value)
                        .tickValues(d3.range(yMin, yMax, 60)) // Ensure ticks are at 1-minute intervals
                        .tickFormat((d: number, i: number) => {
                            // Show label only for every other tick if more than 12 ticks
                            const minutes = Math.floor(d / 60);
                            return yTickValues.length > 10 && i % 2 !== 0 ? '' : `${minutes}:00`;
                        })
                )
                .selectAll('path, line')
                .attr('stroke', '#e5e7eb'); // Apply stroke to Y-axis lines

            // Select text elements on Y-axis separately for styling
            axesGroup.selectAll('.y-axis text')
                .style('font-size', '12px')
                .style('font-family', 'Inter, sans-serif'); // Apply font size to Y-axis text


            // Bars: Position the bars based on the exact 'start_time' (inside the clipped chartGroup)
            chartGroup.selectAll('.bar')
                .data(parsedTrips)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('x', (d: any) => (xGlobal.value(d.season) as number) + xGlobal.value.bandwidth() / 2 - 3) // Center bar in category
                .attr('y', (d: any) => yGlobal.value(d.seconds))
                .attr('width', 6) // Use a fraction of the band width for better spacing
                .attr('height', (d: any) => height - yGlobal.value(d.seconds))
                .attr('fill', '#93c5fd');

            // Add a border around the entire chart area (inside the clipped chartGroup)
            chartGroup.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', width)
                .attr('height', height)
                .attr('fill', 'none')
                .attr('stroke', '#e5e7eb') // Set the border color to gray-200
                .attr('stroke-width', 2);  // Set stroke width for the border

            renderLegend();
        };

        const renderLegend = () => {
            // Define the legend data
            const legendData = [
                { label: 'Rijtijd voorspeld', color: '#93c5fd' }, // Tailwind blue-300
                // Add more items if needed
            ];

            // Get the width of the chart to place the legend on the top right
            const chartWidth = d3.select('#d3-bar-chart-season').select('svg').attr('width');
            const legendX = +chartWidth - margin.right - 150; // Adjust as needed
            const legendY = margin.top + 10;
            const legendWidth = 140; // Set the width of the background box
            const legendHeight = legendData.length * 20 + 10;

            // Append a legend group to the SVG
            const legendGroup = d3.select('#d3-bar-chart-season').select('svg')
                .append('g')
                .attr('class', 'legend')
                .attr('transform', `translate(${legendX}, ${legendY})`)
                .raise();

            legendGroup.append('rect')
                .attr('width', legendWidth)
                .attr('height', legendHeight)
                .attr('fill', 'white')
                .attr('stroke', '#e5e7eb') // Tailwind gray-200 for border
                .attr('stroke-width', 2)
                .attr('rx', 2) // Border radius for rounded corners
                .attr('ry', 2) // Border radius for rounded corners
                .raise();

            // Add legend items
            legendData.forEach((item, index) => {
                const legendItem = legendGroup.append('g')
                    .attr('transform', `translate(6, ${index * 20 + 8})`); // Offset each item vertically

                // Add the color box
                legendItem.append('rect')
                    .attr('transform', `translate(6, 0)`)
                    .attr('width', 12)
                    .attr('height', 12)
                    .attr('fill', item.color);

                // Add the label text
                legendItem.append('text')
                    .attr('transform', `translate(6, -2)`)
                    .attr('x', 20) // Position the text slightly to the right of the color box
                    .attr('y', 10)
                    .text(item.label)
                    .style('font-size', '12px')
                    .style('font-family', 'Inter, sans-serif')
                    .attr('alignment-baseline', 'middle');
            });
        };



        const handleReload = () => {
            // Then render the bar chart
            dividerPositions.value = [];
            renderBarChart();

            // First check and initialize the dividers from the timeClusters
            if (props.seasonClusters) {
                // Initialize dividers based on the timeCluster data

                placeDividersFromClusters();
            }

            // Ensure chart resizes when the window is resized
            window.addEventListener('resize', handleResize);

            const svgElement = document.getElementById('d3-bar-chart-season');
            if (svgElement) {
                const svgContainer = d3.select(svgElement).select('svg');

                // Attach the mousedown event to handle left-clicks for dividers
                svgElement.addEventListener('mousedown', onGraphLeftClick);

                // Attach mousemove event to change cursor to crosshair when in the margin bounds and in addDraggable mode
                svgContainer.on('mousemove', function (event: MouseEvent) {
                    const xPosition = event.offsetX;
                    const withinBounds = xPosition >= margin.left && xPosition <= svgContainer.attr('width') - margin.right;

                    if (isAddingDraggable.value && withinBounds) {
                        d3.select(svgElement).style('cursor', 'crosshair');
                    } else {
                        d3.select(svgElement).style('cursor', 'default');
                    }
                });
            }
        };



        // Function to re-render the chart on resize
        const handleResize = () => {
            handleReload();
        };

        onMounted(() => {
            handleReload();
        });

        watch(
            dividerPositions.value,
            () => {
                updateClusters();
            },
            { immediate: true, deep: true }
        );

        watch(() => props.barChartSeasonTrigger, () => {
            handleReload();
        })



        return {
            draggableDivPosition,
            addBarSvg,
            addDraggableDiv,
            isAddingDraggable,
            seasonsDataAvailable

        };
    },
});
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
}

.div-2 {
    padding-left: 16px;
    padding-right: 16px;
}

/* Container for hover effect */
.hover-container {
    @apply absolute bottom-0 flex justify-center;
    height: 100%;
    width: 20px;
    /* Expanded width for hover effect */
    cursor: ew-resize;
}

/* Skinny div */
.draggable-div {
    @apply bg-gray-600 absolute bottom-0;
    width: 4px;
    height: 75%;
    transition: all 0.3s ease;
    /* Smooth transition */
    z-index: 10;
}

/* Hover effect */
.hover-container::before {
    @apply absolute w-full h-full bg-black bg-opacity-0 transition-opacity duration-300 ease-in-out;
    content: '';
}

.hover-container:hover::before {
    @apply bg-opacity-10;
}


</style>
