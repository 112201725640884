<template>
  <div class="flex-grow h-96 overflow-auto">
    <div class="block-route-cluster flex flex-col flex-grow overflow-hidden">
      <div
        class="relative flex flex-col items-start overflow-y-auto overflow-x-hidden whitespace-normal h-auto pl-1 pr-2 mb-0">
        <!-- Header row for Meet and Tijd labels -->

        <div class="relative flex flex-col items-start w-full pl-1">
          <div v-for="(block, index) in routeOrderRef" :key="index"
            class="block-container h-auto flex items-center w-full">
            <div v-if="block.type !== 'rijtijd'" class="flex items-center hover:bg-gray-200 w-full">
              <label class="checkbox-wrapper h-auto flex items-center my-1 w-full">
                <!-- First checkbox for meethalte -->
                <!-- Comment back out if we want meethaltes
                <input v-if="block.type !== 'rijtijd' && block.type !== 'missing'" v-model="block.meethalte"
                  :disabled="index === 0 || index === routeOrderRef.length - 1"
                  :checked="index === 0 || index === routeOrderRef.length - 1" @change="onMeethalteChange(block)"
                  type="checkbox" class="mr-6 transform scale-150 cursor-pointer">
                -->
                <!-- Second checkbox for tijdhalte -->
                <input v-if="block.type !== 'rijtijd' && block.type !== 'missing'" v-model="block.tijdhalte"
                  :disabled="index === 0 || index === routeOrderRef.length - 1 || block.type === 'meethalte'" @change="onTijdhalteChange(block)"
                  type="checkbox" class="mr-2 transform scale-150 cursor-pointer">
                <span v-if="block.id_userstopname_combination && block.type !== 'missing'" :class="lineClasses(block)"
                  class=" text-sm ml-2 w-full">
                  {{ block.id_userstopname_combination }}
                </span>
                <span v-else class="text-sm ml-2 w-full text-red-400">
                  {{ block.id_userstopname_combination }}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType, onMounted } from 'vue';
import { Route, LinePlanningSettings } from '@/types';

export default defineComponent({
  name: 'LineClusteringOverview',
  emits: ['update-settings'],
  props: {
    routeOrder: {
      type: Array as PropType<Route[]>,
      required: true
    },
    settings: {
      type: Object as PropType<LinePlanningSettings>,
      required: true
    },
  },
  setup(props, { emit }) {
    // Reference to UI elements
    const blockRefs = ref<(HTMLElement | null)[]>([]);

    // Responsive data variables
    const routeOrderRef = ref<Route[]>([]);

    const setBlockRefs = () => {
      blockRefs.value = blockRefs.value.slice(0, props.routeOrder.length);
    };

    // Functions for determining block appearance
    const getShortHandBlock = (type: string): string => {
      if (type === 'meethalte') {
        return 'meet';
      }
      if (type === 'tijdhalte') {
        return 'tijd';
      }
      if (type === 'starthalte') {
        return 'start';
      }
      if (type === 'eindhalte') {
        return 'eind';
      }
      return type;
    };

    const lineClasses = (block: Route) => {
      let textAboveLineClasses = '';
      if (block.type === 'rijtijd') {
        textAboveLineClasses = ' bottom-full mb-1';
      }
      return textAboveLineClasses;
    };

    const setInitialStops = (settings: LinePlanningSettings) => {
      if (settings && settings.stop_combinations && settings.stop_combinations.length > 0) {
        settings.stop_combinations.forEach((stop, index) => {
          if (!settings.stop_combinations) {
            throw new Error("No valid stop combinations");
          }
          if (index === 0) {
            stop.type = 'starthalte';
          } else if (index === settings.stop_combinations.length - 1) {
            stop.type = 'eindhalte';
          } else if (stop.type === 'missing') {
            stop.type = 'missing';
          } else if (stop.id_areacode_combination?.split('_')[0] !== stop.id_areacode_combination?.split('_')[1]) {
            stop.type = 'rijtijd';
          } else {
            stop.type = stop.istimingstop ? 'meethalte' : 'tijdhalte';
          }
        });

        routeOrderRef.value = settings.stop_combinations;
      } else {
        routeOrderRef.value.forEach(route => {
          if (['starthalte', 'eindhalte', 'meethalte'].includes(route.type as string)) {
            route.active = true;
            route.meethalte = true;
          } else {
            route.active = false;
            route.meethalte = false;
          }
        });
      }

      emit('update-settings', routeOrderRef.value);
    };

    const onMeethalteChange = (block: Route) => {
      const blockIndex = routeOrderRef.value.findIndex(b => b === block);

      if (blockIndex > 0) {
        // Get the previous block
        const previousBlock = routeOrderRef.value[blockIndex - 1];

        // Set previous block's meethalte to the same value as the current block
        previousBlock.meethalte = block.meethalte;
        // If meethalte is checked, ensure tijdhalte is unchecked for the current block
        if (block.meethalte) {
          block.tijdhalte = false;
          previousBlock.tijdhalte = false;
        }
      }

      emitRouteOrderRef();
    };

    const onTijdhalteChange = (block: Route) => {
      const blockIndex = routeOrderRef.value.findIndex(b => b === block);

      if (blockIndex > 0) {
        // Get the previous block
        const previousBlock = routeOrderRef.value[blockIndex - 1];

        // Set previous block's tijdhalte to the same value as the current block
        previousBlock.tijdhalte = block.tijdhalte;

        // If tijdhalte is checked, ensure meethalte is unchecked for the current block
        if (block.tijdhalte) {
          block.meethalte = false;
          previousBlock.meethalte = false;
        }
      }

      
      emitRouteOrderRef();
    };

    const emitRouteOrderRef = () => {
      emit('update-settings', routeOrderRef.value);
    };

    onMounted(() => {
      setBlockRefs();
      setInitialStops(props.settings);
    });

    return {
      blockRefs,
      getShortHandBlock,
      routeOrderRef,
      emitRouteOrderRef,
      lineClasses,
      onMeethalteChange,
      onTijdhalteChange,
    };
  },
});
</script>

<style scoped></style>