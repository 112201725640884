<template>
    <div class="w-96 max-w-lg bg-gray-200 p-4 rounded-lg shadow-md">
        <!-- Retrain Session Name -->
        <div class="mb-1">
            <div class="flex items-center">
                <label class="block text-gray-700 text-sm font-bold" for="retrain-session-name">
                    Naam van hertrainingsessie:
                </label>
                <div class="ml-2 relative group">
                    <span class="text-blue-600 cursor-pointer">[?]</span>
                    <div
                        class="absolute hidden group-hover:block bg-gray-300 text-gray-700 text-sm rounded-lg p-2 shadow-lg -top-12 left-0 w-64">
                        Vul een herkenbare naam in zodat deze trainsessie van het model terug is te vinden.
                    </div>
                </div>
            </div>
            <input id="retrain-session-name" autocomplete="off" v-model="retrainSessionName" placeholder="Voer een naam in voor de sessie"
                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                type="text">
        </div>

        <!-- Include Date Ranges -->
        <div class="mb-1">
            <div class="flex items-center">
                <label class="block text-gray-700 text-sm font-bold">Datums om op te nemen:</label>
                <div class="ml-2 relative group">
                    <span class="text-blue-600 cursor-pointer">[?]</span>
                    <div
                        class="absolute hidden group-hover:block bg-gray-300 text-gray-700 text-sm rounded-lg p-2 shadow-lg -top-12 left-0 w-64">
                        Voer datums in het formaat DD/MM/YYYY, of een bereik
                        zoals DD/MM/YYYY-DD/MM/YYYY in. Gebruik puntkomma's om meerdere waarden te scheiden.
                    </div>
                </div>
            </div>
            <textarea id="include-date-range" v-model="includeDates"
                placeholder="Bijv: 01/01/2020-31/12/2022"
                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 resize-y overflow-auto"
                rows="2"></textarea>
        </div>

        <!-- Exclude Date Ranges -->
        <div class="mb-1">
            <div class="flex items-center">
                <label class="block text-gray-700 text-sm font-bold">Datums om uit te sluiten:</label>
                <div class="ml-2 relative group">
                    <span class="text-blue-600 cursor-pointer">[?]</span>
                    <div
                        class="absolute hidden group-hover:block bg-gray-300 text-gray-700 text-sm rounded-lg p-2 shadow-lg -top-12 left-0 w-64">
                        Voer datums in het formaat DD/MM/YYYY, of een bereik
                        zoals DD/MM/YYYY-DD/MM/YYYY in. Gebruik puntkomma's om meerdere waarden te scheiden.
                    </div>
                </div>
            </div>
            <textarea id="exclude-date-range" v-model="excludeDates"
                placeholder="Bijv: 22/11/2020; 01/01/2021-01/05/2021; 22/11/2022"
                class="w-full px-3 py-2 border rounded-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300 resize-y overflow-auto"
                rows="2"></textarea>
        </div>

        <!-- Save Time Range Section -->
        <div class="mb-2">
            <div class="flex items-center">
                <label class="block text-gray-700 text-sm font-bold">
                    Opslaan tijdrange
                </label>
                <div class="ml-2 relative group">
                    <span class="text-blue-600 cursor-pointer">[?]</span>
                    <div
                        class="absolute hidden group-hover:block bg-gray-300 text-gray-700 text-sm rounded-lg p-2 shadow-lg -top-12 left-0 w-64">
                        Als een tijd bereik opgeslagen word, is dit beschikbaar onder de opgeslagen naam voor alle
                        gebruikers van HIPE binnen EBS.
                    </div>
                </div>
            </div>
            <div class="flex items-center">
                <input v-model="timeRangeName" autocomplete="off" placeholder="Naam voor tijdrange"
                    class="w-2/3 px-3 py-2 border rounded-l-lg text-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-300"
                    type="text">
                <button class="w-1/3 bg-slate-800 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-r-lg"
                    @click="saveTimeRange">
                    Opslaan
                </button>
            </div>
        </div>

        <!-- Load Time Range Dropdown (Always Visible) -->
        <div class="mb-1">
            <label class="block text-gray-700 text-sm font-bold">
                Laad tijdrange
            </label>
            <select v-model="selectedTimeRange" @change="loadTimeRange"
                class="w-full bg-white border rounded-lg py-2 px-4 text-gray-400">
                <option value="">Kies een tijdrange</option>
                <option v-for="time in retrainTimes" :key="time" :value="time">{{ time }}</option>
            </select>
        </div>

        <!-- Dropdown for Stops -->
        <div class="mb-2">
            <span class="text-sm text-black font-bold">Selecteer meethaltes:</span>
        </div>
        <div class="flex-grow overflow-auto bg-gray-100 rounded-lg border border-gray-300 mb-4">
            <div class="block-route-cluster flex flex-col overflow-hidden">
                <div
                    class="relative flex flex-col items-start overflow-y-auto overflow-x-hidden whitespace-normal w-full h-72 pl-1 pr-2 mb-0">
                    <div class="relative flex flex-col items-start w-full">
                        <div v-for="(stop, index) in localRouteOrder" :key="index"
                            class="block-container flex items-center w-full text-black">
                            <div v-if="stop.type !== 'rijtijd'" class="flex items-center hover:bg-gray-200 w-full">
                                <label class="checkbox-wrapper flex items-center my-1 w-full">
                                    <!-- Checkbox for Meethalte -->
                                    <input v-if="stop.type !== 'missing'" v-model="stop.meethalte"
                                        :checked="stop.meethalte" type="checkbox"
                                        class="mr-2 transform scale-150 cursor-pointer"
                                        @change="onSelectStop(index, $event)">
                                    <span v-if="stop.id_userstopname_combination && stop.type !== 'missing'"
                                        class="text-sm w-full">
                                        {{ stop.id_userstopname_combination }}
                                    </span>
                                    <span v-else class="text-sm w-full text-red-400">
                                        {{ stop.id_userstopname_combination }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Warning Message -->
        <div v-if="inputInvalid" class="mt-2 text-red-600">
            Ongeldige invoer! {{ invalidMessage }}
        </div>

        <!-- Button to Run Action -->
        <div>
            <button class="w-full bg-slate-800 hover:bg-slate-600 text-white font-semibold py-2 px-4 rounded-lg"
                @click="onRetrain">
                Hertrain het model
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, reactive } from 'vue';
import { Route } from '@/types';

export default defineComponent({
    name: 'DateAndRoutePicker',
    emits: ['retrain'],
    props: {
        trainperiod: {
            type: String,
            required: true
        },
        beginAndEndStop: {
            type: String,
            required: true
        },
        routeOrder: {
            type: Array as PropType<Route[] | undefined>,
            required: true
        },
        retrainTimes: {
            type: Array as PropType<string[]>,
            required: true
        }
    },
    setup(props, { emit }) {

        const localRouteOrder = props.routeOrder ? reactive(props.routeOrder.map(stop => ({ ...stop }))) : [];

        const includeDates = ref('');
        const excludeDates = ref('');
        const retrainSessionName = ref('');
        const timeRangeName = ref('');
        const savedTimeRanges = reactive(new Map());
        const selectedTimeRange = ref('');
        const inputInvalid = ref(false);
        const invalidMessage = ref<string>("")

        const validateDateRanges = (input: string) => {
            const datePattern = /(\d{2}\/\d{2}\/\d{4})/;
            const monthYearPattern = /(\d{2}\/\d{4})/;
            const yearPattern = /(\d{4})/;
            const dateRangePattern = /(\d{2}\/\d{2}\/\d{4})-(\d{2}\/\d{2}\/\d{4})/;
            const monthRangePattern = /(\d{2}\/\d{4})-(\d{2}\/\d{4})/;
            const yearRangePattern = /(\d{4})-(\d{4})/;

            const fullPattern = new RegExp(
                `^(${datePattern.source}|${monthYearPattern.source}|${yearPattern.source}|${dateRangePattern.source}|${monthRangePattern.source}|${yearRangePattern.source})(;\\s*(${datePattern.source}|${monthYearPattern.source}|${yearPattern.source}|${dateRangePattern.source}|${monthRangePattern.source}|${yearRangePattern.source}))*$`
            );

            return fullPattern.test(input);
        };

        const saveTimeRange = () => {
            if (!timeRangeName.value) {
                console.error("Please provide a name for the time range.");
                return;
            }
            savedTimeRanges.set(timeRangeName.value, {
                include: includeDates.value,
                exclude: excludeDates.value
            });
        };

        const loadTimeRange = () => {
            if (!selectedTimeRange.value || !savedTimeRanges.has(selectedTimeRange.value)) {
                console.error("Time range with this name not found.");
                return;
            }
            const saved = savedTimeRanges.get(selectedTimeRange.value);
            includeDates.value = saved.include;
            excludeDates.value = saved.exclude;
        };

        const onRetrain = () => {
            if (!retrainSessionName.value) {
                inputInvalid.value = true;
                invalidMessage.value = "Voer een geldige naam in."
            }
            else if (!validateDateRanges(includeDates.value)) {
                inputInvalid.value = true;
                invalidMessage.value = "Voer geldige data in."
            } else {
                // Proceed with retraining if input is valid
                const filteredStops = localRouteOrder
                    .filter(stop => {
                        const halte = stop.id_areacode_combination.split('_')[0] === stop.id_areacode_combination.split('_')[1]
                        return stop.meethalte && halte
                    })  // Filter out only those where meethalte is true
                    .map(stop => stop.id_areacode_combination);
                emit('retrain', {
                    retrainSessionName: retrainSessionName.value.toLocaleLowerCase(),
                    includeDates: cleanInput(includeDates.value),
                    excludeDates: cleanInput(excludeDates.value),
                    stops: filteredStops
                });
            }
        };

        const onSelectStop = (index: number, event: Event) => {
            const checked = (event.target as HTMLInputElement).checked;
            localRouteOrder[index].meethalte = checked;
            localRouteOrder[index].active = checked;
        };

        const cleanInput = (input: string) => {
            // Replace all spaces and unwanted characters (e.g., any special character) except valid date separators
            return input.replace(/\s+/g, '');  // This removes all spaces. You can extend it to remove other unwanted chars.
        };

        return {
            localRouteOrder,
            includeDates,
            excludeDates,
            retrainSessionName,
            timeRangeName,
            savedTimeRanges,
            selectedTimeRange,
            saveTimeRange,
            loadTimeRange,
            onRetrain,
            inputInvalid,
            invalidMessage,
            onSelectStop
        };
    },
});
</script>

<style scoped></style>
