<template>
    <div v-if="tripsDataAvailable" class="w-full flex">
        <!-- Left column for buttons (using w-42) -->
        <div class="w-16 flex">
            <div class="w-16 flex flex-col items-center">
                <button
                    :class="['w-7 h-7 rounded-sm p-0.5 mt-4 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isZoomEnabled, 'bg-slate-400 text-black': isZoomEnabled }]"
                    @click="handleZoom">
                    <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${zoomSvg})` }"></div>
                </button>
                <button
                    :class="['w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isZoomEnabled, 'bg-slate-400 text-black': isZoomEnabled }]"
                    @click="handleZoomReset">
                    <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${resetZoomImage})` }"></div>
                </button>
                <button
                    :class="['w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isAddingDraggable, 'bg-slate-400 text-black': isAddingDraggable }]"
                    @click="addDraggableDiv">
                    <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${addBarSvg})` }"></div>
                </button>
                <button
                    :class="['w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isScheduleEnabled, 'bg-slate-400 text-black': isScheduleEnabled }]"
                    @click="handleSchedule">
                    <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${scheduleSvg})` }"></div>
                </button>
                <button
                    :class="['w-7 h-7 rounded-sm p-0.5 mb-2 flex items-center justify-center', { 'bg-slate-800 text-white': !isToolTipEnabled, 'bg-slate-400 text-black': isToolTipEnabled }]"
                    @click="handleToolTip">
                    <div class="w-full h-full bg-cover" :style="{ backgroundImage: `url(${toolTip})` }"></div>
                </button>
            </div>
        </div>

        <!-- Chart container -->
        <div id="d3-bar-chart" class="flex"></div>
    </div>
</template>


<script lang="ts">
import { defineComponent, onMounted, watch, computed, PropType, onUnmounted, ref } from 'vue';
import { Bus, LineScheduleTotals, DayTimeClusterData, DayClusterData, SeasonClusterData, ClusteredTrip, Trip, TimeClusterData } from '@/types';
import * as d3 from 'd3';

export default defineComponent({
    name: 'BarChartD3',
    emits: ['update-time-cluster'],
    props: {
        tripsData: {
            type: Array as PropType<Bus[]>,
            required: true,
        },
        scheduleData: {
            type: Array as PropType<LineScheduleTotals[]>,
            required: true,
        },
        timeClusters: {
            type: Array as PropType<DayTimeClusterData[]>,
            required: false, // Default times for vertical lines
        },
        activeDayCluster: {
            type: Number,
            required: true,
        },
        dayClusters: {
            type: Array as PropType<DayClusterData[][]>,
            required: true,
        },
        activeSeasonCluster: {
            type: Number,
            required: true,
        },
        seasonClusters: {
            type: Array as PropType<SeasonClusterData[][]>,
            required: true,
        },
        reloadBarChartTrigger: {
            type: Number,
            required: true,
        },
    },
    setup(props, { emit }) {
        const addBarSvg = require('@/assets/add_bar.svg');
        const removeBarSvg = require('@/assets/remove_bar.svg');
        const zoomClusterSvg = require('@/assets/zoom_cluster.svg');
        const zoomSvg = require('@/assets/zoom.svg');
        const resetZoomImage = require('@/assets/zoom_reset.svg');
        const scheduleSvg = require('@/assets/schedule.svg');
        const toolTip = require('@/assets/tool_tip.svg');


        const margin = { top: 20, right: 30, bottom: 40, left: 50 };
        const height = 300 - margin.top - margin.bottom;
        const tripsDataAvailable = computed(() => props.tripsData && props.tripsData.length > 0);

        const isAddingDraggable = ref<boolean>(false);

        const dividerPositions = ref<{ id: string, position: number }[]>([]);

        const parsedTripsGlobal = ref<{ parsedTime: Date; time: string; averageTime: number; journeynumber: string; }[]>([]);
        const xGlobal = ref<any>(null);
        const yGlobal = ref<any>(null);
        const clustersRef = ref<ClusteredTrip[][]>([]);

        const isZoomEnabled = ref(false);
        const isToolTipEnabled = ref(false);
        let originalXDomain: [Date, Date];
        let originalYDomain: [number, number];
        const recalculateAxis = ref<boolean>(false)

        // Handle zoom button toggle
        const handleZoom = () => {
            isZoomEnabled.value = !isZoomEnabled.value;
            isAddingDraggable.value = false;
            isToolTipEnabled.value = false;
            if (isZoomEnabled.value) {
                addBrush();
            } else {
                removeBrush();
            }
        };

        const handleToolTip = () => {
            isToolTipEnabled.value = !isToolTipEnabled.value;
            isZoomEnabled.value = false;
            isAddingDraggable.value = false;
            handleReload();
        }

        // Handle zoom reset to restore the original graph view
        const handleZoomReset = () => {
            xGlobal.value.domain(originalXDomain);
            yGlobal.value.domain(originalYDomain);
            handleReload();
        };

        // Function to add brush (zoom area)
        const addBrush = () => {
            const svgContainer = d3.select('#d3-bar-chart').select('svg');
            const brushArea = d3.brush()
                .extent([
                    [margin.left, margin.top], // Start brush from the top-left corner considering margins
                    [svgContainer.attr('width') - margin.right, svgContainer.attr('height') - margin.bottom], // End brush at the bottom-right corner considering margins
                ])
                .on('end', brushed);

            svgContainer.append('g')
                .attr('class', 'brush')
                .call(brushArea);
        };


        // Function to remove brush when zoom is disabled or after zoom
        const removeBrush = () => {
            d3.select('.brush').remove();
        };

        // Brushed event handler for both X and Y zoom
        // Brushed event handler for both X and Y zoom
        const brushed = (event: any) => {
            if (!event.selection) return; // Exit if no area was selected

            const [[x0, y0], [x1, y1]] = event.selection as [[number, number], [number, number]]; // Selected area

            // Convert pixel selection to domain (time for X and numeric for Y)
            const newXDomain = [xGlobal.value.invert(x0 - margin.left), xGlobal.value.invert(x1 - margin.left)];
            const newYDomain = [yGlobal.value.invert(y1 - margin.top), yGlobal.value.invert(y0 - margin.top)]; // Inverted as y-axis goes top to bottom

            // Update x and y scales with the new domain
            xGlobal.value.domain(newXDomain);
            yGlobal.value.domain(newYDomain);

            // Update the clip path to reflect the new zoomed area
            d3.select('#clip rect')
                .attr('x', x0)
                .attr('y', y0)
                .attr('width', x1 - x0)
                .attr('height', y1 - y0);

            // Re-render the chart with the new zoomed domain
            handleReload(true);

            // Remove the brush after zooming
            removeBrush();
            isZoomEnabled.value = false;
        };
        // Function to toggle addDraggable mode
        const addDraggableDiv = () => {
            isAddingDraggable.value = !isAddingDraggable.value;
            isZoomEnabled.value = false;
            isToolTipEnabled.value = false;
            handleReload();
        };

        // Function to place dividers
        let dividerIdCounter = 1;
        const generateDividerId = () => {
            return `div-${dividerIdCounter++}`;
        };

        // Function to place dividers
        const onGraphLeftClick = (event: MouseEvent) => {
            if (event.button !== 0) return; // Only proceed if the left button is clicked
            event.preventDefault();

            if (isAddingDraggable.value) {
                const svgContainer = d3.select('#d3-bar-chart').select('svg');
                const xPosition = event.offsetX; // Get the x-position of the click

                if (xPosition < margin.left || xPosition > svgContainer.attr('width') - margin.right) {
                    return; // Don't add the divider if clicked outside the graph boundaries
                }

                // Generate a unique ID for the divider
                const dividerId = generateDividerId();

                // Add a gray rectangle for hover highlight
                const highlightArea = svgContainer
                    .append('rect')
                    .attr('x', xPosition - 10) // Adjust x to center the gray bar
                    .attr('y', margin.top) // Start at the top margin
                    .attr('width', 20) // Width of 20px for highlighting area
                    .attr('height', height + margin.top) // Full height of the chart
                    .attr('fill', '#e5e7eb') // Tailwind gray-200
                    .attr('opacity', 0) // Set opacity to 0.7
                    .attr('pointer-events', 'all') // Enable pointer events for this highlight
                    .attr('clip-path', 'url(#clip-dividers)')
                    .raise(); // Bring the highlight area to the front

                // Append a vertical line (divider) at the clicked position
                const divider = svgContainer
                    .append('line')
                    .attr('x1', xPosition)
                    .attr('x2', xPosition)
                    .attr('y1', margin.top)
                    .attr('y2', height + margin.top)
                    .attr('stroke', 'black')
                    .attr('stroke-width', 2)
                    .attr('clip-path', 'url(#clip-dividers)')
                    .attr('pointer-events', 'none'); // Disable pointer events on the divider

                // Add the x-position to dividerPositions with the generated ID
                dividerPositions.value.push({ id: dividerId, position: xPosition - margin.left });
                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position

                // Add right-click listener to remove both the divider and highlight area
                highlightArea.on('contextmenu', (event: MouseEvent) => {
                    event.preventDefault();
                    highlightArea.remove(); // Remove the highlight area
                    divider.remove(); // Remove the divider line

                    // Remove the corresponding divider from the array by ID
                    dividerPositions.value = dividerPositions.value.filter(divider => divider.id !== dividerId);

                    // Re-cluster trips after removal
                    clusterTripsByDividers();
                });

                // D3 drag behavior for dragging the highlight area
                const dragBehavior = d3.drag()
                    .on('start', function () {
                        highlightArea.raise().attr('opacity', 0.7); // Bring the dragged highlight to the front and make it visible
                    })
                    .on('drag', function (event: any) {
                        const newXPosition = event.x;

                        // Ensure the new x position stays within the left and right margin boundaries
                        if (newXPosition >= margin.left && newXPosition <= svgContainer.attr('width') - margin.right) {
                            highlightArea.attr('x', newXPosition - 10); // Adjust position while dragging
                            divider.attr('x1', newXPosition).attr('x2', newXPosition); // Move the divider line with the highlight

                            // Update the corresponding divider position by ID
                            const dividerIndex = dividerPositions.value.findIndex(d => d.id === dividerId);
                            if (dividerIndex !== -1) {
                                dividerPositions.value[dividerIndex].position = newXPosition - margin.left;
                                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position
                            }
                        }
                    })
                    .on('end', function () {
                        highlightArea.attr('opacity', 0); // Set opacity back after dragging is done
                        // Re-cluster trips after dragging
                        clusterTripsByDividers();
                    });

                highlightArea.call(dragBehavior); // Attach drag behavior to the highlight area

                // Track hover state with a debounce to avoid flickering
                let isHovering = false;
                let debounceTimeout: any = null;

                const setHoverState = (opacity: number, delay = 0) => {
                    clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                        highlightArea.attr('opacity', opacity);
                        isHovering = opacity > 0; // Set hover state based on opacity
                    }, delay);
                };

                // Add hover effect to the highlight area
                highlightArea
                    .on('mouseenter', () => {
                        if (!isHovering) {
                            setHoverState(0.5); // Show the highlight
                        }
                    })
                    .on('mouseleave', () => {
                        if (isHovering) {
                            setHoverState(0, 50); // Hide the highlight with a short delay
                        }
                    });

                // Cluster trips based on dividers and emit
                clusterTripsByDividers();
            }
            renderLegend();
        };

        const placeDividersFromClusters = (timeClusters: TimeClusterData[][]) => {

            const svgContainer = d3.select('#d3-bar-chart').select('svg');

            // Loop over the clusters to calculate where the dividers should be
            for (let i = 1; i < timeClusters.length; i++) {
                // Get the first trip from the current cluster and the last trip from the previous cluster
                const previousClusterEndTime = timeClusters[i - 1][timeClusters[i - 1].length - 1].starttime;
                const currentClusterStartTime = timeClusters[i][0].starttime;

                const timeParser = d3.timeParse('%H:%M:%S'); // Parse time in 'HH:mm:ss' format

                // Parse the time and calculate the midpoint between the two clusters
                const previousTimeParsed = timeParser(previousClusterEndTime) as Date;
                const currentTimeParsed = timeParser(currentClusterStartTime) as Date;

                // Calculate the x-position for the divider (midpoint between previous end and current start)
                const dividerX = margin.left + (xGlobal.value(previousTimeParsed) + xGlobal.value(currentTimeParsed)) / 2;

                // Generate a unique ID for the divider
                const dividerId = generateDividerId();

                // Add the divider line at the calculated x position
                const highlightArea = svgContainer
                    .append('rect')
                    .attr('x', dividerX - 10) // Adjust x to center the gray bar
                    .attr('y', margin.top) // Start at the top margin
                    .attr('width', 20) // Width of 20px for highlighting area
                    .attr('height', height) // Full height of the chart
                    .attr('fill', '#e5e7eb') // Tailwind gray-200
                    .attr('opacity', 0) // Set opacity to 0.7
                    .attr('pointer-events', 'all') // Enable pointer events for this highlight
                    .attr('clip-path', 'url(#clip-dividers)')
                    .raise(); // Bring the highlight area to the front

                const divider = svgContainer
                    .append('line')
                    .attr('x1', dividerX)
                    .attr('x2', dividerX)
                    .attr('y1', margin.top)
                    .attr('y2', height + margin.top)
                    .attr('stroke', 'black')
                    .attr('stroke-width', 2)
                    .attr('clip-path', 'url(#clip-dividers)')
                    .attr('pointer-events', 'none'); // Disable pointer events on the divider

                // Add the x-position to dividerPositions with the generated ID
                dividerPositions.value.push({ id: dividerId.toString(), position: dividerX - margin.left });
                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position

                // Add right-click listener to remove both the divider and highlight area
                highlightArea.on('contextmenu', (event: MouseEvent) => {
                    event.preventDefault();
                    highlightArea.remove(); // Remove the highlight area
                    divider.remove(); // Remove the divider line

                    // Remove the corresponding divider from the array by ID
                    dividerPositions.value = dividerPositions.value.filter(divider => divider.id !== dividerId.toString());

                    // Re-cluster trips after removal
                    clusterTripsByDividers();
                });

                // D3 drag behavior for dragging the highlight area
                const dragBehavior = d3.drag()
                    .on('start', function () {
                        highlightArea.raise().attr('opacity', 0.7); // Bring the dragged highlight to the front and make it visible
                    })
                    .on('drag', function (event: any) {
                        const newXPosition = event.x;

                        // Ensure the new x position stays within the left and right margin boundaries
                        if (newXPosition >= margin.left && newXPosition <= svgContainer.attr('width') - margin.right) {
                            highlightArea.attr('x', newXPosition - 10); // Adjust position while dragging
                            divider.attr('x1', newXPosition).attr('x2', newXPosition); // Move the divider line with the highlight

                            // Update the corresponding divider position by ID
                            const dividerIndex = dividerPositions.value.findIndex(d => d.id === dividerId.toString());
                            if (dividerIndex !== -1) {
                                dividerPositions.value[dividerIndex].position = newXPosition - margin.left;
                                dividerPositions.value.sort((a, b) => a.position - b.position); // Sort the dividers by x-position
                            }
                        }
                    })
                    .on('end', function () {
                        highlightArea.attr('opacity', 0); // Set opacity back after dragging is done
                        // Re-cluster trips after dragging
                        clusterTripsByDividers();
                    });

                highlightArea.call(dragBehavior); // Attach drag behavior to the highlight area

                // Track hover state with a debounce to avoid flickering
                let isHovering = false;
                let debounceTimeout: any = null;

                const setHoverState = (opacity: number, delay = 0) => {
                    clearTimeout(debounceTimeout);
                    debounceTimeout = setTimeout(() => {
                        highlightArea.attr('opacity', opacity);
                        isHovering = opacity > 0; // Set hover state based on opacity
                    }, delay);
                };

                // Add hover effect to the highlight area
                highlightArea
                    .on('mouseenter', () => {
                        if (!isHovering) {
                            setHoverState(0.5); // Show the highlight
                        }
                    })
                    .on('mouseleave', () => {
                        if (isHovering) {
                            setHoverState(0, 50); // Hide the highlight with a short delay
                        }
                    });
            }
        };



        const svgContainer = d3.select('#d3-bar-chart').select('svg');
        svgContainer.on('mousemove', function (event: MouseEvent) {
            const xPosition = event.offsetX;
            const withinBounds = xPosition >= margin.left && xPosition <= svgContainer.attr('width') - margin.right;

            // Only change the cursor when isAddingDraggable mode is active
            if (isAddingDraggable.value) {
                svgContainer.style('cursor', withinBounds ? 'crosshair' : 'default');
            } else {
                svgContainer.style('cursor', 'default'); // Set cursor to default when not in addDraggable mode
            }
        });

        const drawAverageTimeLines = (clusters: ClusteredTrip[][]) => {
            // Clear existing red lines
            const svgElement = d3.select('#d3-bar-chart').select('svg');
            svgElement.selectAll('.average-line').remove();

            // Check if the SVG element exists before proceeding
            if (svgElement.empty()) {
                console.warn("SVG element is not available yet.");
                return; // Exit the function early if SVG is not available
            }

            // Get the width of the SVG container
            const graphWidth = svgElement.attr('width');
            if (!graphWidth) {
                console.warn("SVG width is not defined.");
                return; // Exit the function early if width is not defined
            }

            // Create a new group to hold the lines and apply the clipping path
            const averageLinesGroup = svgElement.append('g')
                .attr('class', 'average-lines-group')
                .attr('transform', `translate(${margin.left},${margin.top})`)
                .attr('clip-path', 'url(#clip)');  // Apply clip path here

            // Iterate over the clusters and draw the average time line
            clusters.forEach((cluster, index) => {
                const avgTotalTime = cluster.reduce((sum, trip) => sum + trip.seconds, 0) / cluster.length; // Calculate average time
                const avgYPosition = yGlobal.value(avgTotalTime); // Convert avgTime to y position using y scale

                // Determine the X positions for the current cluster
                const dividerX1 = index === 0 ? 0 : dividerPositions.value[index - 1].position;
                const dividerX2 = index === clusters.length - 1 ? parseInt(graphWidth) : dividerPositions.value[index].position;
                averageLinesGroup
                    .append('line')
                    .attr('x1', dividerX1)
                    .attr('x2', dividerX2)
                    .attr('y1', avgYPosition)
                    .attr('y2', avgYPosition)
                    .attr('stroke', '#fca5a5')
                    .attr('stroke-width', 2)
                    .attr('class', 'average-line');
            });
        };

        // Modified clusterTripsByDividers function
        // Modified clusterTripsByDividers function
        const clusterTripsByDividers = () => {
            const clusters: ClusteredTrip[][] = []; // Array of clusters (each cluster is an array of ClusteredTrip objects)
            let currentCluster: ClusteredTrip[] = []; // Current cluster being formed
            let dividerIndex = 0; // Track which divider we are currently comparing against

            // Ensure dividers are sorted and iterate through trips
            parsedTripsGlobal.value.forEach((trip: Trip) => {
                const tripX = xGlobal.value(trip.parsedTime); // Calculate x position for the trip start time

                // Check if the trip belongs to the current divider or next
                while (dividerIndex < dividerPositions.value.length && tripX >= dividerPositions.value[dividerIndex].position) {
                    // Push the current cluster to clusters and start a new cluster
                    if (currentCluster.length > 0) {
                        clusters.push([...currentCluster]);
                        currentCluster = [];
                    }
                    dividerIndex++;
                }

                // Add the trip to the current cluster
                currentCluster.push({ journeynumber: trip.journeynumber, starttime: trip.time, seconds: trip.averageTime });
            });

            // Add the last cluster if any trips remain in it
            if (currentCluster.length > 0) {
                clusters.push(currentCluster);
            }

            clustersRef.value = clusters; // Store the clusters in ref

            // Draw the red lines for the average time of each cluster
            drawAverageTimeLines(clusters);

            // Emit the clusters to the parent component
            emit('update-time-cluster', clusters);
        };

        function averageTimesByCluster(tripsData: Bus[], dayClusters: DayClusterData[][], seasonClusters: SeasonClusterData[][], activeDayCluster: number, activeSeasonCluster: number) {
            if (!dayClusters || !seasonClusters) {
                throw new Error("Day or Season Clusters are undefined");
            }
            if (!dayClusters[activeDayCluster] || !seasonClusters[activeSeasonCluster]) {
                return [];
            }

            const activeDays = dayClusters[activeDayCluster].map(dayData => dayData.day);
            const activeSeasons = seasonClusters[activeSeasonCluster].map(item => item.season);
            const filteredTrips = tripsData.filter(trip => activeDays.includes(trip.day) && activeSeasons.includes(trip.season));

            const tripsGroupedByTime: { [key: string]: { total_time: number, journeynumber: string }[] } = {};

            filteredTrips.forEach(trip => {
                if (trip.start_time && trip.total_time && trip.id) {
                    const time = trip.start_time.substring(0, 8);

                    // Extract journeynumber from trip.id
                    const idParts = trip.id.split('_');
                    const journeynumber = idParts[3]; // Assuming the journeynumber is always at this position

                    if (!tripsGroupedByTime[time]) {
                        tripsGroupedByTime[time] = [];
                    }

                    tripsGroupedByTime[time].push({ total_time: trip.total_time, journeynumber });
                }
            });

            const averageTimes = Object.keys(tripsGroupedByTime).map(time => {
                const totalTimes = tripsGroupedByTime[time];
                const averageTime = totalTimes.reduce((sum, entry) => sum + entry.total_time, 0) / totalTimes.length;
                const journeynumber = totalTimes[0].journeynumber;

                return { time, averageTime, journeynumber };
            });
            return averageTimes;
        }

        const isScheduleEnabled = ref<boolean>(false);
        const handleSchedule = () => {
            isScheduleEnabled.value = !isScheduleEnabled.value; // Toggle the schedule visibility
            handleReload(); // Redraw the graph with or without the schedule
        };

        const averageScheduleByDayCluster = computed(() => {
            const getJourneyNumber = (id: string) => {
                const parts = id.split('_');
                return parts[3]; // Assuming the 4th part is the journey number
            };

            const getStartTime = (journeyNumber: string, day: string) => {
                const trip = props.tripsData.find(trip => {
                    if (!trip.id) {
                        throw new Error("missing trip id");
                    }
                    return trip.id.includes(journeyNumber) && trip.day === day;
                });
                return trip ? trip.start_time : null; // Ensure null if undefined
            };

            const getDayFromScheduleId = (id: string): string => {
                return id.split('_')[4]; // Assuming the 5th part is the day
            };

            const average = (arr: number[]) => arr.reduce((sum, val) => sum + val, 0) / arr.length;


            // Prepare the result structure
            const result: { journeyNumber: string; averageTotalTime: number; startTime: string | null; }[][] = [];

            if (!props.dayClusters) {
                throw new Error("missing day clusters");
            }

            // Define minTotalTime and maxTotalTime for height calculation (dummy values, replace with real ones)

            // Loop through each day cluster (e.g., weekday, weekend)
            props.dayClusters.forEach(cluster => {
                // Extract the days from the cluster
                const clusterDays = cluster.map(item => item.day);
                const journeys: Record<string, number[]> = {};

                // Filter scheduleData based on the cluster days
                props.scheduleData.forEach(item => {
                    const day = getDayFromScheduleId(item.id);
                    if (clusterDays.includes(day)) {
                        const journeyNumber = getJourneyNumber(item.id);

                        // If this journeyNumber doesn't exist in journeys, initialize it
                        if (!journeys[journeyNumber]) {
                            journeys[journeyNumber] = [];
                        }

                        // Add the total time to the array for this journey
                        journeys[journeyNumber].push(item.total);
                    }
                });

                // Group by journeyNumber to ensure each journeyNumber is represented once, then calculate the average
                const groupedJourneys: Record<string, { totalTimes: number[]; startTime: string }> = {};

                clusterDays.forEach((day) => {
                    Object.entries(journeys).forEach(([journeyNumber, totalTimes]) => {
                        const startTime = getStartTime(journeyNumber, day as string);

                        // If the journeyNumber already exists, add the totalTimes to it
                        if (groupedJourneys[journeyNumber]) {
                            groupedJourneys[journeyNumber].totalTimes.push(...totalTimes);
                        } else {
                            groupedJourneys[journeyNumber] = {
                                totalTimes,
                                startTime: startTime || "", // Ensure a start time exists
                            };
                        }
                    });
                });

                // Process each grouped journey to calculate the average and the resulting metrics
                const clusterResult = Object.entries(groupedJourneys).map(([journeyNumber, journeyData]) => {
                    const avgTotalTime = average(journeyData.totalTimes);
                    const startTime = journeyData.startTime;

                    return {
                        journeyNumber,
                        averageTotalTime: avgTotalTime,
                        startTime
                    };
                });

                result.push(clusterResult); // Push the calculated cluster results into the result array
            });
            return result;
        });

        const secondsToMMSS = (seconds: number) => {
            const mins = Math.floor(seconds / 60);
            const secs = Math.floor(seconds % 60);
            return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        };


        const renderBarChart = () => {
            const chartContainer = document.getElementById('d3-bar-chart');
            if (!chartContainer) return;

            // Use the full available width of the container
            const width = chartContainer.clientWidth - margin.left - margin.right;

            // Data prep
            const averageTrips = averageTimesByCluster(
                props.tripsData,
                props.dayClusters,
                props.seasonClusters,
                props.activeDayCluster,
                props.activeSeasonCluster
            );

            d3.select('#d3-bar-chart').selectAll('*').remove();

            // Create SVG container
            const svg = d3
                .select('#d3-bar-chart')
                .append('svg')
                .attr('width', width + margin.left + margin.right)
                .attr('height', height + margin.top + margin.bottom);

            // Define the clip path (only for the chart area)
            svg.append('defs')
                .append('clipPath')
                .attr('id', 'clip')
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', width)
                .attr('height', height);

            svg.append('defs')
                .append('clipPath')
                .attr('id', 'clip-dividers')
                .append('rect')
                .attr('x', 0)
                .attr('y', margin.top)
                .attr('width', width + margin.left)
                .attr('height', height);


            const chartGroup = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`)
                .attr('clip-path', 'url(#clip)');  // Apply the clip-path only to chart elements

            // Group for axes (this will be outside the clip path)
            const axesGroup = svg.append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`)

            // Y scale and axis (in minutes and seconds)
            const scheduletimes = averageScheduleByDayCluster.value[props.activeDayCluster];
            let minSchedule = 100000;
            let maxSchedule = 0;
            scheduletimes.forEach(time => {
                minSchedule = Math.min(minSchedule, time.averageTotalTime);
                maxSchedule = Math.max(maxSchedule, time.averageTotalTime);
            })
            let maxTime = d3.max(averageTrips, (d: any) => d.averageTime) as number;
            let minTime = d3.min(averageTrips, (d: any) => d.averageTime) as number;

            minTime = Math.min(minTime, minSchedule);
            maxTime = Math.max(maxTime, maxSchedule);

            const buffer = 60; // 1 minute in seconds
            const yMin = Math.floor(minTime / 60) * 60 - buffer; // Round down to nearest whole minute
            const yMax = Math.ceil(maxTime / 60) * 60 + buffer; // Round up to nearest whole minute and add buffer

            // Define scales for Y and X
            const timeParser = d3.timeParse('%H:%M:%S'); // Parse time in 'HH:mm:ss' format
            const timeFormatter = d3.timeFormat('%H:%M'); // Format time to 'HH:mm'
            const parsedTrips = averageTrips.map(d => ({
                ...d,
                parsedTime: timeParser(d.time) as Date,
            }));
            parsedTripsGlobal.value = parsedTrips;

            const minXTime = d3.min(parsedTrips, (d: any) => d.parsedTime) as Date;
            const maxXTime = d3.max(parsedTrips, (d: any) => d.parsedTime) as Date;
            const timeBuffer = 30 * 60 * 1000; // 15 minutes in milliseconds

            if (!xGlobal.value || recalculateAxis.value) {
                originalXDomain = [new Date(minXTime.getTime() - timeBuffer), new Date(maxXTime.getTime() + timeBuffer)];
                xGlobal.value = d3.scaleTime().domain(originalXDomain).range([0, width]);
            }
            if (!yGlobal.value || recalculateAxis.value) {
                originalYDomain = [yMin, yMax];
                yGlobal.value = d3.scaleLinear().domain(originalYDomain).range([height, 0]);
            }

            // Add background gridlines (X and Y) inside the chart group
            const gridGroup = chartGroup.append('g').attr('class', 'grid');

            // Add Y-axis gridlines (horizontal gridlines)
            const yTickValues = d3.range(yMin, yMax + 60, 60); // Ensure ticks are at 1-minute intervals

            gridGroup.append('g')
                .call(
                    d3.axisLeft(yGlobal.value)
                        .tickSize(-width) // Extend ticks to form horizontal gridlines
                        .tickValues(yTickValues) // Use the same tick values as Y-axis
                        .tickFormat('')   // Remove tick labels for gridlines
                )
                .selectAll('line')
                .attr('stroke', '#e5e7eb'); // Tailwind gray-200 for gridlines

            // Add X-axis gridlines (vertical gridlines)
            gridGroup.append('g')
                .attr('transform', `translate(0,${height})`)
                .call(
                    d3.axisBottom(xGlobal.value)
                        .tickSize(-height) // Extend ticks to form vertical gridlines
                        .tickFormat('')    // Remove tick labels
                )
                .selectAll('line')
                .attr('stroke', '#e5e7eb'); // Tailwind gray-200 for gridlines

            // Add Y-axis with whole minute ticks and give it a class 'y-axis'
            axesGroup.append('g')
                .attr('class', 'y-axis') // Add class for later selection
                .call(
                    d3.axisLeft(yGlobal.value)
                        .tickValues(d3.range(yMin, yMax + 60, 60)) // Ensure ticks are at 1-minute intervals
                        .tickFormat((d: number, i: number) => {
                            // Show label only for every other tick if more than 12 ticks
                            const minutes = Math.floor(d / 60);
                            return yTickValues.length > 10 && i % 2 !== 0 ? '' : `${minutes}:00`;
                        })
                )
                .selectAll('path, line')
                .attr('stroke', '#e5e7eb'); // Apply stroke to Y-axis lines

            // Select text elements on Y-axis separately for styling
            axesGroup.selectAll('.y-axis text')
                .style('font-size', '12px')
                .style('font-family', 'Inter, sans-serif'); // Apply font size to Y-axis text

            // Add X-axis and give it a class 'x-axis'
            axesGroup.append('g')
                .attr('class', 'x-axis') // Add class for later selection
                .attr('transform', `translate(0,${height})`)
                .call(
                    d3.axisBottom(xGlobal.value)
                        .ticks(d3.timeHour.every(1)) // Show tick marks for every hour
                        .tickFormat(timeFormatter)   // Format as 'HH:mm'
                )
                .selectAll('path, line')
                .attr('stroke', '#e5e7eb'); // Apply stroke to X-axis lines

            // Select text elements on X-axis separately for styling
            axesGroup.selectAll('.x-axis text')
                .style('font-size', '12px')
                .style('font-family', 'Inter, sans-serif'); // Apply font size to X-axis text

            // Add schedule blocks to the graph (if needed)
            // (code for schedule blocks stays the same, placed inside `chartGroup`)
            const averageSchedule = averageScheduleByDayCluster.value;
            // Add schedule blocks to the graph
            if (isScheduleEnabled.value && Array.isArray(averageSchedule) && averageSchedule.length > 0) {
                const dayClusterSchedule = averageSchedule[props.activeDayCluster];

                dayClusterSchedule.forEach((scheduleItem, index) => {
                    let startX, endX, width;
                    const currentStartX = xGlobal.value(timeParser(dayClusterSchedule[index].startTime)) // Next block's start
                    // For the first block, extend halfway from the start with a default width on the left
                    if (index === 0) {
                        startX = currentStartX - 12; // Move 12px to the left for the first block
                    } else {
                        // Midpoint between the current block and the previous block
                        let prevStartX = xGlobal.value(timeParser(dayClusterSchedule[index - 1].startTime));
                        startX = (prevStartX + currentStartX) / 2;
                    }

                    // Calculate the width based on the midpoint between this starttime and the next
                    if (index < dayClusterSchedule.length - 1) {
                        let nextStartX = xGlobal.value(timeParser(dayClusterSchedule[index + 1].startTime));
                        endX = (currentStartX + nextStartX) / 2
                        width = endX - startX;
                    } else {
                        width = currentStartX - startX + 12;
                    }

                    // Calculate the y position and height based on averageTotalTime
                    const yPosition = yGlobal.value(scheduleItem.averageTotalTime); // Use the y-scale for the vertical position
                    const blockHeight = height - yPosition; // Calculate height based on the y-scale

                    // Append a rect (block) to represent the schedule
                    chartGroup.append('rect')
                        .attr('x', startX) // Use calculated startX
                        .attr('y', yPosition) // Use the calculated y position for the schedule block
                        .attr('width', width) // Use the calculated width
                        .attr('height', blockHeight) // Use calculated height based on averageTotalTime
                        .attr('fill', '#d1d5db') // 
                        .attr('opacity', 0.5) // Some transparency to make it less dominant
                        .attr('pointer-events', 'none'); // Disable pointer events for schedule blocks
                });
            }
            // const barHoverWidth = 3;  // Extra pixels on either side of the bar
            const normalColor = '#93c5fd';  // Tailwind blue-400
            const hoverColor = '#3b82f6';  // Darker Tailwind blue-500
            const tooltip = d3.select("body")
                .append("div")
                .attr("class", "tooltip") // Add your tooltip styling in CSS
                .style("position", "absolute")
                .style("background-color", "white")
                .style("border", "1px solid #d1d5db")
                .style("padding", "8px")
                .style("font-size", "12px")
                .style("font-family", "Inter, sans-serif")
                .style("border-radius", "4px")
                .style("pointer-events", "none")
                .style("display", "none"); // Start hidden

            let isHovering = false;
            let debounceTimeout: any = null;

            const setHoverState = (opacity: number, delay = 0) => {
                clearTimeout(debounceTimeout);
                debounceTimeout = setTimeout(() => {
                    isHovering = opacity > 0;
                }, delay);
            };

            // Add bars with hoverable rect overlays
            parsedTrips.forEach((d) => {
                const barX = xGlobal.value(d.parsedTime) as number;
                const barY = yGlobal.value(d.averageTime);
                const barHeight = height - barY;
                // Add the actual bar
                const bar = chartGroup.append('rect')
                    .attr('x', barX - 2)  // Center the bar at `barX`
                    .attr('y', barY)
                    .attr('width', 4) // Bar width
                    .attr('height', barHeight)
                    .attr('fill', normalColor);

                // Add a hoverable highlight area around the bar
                const highlightArea = chartGroup.append('rect')
                    .attr('x', barX - 2)
                    .attr('y', 0)
                    .attr('width', 4) // Expanded width for easier hovering
                    .attr('height', height)
                    .attr('fill', 'transparent')
                    .attr('pointer-events', 'all');

                if (isToolTipEnabled.value) {
                    // Hover effect for the highlight area
                    highlightArea
                        .on('mouseenter', (event: MouseEvent) => {
                            if (!isHovering) {
                                setHoverState(0.5);
                                tooltip.style("display", "block")
                                    .html(`<strong>Starttijd:</strong> ${d.time}<br><strong>Rijtijd:</strong> ${secondsToMMSS(d.averageTime)}`)
                                    .style("left", `${event.pageX + 10}px`)
                                    .style("top", `${event.pageY - 28}px`);
                                bar.attr("fill", hoverColor);  // Change bar color on hover
                            }
                        })
                        .on('mousemove', (event: MouseEvent) => {
                            tooltip.style("left", `${event.pageX + 10}px`)
                                .style("top", `${event.pageY - 28}px`);
                        })
                        .on('mouseleave', () => {
                            if (isHovering) {
                                setHoverState(0, 50);
                                tooltip.style("display", "none");
                                bar.attr("fill", normalColor);  // Reset bar color on mouse out
                            }
                        });
                }

            });
            // Add a border around the entire chart area (inside the clipped chartGroup)
            chartGroup.append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', width)
                .attr('height', height)
                .attr('fill', 'none')
                .attr('stroke', '#e5e7eb') // Set the border color to gray-200
                .attr('stroke-width', 2);  // Set stroke width for the border

            renderLegend();
        };

        const renderLegend = () => {
            // Define the legend data
            const legendData = [
                { label: 'Rijtijd voorspeld', color: '#93c5fd' }, // Tailwind blue-300
                { label: 'Cluster gemiddelde', color: '#fca5a5' }, // Tailwind gray-200
                // Add more items if needed
            ];

            // Get the width of the chart to place the legend on the top right
            const chartWidth = d3.select('#d3-bar-chart').select('svg').attr('width');
            const legendX = +chartWidth - margin.right - 160; // Adjust as needed
            const legendY = margin.top + 10;
            const legendWidth = 150; // Set the width of the background box
            const legendHeight = legendData.length * 20 + 10;

            // Append a legend group to the SVG
            const legendGroup = d3.select('#d3-bar-chart').select('svg')
                .append('g')
                .attr('class', 'legend')
                .attr('transform', `translate(${legendX}, ${legendY})`)
                .raise();

            legendGroup.append('rect')
                .attr('width', legendWidth)
                .attr('height', legendHeight)
                .attr('fill', 'white')
                .attr('stroke', '#e5e7eb') // Tailwind gray-200 for border
                .attr('stroke-width', 2)
                .attr('rx', 2) // Border radius for rounded corners
                .attr('ry', 2) // Border radius for rounded corners
                .raise()

            // Add legend items
            legendData.forEach((item, index) => {
                const legendItem = legendGroup.append('g')
                    .attr('transform', `translate(6, ${index * 20 + 8})`); // Offset each item vertically

                // Add the color box
                legendItem.append('rect')
                    .attr('transform', `translate(6, 0)`)
                    .attr('width', 12)
                    .attr('height', 12)
                    .attr('fill', item.color);

                // Add the label text
                legendItem.append('text')
                    .attr('transform', `translate(6, -2)`)
                    .attr('x', 20) // Position the text slightly to the right of the color box
                    .attr('y', 10)
                    .text(item.label)
                    .style('font-size', '12px')
                    .style('font-family', 'Inter, sans-serif')
                    .attr('alignment-baseline', 'middle');
            });
        };



        const handleReload = (zoom: boolean = false) => {
            // Then render the bar chart
            dividerPositions.value = [];
            recalculateAxis.value = !zoom
            renderBarChart();

            // First check and initialize the dividers from the timeClusters
            const seasons = props.seasonClusters[props.activeSeasonCluster].map(cluster => cluster.season);
            const days = props.dayClusters[props.activeDayCluster].map(cluster => cluster.day);
            const timeCluster = props.timeClusters?.find(cluster => cluster.day_cluster === days.join('_') && cluster.season_cluster === seasons.join('_'));
            if (timeCluster && timeCluster.time_cluster) {
                // Initialize dividers based on the timeCluster data
                clustersRef.value = timeCluster.time_cluster.map(clusterArray => {
                    return clusterArray.map(trip => {
                        // Assuming trip contains properties like journeynumber, starttime, and totaltime
                        return {
                            journeynumber: trip.journeynumber!, // Replace with actual property name if different
                            starttime: trip.starttime,         // Replace with actual property name if different
                            seconds: trip.seconds,         // Replace with actual property name if different
                        };
                    });
                });

                placeDividersFromClusters(timeCluster.time_cluster);
            }



            // Ensure chart resizes when the window is resized
            window.addEventListener('resize', handleResize);

            const svgElement = document.getElementById('d3-bar-chart');
            if (svgElement) {
                const svgContainer = d3.select(svgElement).select('svg');

                // Attach the mousedown event to handle left-clicks for dividers
                svgElement.addEventListener('mousedown', onGraphLeftClick);

                // Attach mousemove event to change cursor to crosshair when in the margin bounds and in addDraggable mode
                svgContainer.on('mousemove', function (event: MouseEvent) {
                    const xPosition = event.offsetX;
                    const withinBounds = xPosition >= margin.left && xPosition <= svgContainer.attr('width') - margin.right;

                    if (isAddingDraggable.value && withinBounds) {
                        d3.select(svgElement).style('cursor', 'crosshair');
                    } else {
                        d3.select(svgElement).style('cursor', 'default');
                    }
                });
            }

            // Draw the average time lines again after the chart is re-rendered
            if (clustersRef.value.length > 0) {
                drawAverageTimeLines(clustersRef.value);
            }
            recalculateAxis.value = false;
        };



        // Function to re-render the chart on resize
        const handleResize = () => {
            handleReload();
        };

        // Watch filteredTrips to re-render the chart when filtered data changes
        watch(
            props.tripsData,
            () => {
                handleReload();
            },
            { immediate: true }
        );

        watch(
            props.seasonClusters,
            () => {
                handleReload();
            },
            { immediate: true, deep: true }
        );

        watch(
            props.dayClusters,
            () => {
                handleReload();
            },
            { immediate: true, deep: true }
        );

        watch(() => props.activeDayCluster, () => {
            handleReload();
        }, { deep: true, immediate: true });

        watch(() => props.activeSeasonCluster, () => {
            handleReload();
        }, { deep: true, immediate: true });

        watch(() => props.reloadBarChartTrigger, () => {
            handleReload();
        })



        // Ensure chart resizes when the window resizes
        onMounted(() => {
            handleReload();
        });
        // Cleanup the event listener on unmount
        onUnmounted(() => {
            window.removeEventListener('resize', handleResize);
            const svgElement = document.getElementById('d3-bar-chart');
            if (svgElement) {
                svgElement.removeEventListener('mousedown', onGraphLeftClick); // Remove the mousedown event
            }
        });

        return {
            tripsDataAvailable,
            addBarSvg,
            removeBarSvg,
            zoomClusterSvg,
            zoomSvg,
            resetZoomImage,
            handleZoomReset,
            handleZoom,
            scheduleSvg,
            isAddingDraggable,
            addDraggableDiv,
            isScheduleEnabled,
            handleSchedule,
            isZoomEnabled,
            isToolTipEnabled,
            handleToolTip,
            toolTip
        };
    },
});
</script>

<style scoped>
#d3-bar-chart {
    width: 100%;
    height: 100%;
}
</style>