import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import SingleLijnSchedule from '../views/SingleLijnSchedule.vue';
import HomePage from '../views/HomePage.vue';
import LoginPage from '../views/LoginPage.vue'; 
import ErrorPage from '@/views/ErrorPage.vue';


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
    meta: { title: 'Home', requiresAuth: true } // Protect HomePage
  },
  {
    path: '/lijn-planner/:lineId',
    name: 'SingleLijnSchedule',
    component: SingleLijnSchedule,
    meta: { title: 'Lijn planner', requiresAuth: true }, // Protect this route as well
    props: (route: RouteLocationNormalized) => ({
      lineId: route.params.lineId,
      journeyPatternCode: route.params.journeyPatternCode,
      linePublicNumber: route.params.linePublicNumber,
    })
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage,
    meta: { title: 'Login' }
  },
  {
    path: '/error',
    name: 'ErrorPage',
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard to update the title and check authentication
router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || 'HIPE';
  const isAuthenticated = localStorage.getItem('loggedIn') === 'true';

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/login');  // Redirect to login if not authenticated
  } else {
    next();
  }
});

export default router;
