<template>
    <transition name="fade">
        <div v-if="message" class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg"
            :class="messageClass">
            <p>{{ message }}</p>
        </div>
    </transition>
    <transition name="fade">
        <div v-if="resetMessage"
            class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg z-50"
            :class="messageClass">
            <p>{{ resetMessage }}</p>
        </div>
    </transition>
    <transition name="fade">
        <div v-if="saveMessage"
            class="fixed top-0 left-1/2 transform -translate-x-1/2 text-black p-4 rounded shadow-lg z-50"
            :class="messageClass">
            <p>{{ saveMessage }}</p>
        </div>
    </transition>
    <div v-if="!displayTool" class="z-50">
        <SkeletonLoader />
    </div>
    <div v-else class="flex flex-col h-screen">
        <div class="flex w-full h-10 navbar bg-slate-900 px-8 items-center">
            <div class="menu-wrapper flex flex-shrink-0">
                <button
                    class="flex w-40 mx-2 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="returnToHome()">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                    </svg>
                    <p>Lijn selectie</p>
                </button>

            </div>
            <div class="flex-grow"></div>
            <div class="flex items-center text-white">
                <div class="relative">
                    <button @click="handleModelDropdown"
                        class="flex w-96 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-1 px-2 text-xs">
                        <p>{{ trainperiod }}</p>
                        <p>{{ beginAndEndStop }}</p>
                        <span class="text-base">
                            <svg class="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.44l3.71-3.25a.75.75 0 111.04 1.08l-4 3.5a.75.75 0 01-1.04 0l-4-3.5a.75.75 0 01-.02-1.06z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                    </button>
                    <div v-if="expandModelButton && modelMinMax" class="absolute z-50">
                        <ModelInformation :modelMinMax="modelMinMax" :modelInformation="modelInformation"
                            :predictionInformation="predictionInformation" :percentiel="selectedPercentiel"
                            :percentielen="percentielen" :modelId="modelId" @toggle-model="toggleModel" />
                    </div>
                </div>

                <div class="relative">
                    <button
                        class="flex w-36 mx-2 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                        :disabled="isModelRunning" @click="handleRetrainDropdown">
                        <p v-if="!isModelRunning">Hertrain</p>
                        <span v-if="!isModelRunning">
                            <svg class="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                fill="currentColor">
                                <path fill-rule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06-.02L10 10.44l3.71-3.25a.75.75 0 111.04 1.08l-4 3.5a.75.75 0 01-1.04 0l-4-3.5a.75.75 0 01-.02-1.06z"
                                    clip-rule="evenodd" />
                            </svg>
                        </span>
                        <!-- Display loading spinner when task is running -->
                        <span v-else class="flex items-center">
                            <p>Trainen</p>
                            <svg class="ml-2 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                    stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </span>
                    </button>
                    <div v-if="expandRetrainButton" class="absolute z-50">
                        <DateAndRoutePicker :trainperiod="trainperiod" :beginAndEndStop="beginAndEndStop"
                            :routeOrder="linePlanningSettings.stop_combinations" :retrainTimes="retrainTimes"
                            @retrain="handleRetrain" />
                    </div>
                </div>

                <button
                    class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="saveToServer()">
                    <p>Opslaan</p>
                </button>
                <div class="relative">
                    <button
                        class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                        @click="handleLoadButton">
                        <p>Laad</p>
                        <svg class="ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                            fill="currentColor">
                            <path fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06-.02L10 10.44l3.71-3.25a.75.75 0 111.04 1.08l-4 3.5a.75.75 0 01-1.04 0l-4-3.5a.75.75 0 01-.02-1.06z"
                                clip-rule="evenodd" />
                        </svg>
                    </button>
                    <div v-if="loadDropdown"
                        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50">
                        <div class="py-1 z-50">
                            <button v-for="session in sessionList" :key="session.id as string"
                                class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                @click="selectSession(session.id as string)">
                                {{ session.id }} - {{ session.datetime }}
                            </button>
                        </div>
                    </div>
                </div>
                <button
                    class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="generatePDFWithMultipleCharts()">
                    <p>Exporteer</p>
                </button>
                <button
                    class="flex w-24 mx-1 items-center justify-center text-white bg-slate-800 hover:bg-slate-600 py-2 px-4"
                    @click="fullReset()">
                    <p>Reset</p>
                </button>
                <span class="mx-4">{{ userNameLocalOrProps }}</span>
            </div>
        </div>
        <div class="flex h-full overflow-hidden">
            <div class="w-1/4 bg-white p-2 flex flex-col shadow-lg" id="sidebar">
                <!-- info and percentiel selection -->
                <!-- Card container for Bus Info and Percentiel Selection -->
                <div class="bg-white p-4 rounded-sm shadow-md flex items-center justify-between mb-6">

                    <!-- Bus Information Section -->
                    <div class="flex items-start">
                        <!-- Bus Line and Route Info -->
                        <div class="text-gray-700">
                            <h2 class="text-lg font-bold">Buslijn {{ linePublicNumberLocalOrProps }}</h2>
                            <p class="text-sm">{{ lineNameLocalOrProps }}</p>
                            <p class="italic text-sm text-gray-500">Routepatroon {{ journeyPatternCodeLocalOrProps }}
                            </p>
                        </div>
                    </div>

                    <!-- Percentiel Dropdown Section -->
                    <div class="w-1/4 relative">
                        <label class="block text-gray-600 font-semibold text-sm mb-1 text-right">Kies Percentiel</label>
                        <button @click="togglePercentielen"
                            class="w-full bg-gray-200 hover:bg-gray-300 text-black text-sm py-2 px-4 rounded-sm flex items-center justify-between">
                            <span>{{ selectedPercentiel }} %</span>
                            <svg v-if="!isPercentielenOpen" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5"
                                fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 9l-7 7-7-7" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M5 15l7-7 7 7" />
                            </svg>
                        </button>
                        <!-- Dropdown Menu -->
                        <ul v-if="isPercentielenOpen"
                            class="absolute mt-2 w-full bg-white border border-gray-300 rounded-sm shadow-lg z-50 text-gray-700">
                            <li v-for="percentiel in percentielen" :key="percentiel"
                                @click="selectPercentiel(percentiel)"
                                class="px-4 py-2 cursor-pointer hover:bg-gray-100">
                                {{ percentiel }}
                            </li>
                        </ul>
                    </div>

                </div>

                <button @click="toggleCollapseChooseStops"
                    class="w-full bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded-sm my-1 flex items-center">
                    <svg v-if="collapseChooseStops" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                    <span>{{ collapseChooseStops ? 'Kies tijdhaltes' : 'Kies tijdhaltes' }}</span>
                </button>
                <transition name="slide">
                    <div v-if="!collapseChooseStops" class="flex-col">
                        <LineClusteringOverview :routeOrder="routeOrder" :settings="linePlanningSettings"
                            @update-settings="handleUpdateStops" />
                    </div>
                </transition>
                <div class="w-full bg-gray-200 text-black pl-4 pr-1 rounded-sm my-1 flex justify-between">
                    <button class="w-7/12 hover:bg-gray-300 py-2 mr-1 flex items-center"
                        @click="toggleCollapseChooseSeasons">
                        <svg v-if="collapseChooseSeasons" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2"
                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                        </svg>
                        <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                            viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                        </svg>
                        <span>{{ collapseChooseSeasons ? 'Kies Seizoenen' : 'Kies Seizoenen' }}</span>
                    </button>

                    <!-- Dropdown for seasonClusterTexts -->
                    <div v-if="seasonClusterTexts.length > 1" class="flex items-center pr-4">
                        <select v-model="activeSeasonCluster"
                            class="w-32 bg-white border border-gray-300 rounded-sm shadow-sm text-sm">
                            <option v-for="(seasonText, index) in seasonClusterTexts" :key="index" :value="index">
                                {{ seasonText }}
                            </option>
                        </select>
                    </div>
                </div>
                <transition name="slide">
                    <div v-if="!collapseChooseSeasons" class="flex overflow-auto">
                        <BarSeasonsD3 :seasonsData="seasonsData" :seasonClusters="linePlanningSettings.season_clusters"
                            :barChartSeasonTrigger="barChartSeasonTrigger" @update-season-cluster="handleUpdateSeasons"
                            @barseason-finished="handleSeasonchartFinished" />
                    </div>
                </transition>
                <button @click="toggleCollapseChooseDays"
                    class="w-full bg-gray-200 hover:bg-gray-300 text-black py-2 px-4 rounded-sm my-1 flex items-center">
                    <svg v-if="collapseChooseDays" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                    </svg>
                    <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
                        stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                    </svg>
                    <span>{{ collapseChooseDays ? 'Kies Dagen' : 'Kies Dagen' }}</span>
                </button>
                <transition name="slide">
                    <div v-if="!collapseChooseDays" class="flex overflow-auto">
                        <BarDaysD3 :daysData="daysData" :dayClusters="linePlanningSettings.day_clusters"
                            :barChartDayTrigger="barChartDayTrigger" @update-day-cluster="handleUpdateDays"
                            @barday-finished="handleDaychartFinished" />
                    </div>
                </transition>
            </div>
            <div class="w-3/4 h-full flex flex-col p-4">
                <div class="shadow-lg flex flex-col h-full rounded-sm">
                    <div class="flex bg-gray-200 rounded-t-sm justify-between">
                        <div class="flex">
                            <div class="w-16"></div>
                            <button v-for="(clusterText, index) in dayClusterTexts" :key="index"
                                class="bg-gray-200 py-2 px-4 hover:bg-gray-100" @click="handleDayClusterClick(index)"
                                :class="{ 'active-day-cluster': index === activeDayCluster }">
                                {{ clusterText }}
                            </button>
                        </div>
                    </div>
                    <div v-if="tripsDataAvailable" class="barchartline-container flex" id="barChartLine">
                        <BarChartLine :tripsData="tripsData" :scheduleData="lineScheduleTotals"
                            :activeDayCluster="activeDayCluster" :dayClusters="linePlanningSettings.day_clusters"
                            :timeClusters="linePlanningSettings.time_clusters"
                            :activeSeasonCluster="activeSeasonCluster"
                            :seasonClusters="linePlanningSettings.season_clusters"
                            :reloadBarChartTrigger="reloadBarChartTrigger"
                            @update-time-cluster="handleUpdateTimeCluster" @barline-finished="handleBarchartFinished" />
                    </div>

                    <div class="flex w-full items-center bg-gray-200 ">
                        <div class="w-16"></div>
                        <div v-if="showPredictionTable" class="flex overflow-x-auto">
                            <button v-for="(timeCluster, clusterIndex) in getActiveTimeClusters()" :key="clusterIndex"
                                class="bg-gray-200 py-2 px-4 flex justify-center items-center text-xs hover:bg-gray-100"
                                @click="handleTimeClusterClick(clusterIndex)"
                                :class="{ 'active-time-cluster': clusterIndex === activeTimeCluster }">
                                <span class="truncate">Cluster {{ getTimeClusterInfo(clusterIndex) }}</span>
                            </button>
                        </div>
                        <div class="w-16"></div>
                    </div>
                    <div class="flex-grow">
                        <PredictionTable v-if="showPredictionTable" :route="routeOrder" :percentiel="selectedPercentiel"
                            :predictions="combinedPredictions" :schedule="lineSchedule"
                            :linePlanningSettings="linePlanningSettings" :activeTimeCluster="activeTimeCluster"
                            :activeDayCluster="activeDayCluster" :activeSeasonCluster="activeSeasonCluster"
                            :key="predictionTrigger" :timeClusterTrigger="timeClusterTrigger"
                            @update-chosen-times="updateChosenTimes" @set-percentage="setPercentageAsStandard"
                            :chosenMemory="chosenMemory" id="predictionTable" />

                        <div v-else class="flex h-full items-center justify-center">
                            <LoadingAnimationComp />
                        </div>
                    </div>
                    <div v-if="linePublicNumberLocalOrProps === '306' && journeyPatternCodeLocalOrProps === '28' && modelId === 'standaard'"
                        class="mx-3 p-4 rounded shadow-lg bg-red-300 text-black">
                        <p>Pas op! Er missen voorspellingen voor onderliggende haltes (<i>Purmerend, Churchilllaan -
                                Purmerend, Station Overwhere</i>).</p>
                    </div>
                    <div v-if="linePublicNumberLocalOrProps === '306' && journeyPatternCodeLocalOrProps === '29' && modelId === 'standaard'"
                        class="mx-3 p-4 rounded shadow-lg bg-red-300 text-black">
                        <p>Pas op! Er missen voorspellingen voor onderliggende haltes (<i>Purmerend, Station Overwhere - Purmerend, Churchilllaan
                                </i>).</p>
                    </div>
                    <div v-if="linePublicNumberLocalOrProps === '306' && (journeyPatternCodeLocalOrProps === '28' || journeyPatternCodeLocalOrProps === '29') && modelId === 'standaard'"
                        class="m-3 p-4 rounded shadow-lg bg-red-300 text-black">
                        <p>Pas op! Er is een verschil in haltes tussen de meest recente dienstregeling en de gewenste
                            route.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, computed, watch } from 'vue';
import {
    Bus,
    Lijn,
    LinePlanningSettings,
    Route,
    RouteMessage,
    Day,
    DayData,
    PREDICTION,
    Block,
    TimeClusterData,
    AveragedPrediction,
    Chosen,
    ChosenMemory,
    SessionData,
    SessionShorthand,
    Schedule,
    LineSchedule,
    LineScheduleTotals,
    Season,
    SeasonData,
    ClusteredSchedule,
    PercentilePredictions,
    ArrayPredictions,
    WasmExports,
    PredictionParts,
    ModelInfo,
    ModelMinMax
} from '@/types'
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';
import BarChartLine from '@/components/BarChartD3.vue';
import LineClusteringOverview from '@/components/LineClusteringOverview.vue'
import BarDaysD3 from '@/components/BarDaysD3.vue'
import PredictionTable from '@/components/PredictionTable.vue'
import LoadingAnimationComp from '@/components/LoadingAnimationComp.vue'
import DateAndRoutePicker from '@/components/DateAndRoutePicker.vue'
import BarSeasonsD3 from '@/components/BarSeasonsD3.vue';
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import ModelInformation from '@/components/ModelInformation.vue';
// Import necessary libraries
import { jsPDF } from 'jspdf';

import domtoimage from 'dom-to-image';

export default defineComponent({
    name: 'SingleLijnSchedule',
    components: {
        BarChartLine,
        LineClusteringOverview,
        BarDaysD3,
        BarSeasonsD3,
        PredictionTable,
        LoadingAnimationComp,
        DateAndRoutePicker,
        SkeletonLoader,
        ModelInformation
    },
    props: {
        linePublicNumber: {
            type: String,
            required: true
        },
        lineId: {
            type: String,
            required: true
        },
        journeyPatternCode: {
            type: String,
            required: true
        },
    },

    setup(props) {
        // Setting local variables stored client side
        const userNameLocalOrProps = ref<string | null>(localStorage.getItem('userName'));
        const userIdLocalOrProps = ref<string | null>(localStorage.getItem('userId'));
        const lineNameLocalOrProps = ref<string | null>(localStorage.getItem('lineName'));
        const journeyPatternCodeLocalOrProps = ref<string | null>(localStorage.getItem('journeyPatternCode'));
        const linePublicNumberLocalOrProps = ref<string | null>(localStorage.getItem('linePublicNumber'));


        // Setting responsive data variables
        const tripsData = ref<Bus[]>([]);
        const message = ref<string | null>(null);
        const routeOrder = ref<Route[]>([]);
        const daysData = ref<Day[]>([]);
        const seasonsData = ref<Season[]>([]);
        const activeDayCluster = ref<number>(0);
        const activeTimeCluster = ref<number>(0);
        const allPredictions = ref<PREDICTION[]>([]);
        const allArrays = ref<ArrayPredictions>();
        const predictionInformation = ref<PredictionParts>();
        const combinedPredictions = ref<AveragedPrediction[]>([]);
        const chosenMemory = ref<ChosenMemory[]>([]);
        const sessionList = ref<SessionShorthand[]>([]);
        const expandRetrainButton = ref<boolean>(false);
        const isModelRunning = ref<boolean>(false);
        const expandModelButton = ref<boolean>(false);

        const error = ref<string>("");
        let messageType = ref<string>("");
        let resetMessage = ref<string>("");
        let saveMessage = ref<string>("");

        const dayMapper: { [key: string]: string } = {
            monday: 'Maandag',
            tuesday: 'Dinsdag',
            wednesday: 'Woensdag',
            thursday: 'Donderdag',
            friday: 'Vrijdag',
            saturday: 'Zaterdag',
            sunday: 'Zondag'
        };

        const seasonMapper: { [key: string]: string } = {
            summer: 'Zomer',
            spring: 'Lente',
            winter: 'Winter',
            autumn: 'Herfst',
        };


        // Setting state management variables
        const isLoading = ref<boolean>(true);
        const displayTool = ref<boolean>(false);
        const routeLoading = ref<boolean>(true);
        const predictionLoading = ref<boolean>(true);
        const collapseChooseStops = ref<boolean>(true);
        const collapseChooseDays = ref<boolean>(false);
        const collapseChooseSeasons = ref<boolean>(false);
        const showPredictionTable = ref<boolean>(false);
        const tripsDataAvailable = ref<boolean>(false);
        const loadDropdown = ref<boolean>(false);
        const loadedTimeClustersFromDb = ref<boolean>(false);
        const reloadBarChartTrigger = ref<number>(0);
        const predictionTrigger = ref<number>(0);
        const barChartDayTrigger = ref<number>(0);
        const barChartSeasonTrigger = ref<number>(0);
        const percentielen = ref<number[]>([50, 80, 85]);
        const selectedPercentiel = ref<number>(80);
        const isPercentielenOpen = ref<boolean>(false);
        const schedule = ref<Schedule[]>([]);
        const scheduleLoading = ref<boolean>(true);
        const lineSchedule = ref<LineSchedule[]>([])
        const lineScheduleTotals = ref<LineScheduleTotals[]>([])
        const beginAndEndStop = ref<string>("");
        const trainperiod = ref<string>("");
        const retrainTimes = ref<string[]>([]);
        const activeSeasonCluster = ref<number>(0);
        const allSeasons = ref<string[]>(['Winter', 'Spring', 'Summer', 'Autumn'])
        const modelInformation = ref<ModelInfo[]>([]);
        const modelId = ref<string>("standaard")
        const modelMinMax = ref<ModelMinMax>();

        // Setting the global route variable to access parameters passed from the HomePage
        const route = useRoute();


        // Initializing the LinePlanningSettings this variable stores all the information necessary for building the front end
        const linePlanningSettings = ref<LinePlanningSettings>({
            line_id: props.lineId as string, time_clusters: [], day_clusters: [], stop_combinations: [], season_clusters: [[{ season: 'Summer' }, { season: 'Spring' }, { season: 'Winter' }, { season: 'Autumn' },]],
        });

        const fetchRoute = async () => {
            try {
                const response = await axios.get<RouteMessage>(`${process.env.VUE_APP_BACKEND_URL}/routeoverzicht/${props.lineId}`);
                if (response.data.ROUTE) {
                    routeOrder.value = response.data.ROUTE;
                    beginAndEndStop.value = routeOrder.value[0].id_userstopname_combination + ' — ' + routeOrder.value[routeOrder.value.length - 1].id_userstopname_combination
                } else {
                    routeOrder.value = [];
                }
            } catch (err) {
                error.value = 'Failed to load the order data';
                console.error(err);
            } finally {
                routeLoading.value = false;
            }
        };

        // const fetchPredictionLijn = async () => {
        //     try {
        //         const response = await axios.get<PREDICTION[]>(`${process.env.VUE_APP_BACKEND_URL}/allpredictions/${props.lineId}`);
        //         allPredictions.value = response.data;
        //         console.log("original", response.data)
        //         seasonsData.value = determineSeasonsData(tripsData.value)
        //         daysData.value = determineDaysData(tripsData.value);
        //     } catch (err) {
        //         error.value = 'Failed to load blocks data';
        //         console.error(err);
        //     } finally {
        //         predictionLoading.value = false;
        //     }
        // };


        const fetchArrays = async (predictionId?: string) => {
            try {
                // Construct the URL based on whether predictionId is provided
                let url = `${process.env.VUE_APP_BACKEND_URL}/allarrays/${props.lineId}`;

                // If predictionId is provided, append it to the URL as a query parameter
                if (predictionId) {
                    url += `?predictionId=${predictionId}`;
                }

                // Make the API request using the constructed URL
                const response = await axios.get<ArrayPredictions>(url);
                allArrays.value = response.data;

                if (!response.data.single_prediction_arr_start || !response.data.single_prediction_arr_start.predictions) {
                    throw new Error("Missing the total prediction and prediction information");
                }

                predictionInformation.value = response.data.single_prediction_arr_start.predictions;

                tripsData.value = determineTotalTimesFromPrediction(allArrays.value);
                seasonsData.value = determineSeasonsData(tripsData.value);
                daysData.value = determineDaysData(tripsData.value);
                modelMinMax.value = determineModelMinMax(seasonsData.value, daysData.value, tripsData.value, response.data.single_prediction_arr_start.crowding)
                // Here we can determine the min and max times for the model info
            } catch (err) {
                console.error(err);
            } finally {
                predictionLoading.value = false;
            }
        };

        const determineModelMinMax = (seasonsData: Season[], daysData: Day[], tripsData: Bus[], crowding: number[]): ModelMinMax => {
            const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]
            const saturday = ["Saturday"]
            const sunday = ["Sunday"]
            const weekTrips = tripsData.filter(trip => weekdays.includes(trip.day!));
            const saturdayTrips = tripsData.filter(trip => saturday.includes(trip.day!));
            const sundayTrips = tripsData.filter(trip => sunday.includes(trip.day!));
            function getMinAndMaxTime(trips: Bus[]) {
                const times = trips.map(trip => trip.total_time!);
                const min = Math.min(...times);
                const max = Math.max(...times);
                return { min, max };
            }
            const crowdingTimeRange = { min: Math.min(...crowding), max: Math.max(...crowding) }
            const weekdaysTimeRange = getMinAndMaxTime(weekTrips);
            const saturdayTimeRange = getMinAndMaxTime(saturdayTrips);
            const sundayTimeRange = getMinAndMaxTime(sundayTrips);

            const seasonTimes = seasonsData.map(item => item.average_time!)
            const seasonTimeRange = { min: Math.min(...seasonTimes), max: Math.max(...seasonTimes) }

            const dayTimes = daysData.map(item => item.average_time!)
            const dayTimeRange = { min: Math.min(...dayTimes), max: Math.max(...dayTimes) }
            return {
                weekdaysTimeRange,
                saturdayTimeRange,
                sundayTimeRange,
                seasonTimeRange,
                dayTimeRange,
                crowdingTimeRange
            }

        }


        const fetchAllSessions = async () => {
            try {
                const response = await axios.post<SessionShorthand[]>(`${process.env.VUE_APP_BACKEND_URL}/get_all_sessions`, {
                    user_id: userIdLocalOrProps.value,
                    line_id: props.lineId,
                });
                sessionList.value = response.data.map(item => {
                    if (!item.datetime) {
                        throw new Error("Missing date time")
                    }
                    const formattedDate = formatDate(item.datetime)
                    return {
                        ...item,
                        datetime: formattedDate
                    }
                })
            } catch (err) {
                console.error('Failed to load sessions:', err);
            }
        };

        const fetchSingleSession = async (session_id: string) => {
            try {
                const response = await axios.get<SessionData>(`${process.env.VUE_APP_BACKEND_URL}/get_session/${session_id}`);
                loadedTimeClustersFromDb.value = true;
                if (!response.data.line_planning_settings || !response.data.chosen_memory) {
                    throw new Error("No session details were provided in the save file");
                }
                linePlanningSettings.value = response.data.line_planning_settings
                chosenMemory.value = response.data.chosen_memory
                await runClusterAndAverage();
                //await handleChosenTimesInitialization();
                barChartDayTrigger.value += 1
                barChartSeasonTrigger.value += 1
                reloadBarChartTrigger.value += 1
                predictionTrigger.value += 1

            } catch (err) {
                console.error('Failed to load single session:', err);
            }
        };

        const fetchModelInformation = async () => {
            try {
                const response = await axios.get<ModelInfo[]>(`${process.env.VUE_APP_BACKEND_URL}/get_model_info/${props.lineId}`);
                modelInformation.value = response.data
                if (modelId.value) {
                    trainperiod.value = modelInformation.value.find(item => item.prediction_id === modelId.value)?.train_period || "missing"
                } else {
                    trainperiod.value = modelInformation.value.find(item => item.prediction_id === 'standaard')?.train_period || "missing"
                }
            } catch (err) {
                console.error('Failed to load model information:', err);
            }
        };


        const fetchSchedule = async () => {
            try {
                const response = await axios.get<Schedule[]>(`${process.env.VUE_APP_BACKEND_URL}/schedule/${props.lineId}`);
                schedule.value = response.data;
                lineSchedule.value = transformSchedule(response.data)
                lineScheduleTotals.value = lineSchedule.value.map((item) => {
                    const totalSchedule = item.schedule.reduce((sum, item) => sum + hhmmssToSeconds(item.time_planned as string), 0)
                    return {
                        id: item.id,
                        total: totalSchedule
                    }
                })
            } catch (err) {
                error.value = 'Failed to load blocks data';
                console.error(err);
            } finally {
                scheduleLoading.value = false;
            }
        }

        // Data sending to the back end
        const uploadToServer = async (sessionData: SessionData) => {
            try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/save_session`, sessionData);
                if (!response) {
                    throw new Error("some serverside error for uploading the session")
                }
                const baseMessage = 'De sessie settings zijn opgeslagen!';
                saveMessage.value = baseMessage;
                messageType.value = 'good';
                setTimeout(() => {
                    saveMessage.value = '';
                }, 3000);
            } catch (err) {
                console.error('Failed to save session data:', err);
                messageType.value = 'bad';
                const baseMessage = 'De sessie is niet opgeslagen, error: ';
                saveMessage.value = baseMessage.concat(err as string);
            }
        };


        const retrainModel = async (retrainSessionName: string, includeDates: string, excludeDates: string, stops: string[]) => {
            isModelRunning.value = true; // Set to true when task starts
            try {
                const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/retrain_model`, {
                    lineId: props.lineId,
                    linePublicNumber: props.linePublicNumber,
                    retrainSessionName: retrainSessionName,
                    includeDates: includeDates,
                    excludeDates: excludeDates,
                    stops: stops
                });
                const modelId = response.data.model_id;

                const intervalId = setInterval(async () => {
                    try {
                        const statusResponse = await axios.get(`${process.env.VUE_APP_BACKEND_URL}/model_status/${modelId}`);
                        const taskStatus = statusResponse.data;

                        if (taskStatus.state === 'SUCCESS' || taskStatus.state === 'FAILURE') {
                            clearInterval(intervalId);
                            isModelRunning.value = false; // Set to false when task is complete
                        }
                    } catch (statusError) {
                        console.error('Error checking task status:', statusError);
                        clearInterval(intervalId);
                        isModelRunning.value = false; // Set to false on error
                    }
                }, 2000);

            } catch (error) {
                console.error('Error starting task:', error);
                isModelRunning.value = false; // Set to false on error
            }
        }

        const fetchRetrainTimes = () => {
            retrainTimes.value = ["2020", "01/2020-03/2021", "2023"]
        }


        // Handling data loading and state management based on the loaded data
        const loadData = async (predictionId?: string) => {
            try {
                isLoading.value = true; // Set loading to true before starting any async operations

                // Fetch and await the essential data required for schedule initialization
                await Promise.all([fetchRoute(), fetchSchedule(), fetchRetrainTimes(), fetchModelInformation()]);

                // Ensure that the line schedule is fully initialized before fetching additional data
                if (!lineSchedule.value.length) {
                    throw new Error('Line schedule not initialized correctly');
                }

                // Fetch remaining data only after the first set is successfully initialized
                await Promise.all([fetchAllSessions(), fetchArrays(predictionId)]);

                initializeStandardStops();

            } catch (err) {
                console.error('Error while loading data', err);
            } finally {
                isLoading.value = false; // Ensure loading state is only set to false after everything is initialized
            }
        };

        const handleRetrain = (retrainSessionName: string, includeDates: string, excludeDates: string, stops: string[]) => {
            retrainModel(retrainSessionName, includeDates, excludeDates, stops);
            expandRetrainButton.value = false;
        }

        const toggleModel = async (predictionId: string) => {
            combinedPredictions.value = [];
            chosenMemory.value = [];
            linePlanningSettings.value.time_clusters = [];
            expandModelButton.value = !expandModelButton.value;
            displayTool.value = false;
            modelId.value = predictionId;
            await loadData(modelId.value);
            await runClusterAndAverage();
            await handleChosenTimesInitialization();
            displayTool.value = true;
            showPredictionTable.value = true;
            predictionTrigger.value += 1;
        }


        const handleOnMounted = async () => {
            await loadWasm();
            await loadData(modelId.value);
            await runClusterAndAverage();
            await handleChosenTimesInitialization();
            displayTool.value = true;
            showPredictionTable.value = true;
            predictionTrigger.value += 1;
        };
        onMounted(handleOnMounted);


        function transformSchedule(schedule: Schedule[]): LineSchedule[] {
            const groupedSchedules: { [key: string]: LineSchedule } = {};

            schedule.forEach(entry => {
                // Split the ID into parts and remove the segment with the stop IDs (e.g., 54470130_54470150)
                const idParts = entry.id.split('_');
                const highOrderId = `${idParts[0]}_${idParts[1]}_${idParts[2]}_${idParts[3]}_${idParts[6]}_${idParts[7]}`;

                // If the high order ID doesn't exist in groupedSchedules, create a new entry
                if (!groupedSchedules[highOrderId]) {
                    groupedSchedules[highOrderId] = { id: highOrderId, schedule: [] };
                }

                // Add the current entry to the corresponding schedule list
                groupedSchedules[highOrderId].schedule.push(entry);
            });

            // Convert the groupedSchedules object to an array
            return Object.values(groupedSchedules);
        }

        function extractDayTimeSeasonJourneynumber(id: string) {
            const parts = id.split('_');
            const journeynumber = parts[3];
            const day = parts[4];  // Assuming the 4th part is the day
            const season = parts[5];
            const startTime = parts[6];  // Assuming the 6th part is the start time
            return { day, startTime, season, journeynumber };
        }

        const hhmmssToSeconds = (hhmmss: string): number => {
            const [h, m, s] = hhmmss.split(':').map(Number);
            return h * 3600 + m * 60 + s
        }


        const determineTotalTimesFromPrediction = (data: ArrayPredictions): Lijn[] => {
            const percentielSelector = parseFloat((selectedPercentiel.value / 100).toFixed(2)).toString();
            const startTimeInfo = data.starttimes;
            const idsArray = data.single_prediction_arr_start?.ids;
            const timesArray = data.single_prediction_arr_start?.times; // Assuming this is a 2D Float32Array

            if (!idsArray || !timesArray) {
                throw new Error("Data is missing ids or times.");
            }
            const prefix = idsArray[idsArray.length - 1].split('_').slice(0, -1).join('_');
            // Filter matching indexes based on the prefix and percentile
            const matchingIndexes = idsArray.reduce((acc: number[], id, index) => {
                const idPrefix = id.split('_').slice(0, -1).join('_'); // Remove the last part of the ID (percentile)
                if (idPrefix === prefix && id.split('_').pop() === percentielSelector) {
                    acc.push(index);
                }
                return acc;
            }, []);

            if (matchingIndexes.length === 0) {
                throw new Error("No matching IDs found for the prefix and percentile selector.");
            }

            // Generate the result using the matching indexes
            let result: Bus[] = [];
            startTimeInfo.forEach((starttime, index) => {
                const { day, startTime, season, journeynumber } = extractDayTimeSeasonJourneynumber(starttime);
                result.push({
                    id: starttime,
                    day,
                    season,
                    journeynumber,
                    start_time: startTime,
                    total_time: timesArray[index][matchingIndexes[0]] // Use the matching index
                });
            });

            tripsDataAvailable.value = true;
            return result;
        };

        // Functions for handling the saving and loading of different sessions from the backend
        const handleLoadButton = () => {
            loadDropdown.value = !loadDropdown.value;
        };

        const selectSession = async (session_id: string) => {
            // isLoading.value = true;
            try {
                await fetchSingleSession(session_id);
            } catch (err) {
                console.error(err);
            } finally {
                isLoading.value = false;
                loadDropdown.value = false;
            }
        };

        const saveToServer = async () => {
            const sessionData: SessionData = {
                user_id: userIdLocalOrProps.value as string,
                line_id: props.lineId,
                line_planning_settings: linePlanningSettings.value,
                chosen_memory: chosenMemory.value
            }
            await Promise.all([uploadToServer(sessionData)]);
            fetchAllSessions();
        }


        // Capture multiple charts and pass to PDF generation
        // eslint-disable-next-line
        // Capture multiple charts with custom cropping and pass to PDF generation in order
        function captureChartsAsImages(chartElementIds: string[], cropConfigs: { [key: string]: { left: number, top: number } }): Promise<string[]> {
            // Create an array of promises to capture each chart image in order
            const capturePromises = chartElementIds.map((chartElementId) => {
                return new Promise<string>((resolve, reject) => {
                    const chartElement = document.getElementById(chartElementId);
                    if (!chartElement) {
                        console.error(`Chart element with id ${chartElementId} not found`);
                        reject(`Chart element with id ${chartElementId} not found`);
                        return;
                    }

                    const originalWidth = chartElement.clientWidth;
                    const originalHeight = chartElement.clientHeight;

                    domtoimage.toPng(chartElement)
                        .then((dataUrl: string) => {
                            const img = new Image();
                            img.src = dataUrl;

                            img.onload = () => {
                                // Apply cropping for each image using a canvas
                                const cropConfig = cropConfigs[chartElementId];
                                const canvas = document.createElement("canvas");
                                canvas.width = originalWidth - cropConfig.left;
                                canvas.height = originalHeight - cropConfig.top;
                                const ctx = canvas.getContext("2d");

                                if (ctx) {
                                    ctx.drawImage(
                                        img,
                                        cropConfig.left,
                                        cropConfig.top,
                                        originalWidth,
                                        originalHeight,
                                        0,
                                        0,
                                        originalWidth,
                                        originalHeight
                                    );
                                    resolve(canvas.toDataURL("image/png"));
                                } else {
                                    reject("Canvas context is not available.");
                                }
                            };
                        })
                        .catch((error: any) => {
                            console.error('Error capturing chart image:', error);
                            reject(error);
                        });
                });
            });

            // Use Promise.all to ensure images are captured in the correct order
            return Promise.all(capturePromises);
        }

        // Generate PDF with multiple cropped images in order
        async function generatePDF(chartImages: string[]) {
            const pdf = new jsPDF();
            const now = new Date();
            const customDateTime = now.toISOString().replace(/[:T]/g, "-").slice(0, 19);

            pdf.setFontSize(14);
            pdf.text(`Rapport - Buslijn ${linePublicNumberLocalOrProps.value}`, 20, 20);
            pdf.setFontSize(12);
            pdf.text(`Routepatroon ${journeyPatternCodeLocalOrProps.value} - ${lineNameLocalOrProps.value}`, 20, 26);
            pdf.setFontSize(10);
            pdf.text(customDateTime, 20, 30);

            // Add each image to the PDF in the specified order
            let imageYPosition = 40;
            for (const image of chartImages) {
                const img = new Image();
                img.src = image;

                await new Promise((resolve) => {
                    img.onload = () => {
                        const aspectRatio = img.width / img.height;
                        const maxWidth = 180;
                        const height = maxWidth / aspectRatio;

                        pdf.addImage(image, "PNG", 15, imageYPosition, maxWidth, height);
                        imageYPosition += height + 10;  // Adjust spacing between images
                        resolve(null);
                    };
                });
            }

            const pdfBlob = pdf.output('blob');
            const pdfUrl = URL.createObjectURL(pdfBlob);
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = `HIPE_export_${customDateTime}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(pdfUrl);
        }

        // Function to initiate capturing and PDF generation in order
        async function generatePDFWithMultipleCharts() {
            const chartElementIds = ['barChartLine', 'predictionTable'];  // List of chart IDs to capture
            exportToCSV()
            // Define custom cropping configurations for each chart
            const cropConfigs = {
                barChartLine: { left: 65, top: 0 },
                predictionTable: { left: 0, top: 55 },
            };

            try {
                const chartImages = await captureChartsAsImages(chartElementIds, cropConfigs);
                generatePDF(chartImages);
            } catch (error) {
                console.error("Error generating PDF:", error);
            }
        }


        function exportToCSV() {
            // Define the CSV header
            const csvRows = ['clusterId,dayId,timeId,seasonId,blockId,inputMeethalte,timeInHHMMSS'];
            if (!linePlanningSettings.value.stop_combinations) {
                throw new Error("Missing stop combinations so no export");
            }

            // Function to convert seconds to HH:MM:SS format
            function secondsToHHMMSS(seconds: number) {
                const hours = Math.floor(seconds / 3600).toString().padStart(2, '0');
                const minutes = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
                const secs = (seconds % 60).toFixed(0).padStart(2, '0');
                return `${hours}:${minutes}:${secs}`;
            }

            // Loop through the array of clusters
            const meethaltes = generateMeethalteIds(linePlanningSettings.value.stop_combinations);
            chosenMemory.value.forEach(cluster => {
                const { clusterId, dayId, timeId, seasonId, chosenTimes } = cluster;
                const times = timeId.split('_');
                let timeRange = '';
                if (times.length > 1) {
                    const startTime = times[0].split(':')[0] + ':00:00';
                    const endTime = (parseInt(times[times.length - 1].split(':')[0], 10)).toString().padStart(2, '0') + ':59:59';
                    timeRange = `${startTime}_${endTime}`;
                } else if (times.length === 1) {
                    const startTime = times[0].split(':')[0] + ':00:00';
                    const endTime = (parseInt(times[0].split(':')[0], 10)).toString().padStart(2, '0') + ':59:59';
                    timeRange = `${startTime}_${endTime}`;
                }

                // Filter out the chosenTimes where meethalte is true
                chosenTimes.filter(item => item.meethalte).forEach((item, index) => {
                    const { blockId, inputMeethalte } = item;
                    if (!blockId) {
                        throw new Error("block without an id");
                    }

                    // Assuming inputMeethalte is in seconds, convert it to HH:MM:SS
                    const timeInHHMMSS = inputMeethalte ? secondsToHHMMSS(inputMeethalte) : '00:00:00';

                    // Construct the CSV row with the necessary columns
                    const idParts = blockId?.split('_');
                    const reconstructedId = idParts[0] + '_' + idParts[1] + '_' + idParts[2] + '_' + meethaltes[index];
                    const row = `${clusterId},${dayId},${timeRange},${seasonId},${reconstructedId},${inputMeethalte},${timeInHHMMSS}`;
                    csvRows.push(row);
                });

                // Add the total time of a cluster
                const totalIdParts = chosenTimes[0].blockId?.split('_');
                if (!totalIdParts || !linePlanningSettings.value.stop_combinations) {
                    throw new Error("Missing id for the total chosen value");
                }
                const totalId = totalIdParts[0] + '_' + totalIdParts[1] + '_' + totalIdParts[2] + '_' + generateTotalId(linePlanningSettings.value.stop_combinations);
                const totalChosenHHMMSS = secondsToHHMMSS(cluster.totalChosen); // Convert totalChosen to HH:MM:SS
                csvRows.push(`${clusterId},${dayId},${timeRange},${seasonId},${totalId},${cluster.totalChosen},${totalChosenHHMMSS}`);
            });

            // Join the rows with a newline character
            const csvContent = csvRows.join('\n');
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');

            const customDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            // Download the CSV file
            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.setAttribute('hidden', '');
            a.setAttribute('href', url);

            a.setAttribute('download', `HIPE_export_${customDateTime}.csv`);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);


            return csvRows
        }


        function generateMeethalteIds(stops: Route[]): string[] {
            const meethalteIds: string[] = [];
            let previousMeethalte: Route | null = null;

            // Iterate through the stops
            for (const stop of stops) {
                if (stop.meethalte && stop.type !== 'rijtijd') {
                    // If we have a previous meethalte, generate the new ID
                    if (previousMeethalte) {
                        const newId = `${previousMeethalte.id_areacode_combination.split('_')[0]}_${stop.id_areacode_combination.split('_')[0]}`;
                        meethalteIds.push(newId);
                    }
                    // Update the previous meethalte to the current one
                    previousMeethalte = stop;
                }
            }
            return meethalteIds;
        }

        function generateTotalId(stops: Route[]): string {
            return `${stops[0].id_areacode_combination.split('_')[0]}_${stops[stops.length - 1].id_areacode_combination.split('_')[0]}`;
        }


        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////// Implementation of the chosenTimes initialization for all clusters /////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // The idea is as follows, loop through all possible clusters, check if the cluster already exists, if it does not, initialize it.
        // This should end up initializing the entire chosenMemory on first load, if the clusters change only the affected clusters change.
        // When we start exporting we probably have to start checking for active clusters again. 

        const initiateTimeClusters = async () => {
            linePlanningSettings.value.season_clusters.forEach(seasons => {
                linePlanningSettings.value.day_clusters.forEach(days => {

                    const activeDays = days.map(day => day.day);
                    const dayCluster = activeDays.join('_');
                    const activeSeasons = seasons.map(season => season.season);
                    const seasonCluster = activeSeasons.join('_');
                    if (!linePlanningSettings.value.time_clusters?.find(item => (item.day_cluster === dayCluster && item.season_cluster === seasonCluster) || !(item.time_cluster.length > 0))) {
                        const filteredTrips = tripsData.value.filter(trip => activeDays.includes(trip.day) && activeSeasons.includes(trip.season));
                        const uniqueStartTimes = new Set<string>();
                        const startTimes = filteredTrips
                            .filter(trip => {
                                const starttime = trip.start_time as string;
                                if (!uniqueStartTimes.has(starttime)) {
                                    uniqueStartTimes.add(starttime);
                                    return true; // Keep this trip if starttime is unique
                                }
                                return false; // Filter out duplicate starttime
                            })
                            .map(trip => {
                                return {
                                    starttime: trip.start_time as string,
                                    journeynumber: trip.journeynumber as string,
                                    height: 0,
                                    xAxisPosition: 0,
                                    seconds: trip.total_time as number,
                                };
                            });
                        linePlanningSettings.value.time_clusters?.push({ day_cluster: dayCluster, season_cluster: seasonCluster, time_cluster: [startTimes] })
                    }

                })
            })
        }

        const handleChosenTimesInitialization = async () => {
            if (!linePlanningSettings.value.stop_combinations) {
                throw new Error("We are missing the necessary stop information");
            }
            // Get all the tracks:
            const stops = linePlanningSettings.value.stop_combinations
            const tracks = getTracksFromStopCombinations(stops);
            const percentielSelector = parseFloat((selectedPercentiel.value / 100).toFixed(2)).toString();
            // Preset all the timeclusters to all the trips matching days and seasons
            // build all the cluster id's:
            linePlanningSettings.value.time_clusters?.forEach(timeCluster => {
                // Build all the cluster id's
                const season = timeCluster.season_cluster;
                const day = timeCluster.day_cluster;


                timeCluster.time_cluster.forEach(timeRange => {
                    const journey = timeRange.map((timeItem) => timeItem.journeynumber).join("_");
                    const time = timeRange.map(item => item.starttime).join('_');
                    const clusterId = getClusterId(season, day, time);
                    if (!chosenMemory.value.find(item => item.clusterId === clusterId)) {
                        const schedule = clusterSchedule(lineSchedule.value, time, day, season, journey, stops)
                        const clusterPrediction = getClusterPrediction(season, day, time, tracks, percentielSelector);
                        if (!clusterPrediction) {
                            throw new Error("Missing prediction for ....")
                        }
                        const chosenTimes = tracks.map(track => {
                            const blockId = track.prediction_id + '_' + percentielSelector;
                            let missing = false;
                            let scheduleMissing = false;
                            let prediction = 0;
                            if (track.meethalte) {
                                let pred = clusterPrediction.single_prediction_arr?.find(item => item.id === blockId)?.time;
                                if (pred) {
                                    prediction = pred;
                                }
                            } else {
                                let pred = clusterPrediction.single_prediction_dep?.find(item => item.id === blockId)?.time;
                                if (pred) {
                                    prediction = pred;
                                }
                            }

                            if (prediction !== 0) {
                                var input = prediction
                            } else if (getSchedule(blockId, schedule, season, day, time) !== 'No schedule') {
                                missing = true;
                                var scheduledTime = getSchedule(blockId, schedule, season, day, time).split('-')
                                if (scheduledTime.length === 1) {
                                    input = mmssToSeconds(scheduledTime[0])
                                } else {
                                    input = (mmssToSeconds(scheduledTime[0]) + mmssToSeconds(scheduledTime[1])) / 2
                                }
                                prediction = input;
                            } else {
                                input = 0;
                                missing = true;
                                scheduleMissing = true;
                            }
                            let inputMeethalte = 0;
                            if (track.meethalte) {
                                inputMeethalte = findMeethaltePredictionById(blockId, clusterPrediction.single_prediction_traject!);
                            }


                            return {
                                blockId,
                                predictionBlock: prediction,
                                inputBlock: input,
                                meethalte: track.meethalte,
                                tijdhalte: track.tijdhalte,
                                inputMeethalte: inputMeethalte,
                                missing,
                                scheduleMissing
                            }
                        })
                        if (!clusterPrediction.single_prediction_total || !clusterPrediction.single_prediction_total[0].time) {
                            throw new Error("Missing total prediction for")
                        }
                        const totalChosen = clusterPrediction.single_prediction_total[0].time
                        const calibratedChosenTimes = calibrateChosenSum(totalChosen, chosenTimes, clusterPrediction);
                        const calibratedDistributedChosenTimes = distributeInputProportionally(calibratedChosenTimes);
                        chosenMemory.value.push({ clusterId, dayId: day, timeId: time, seasonId: season, chosenTimes: calibratedDistributedChosenTimes, totalChosen: totalChosen })
                    }
                })
            })
        };


        const processPredictionsFromStartArr = (
            predictionData: Block[],
        ): Block[] => {

            let lastId = predictionData[predictionData.length - 1].id
            let prefix = lastId?.split('_').slice(0, -1).join('_');

            // Filter predictions from start_arr that match the total IDs
            const totalPredictions = predictionData.filter(pred => {
                if (!pred.id) {
                    throw new Error("We are missing a prediction id")
                }
                const predIdWithoutPercentile = pred.id.split('_').slice(0, -1).join('_'); // Remove the last part (percentile)
                // Match the base ID (excluding percentile) with the last element of total ids
                return predIdWithoutPercentile === prefix;
            });
            return totalPredictions
        };


        const getClusterPrediction = (season: string, day: string, time: string, stops: Route[], percentage: string): AveragedPrediction | undefined => {
            const prediction = combinedPredictions.value.find(
                (entry) => {
                    return entry.dayCluster == day && entry.timeCluster == time && entry.seasonCluster === season
                }
            );
            if (!linePlanningSettings.value.stop_combinations) {
                throw new Error("Missing stop combinations");
            }

            if (prediction) {
                const single_prediction_dep = prediction.single_prediction_dep;
                const single_prediction_arr = prediction.single_prediction_arr;
                const single_prediction_total = prediction.single_prediction_total;
                const single_prediction_halteer = prediction.single_prediction_halteer;
                const single_prediction_traject = prediction.single_prediction_traject;
                const single_prediction_arr_start = prediction.single_prediction_arr_start;

                if (!single_prediction_dep || !single_prediction_arr || !single_prediction_total || !single_prediction_halteer || !single_prediction_traject || !single_prediction_arr_start) {
                    throw new Error("No single prediction available");
                }

                // Process all types of predictions
                const depPredictions = processPredictions('single_prediction_dep', single_prediction_dep, stops, percentage, true);
                const arrPredictions = processPredictions('single_prediction_arr', single_prediction_arr, stops, percentage, true);
                const totalPredictions = processPredictions('single_prediction_total', processPredictionsFromStartArr(single_prediction_arr_start), stops, percentage, true);
                const halteerPredictions = processPredictions('single_prediction_halteer', single_prediction_halteer, stops, percentage, true);
                const trajectPredictions = processPredictions('single_prediction_traject', single_prediction_traject, stops, percentage, false);
                // Store all results in the current prediction
                return {
                    ...prediction,
                    single_prediction_dep: depPredictions.filteredPredictions,
                    single_predictions_dep: depPredictions.allPredictions,
                    single_prediction_arr: arrPredictions.filteredPredictions,
                    single_predictions_arr: arrPredictions.allPredictions,
                    single_prediction_total: totalPredictions.filteredPredictions,
                    single_predictions_total: totalPredictions.allPredictions,
                    single_prediction_halteer: halteerPredictions.filteredPredictions,
                    single_predictions_halteer: halteerPredictions.allPredictions,
                    single_prediction_traject: trajectPredictions.filteredPredictions,
                    single_predictions_traject: trajectPredictions.allPredictions
                };
            }
            return undefined

        };

        const getSchedule = (id: string, schedule: ClusteredSchedule[], season: string, day: string, time: string): string => {
            const idParts = id.split('_')
            const stopcode = idParts[3] + '_' + idParts[4]
            const activeSchedule = schedule.find((item) => {
                return item.day_cluster === day && item.time_cluster === time && item.season_cluster === season;
            })

            if (activeSchedule) {
                const foundItem = activeSchedule.time_range?.find((item) => item[stopcode] !== undefined);

                if (foundItem) {
                    const minmax = foundItem[stopcode].split('-')
                    if (foundItem[stopcode] === '0-0') {
                        return 'No schedule'
                    } else if (minmax[0] === minmax[1]) {
                        return `${secondsToMMSS(parseInt(minmax[0]))}`
                    } else {
                        return `${secondsToMMSS(parseInt(minmax[0]))}-${secondsToMMSS(parseInt(minmax[1]))}`;
                    }

                }
                return 'No schedule'; // Handle the case where nothing is found
            }
            return "work in progress"
        }

        const secondsToMMSS = (seconds: number) => {
            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;
            return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        };

        const mmssToSeconds = (mmss: string) => {
            const [mins, secs] = mmss.split(":").map(Number);
            return mins * 60 + secs;
        };

        const findMeethaltePredictionById = (id: string, traject: Block[]): number => {
            let codeMeethalte = id.split('_')[4];
            let trajectPrediction = traject.find(item => {
                let code = item.id?.split('_')[4];
                return code === codeMeethalte
            })?.time
            return trajectPrediction ? trajectPrediction : 0;
        }

        function getDayFromScheduleId(id: string): string {
            return id.split('_')[4];
        }

        function getSeasonFromScheduleId(id: string): string {
            return id.split('_')[5];
        }

        function getJourneyNumberFromId(id: string): string {
            return id.split('_')[3];
        }


        function clusterSchedule(data: LineSchedule[], timeCluster: string, dayCluster: string, seasonCluster: string, journeyCluster: string, orderedRoute: Route[]): ClusteredSchedule[] {
            const grouped: ClusteredSchedule[] = [];
            const journeyClusterArray = journeyCluster.split('_');
            const dayClusterArray = dayCluster.split('_');
            const seasonClusterArray = seasonCluster.split('_');
            const activeStops = getActiveStopsCode(orderedRoute);
            const matchedData = data.filter(d => {
                const journeyNumber = getJourneyNumberFromId(d.id);
                const day = getDayFromScheduleId(d.id);
                const season = getSeasonFromScheduleId(d.id);
                return journeyClusterArray.includes(journeyNumber) && dayClusterArray.includes(day) && seasonClusterArray.includes(season);
            });
            if (matchedData.length) {
                const newId = matchedData.map(d => d.id).join('__');
                const stopTimeRanges: { [id_areacode_combination: string]: string } = {};
                const stopSumRanges: { [id_areacode_combination: string]: string } = {};

                let minScheduleLine: number | null = null;
                let maxScheduleLine: number | null = null;
                const filteredRoute = orderedRoute.filter(item => item.id_areacode_combination.split('_')[0] !== item.id_areacode_combination.split('_')[1])
                const orderMap = new Map();
                filteredRoute.forEach((item, index) => {
                    orderMap.set(item.id_areacode_combination, index);
                });

                matchedData.forEach(lineSchedule => {
                    let sumTripTotal = 0;
                    let tripMinTotal = 0;
                    let tripMaxTotal = 0;

                    // Filter the schedule to only include stops present in orderedRoute
                    lineSchedule.schedule = lineSchedule.schedule.filter(stop => {
                        return orderMap.has(stop.id_areacode_combination);
                    });


                    filteredRoute.forEach((stop, index) => {
                        if (!stop.id_areacode_combination) {
                            throw new Error("NO stop combination");
                        }
                        const userstopcode = stop.id_areacode_combination;
                        const scheduleForStop = lineSchedule.schedule.find(item => item.id_areacode_combination === userstopcode)
                        let seconds = 0;
                        if (scheduleForStop) {
                            seconds = hhmmssToSeconds(scheduleForStop.time_planned as string);
                        }
                        sumTripTotal += seconds;

                        // Time Range Calculation
                        if (!stopTimeRanges[userstopcode]) {
                            stopTimeRanges[userstopcode] = `${seconds}-${seconds}`;
                        } else {
                            const [min, max] = stopTimeRanges[userstopcode].split('-').map(Number);
                            const newMin = Math.min(min, seconds);
                            const newMax = Math.max(max, seconds);
                            stopTimeRanges[userstopcode] = `${newMin}-${newMax}`;
                        }

                        // Sum Range Calculation
                        if (!stopSumRanges[userstopcode]) {
                            stopSumRanges[userstopcode] = `${sumTripTotal}-${sumTripTotal}`;
                        } else {
                            const [min, max] = stopSumRanges[userstopcode].split('-').map(Number);

                            const newMinSum = Math.min(min, sumTripTotal);
                            const newMaxSum = Math.max(max, sumTripTotal);
                            stopSumRanges[userstopcode] = `${newMinSum}-${newMaxSum}`;
                        }

                        tripMinTotal = index === 0 ? seconds : tripMinTotal + seconds;
                        tripMaxTotal = index === 0 ? seconds : tripMaxTotal + seconds;
                        if (activeStops.includes(userstopcode)) {
                            sumTripTotal = 0;
                        }
                    });

                    // Update the overall min/max for the entire schedule
                    if (minScheduleLine === null || tripMinTotal < minScheduleLine) {
                        minScheduleLine = tripMinTotal;
                    }
                    if (maxScheduleLine === null || tripMaxTotal > maxScheduleLine) {
                        maxScheduleLine = tripMaxTotal;
                    }
                });

                const timeRangeArray = Object.entries(stopTimeRanges).map(([userstopcode, range]) => {
                    return { [userstopcode]: range };
                });

                const sumRangeArray = Object.entries(stopSumRanges).map(([userstopcode, sumRange]) => {
                    return { [userstopcode]: sumRange };
                });
                grouped.push({
                    id: newId,
                    time_cluster: timeCluster,
                    day_cluster: dayCluster,
                    journey_cluster: journeyCluster,
                    season_cluster: seasonCluster,
                    total_schedule: `${minScheduleLine}-${maxScheduleLine}`,
                    time_range: timeRangeArray,
                    sum_range: sumRangeArray,
                });
            }

            return grouped;
        }

        const processPredictions = (predictionType: string, predictionData: Block[], stops: Route[], standardPercentage: string, addMissingStops: boolean) => {
            // Reduce the prediction data into a structure keyed by percentile
            const predictionByPercentile = predictionData.reduce((acc: Record<string, Block[]>, pred: Block) => {
                const percentile = (pred.id as string).split('_').pop(); // Get the last part of the ID which is the percentage
                if (!acc[percentile as string]) {
                    acc[percentile as string] = [];
                }
                acc[percentile as string].push(pred);
                return acc;
            }, {});

            // Ensure each prediction contains all stops
            Object.keys(predictionByPercentile).forEach(percentile => {
                const predictions = predictionByPercentile[percentile];

                // Create a map of existing stops in predictions
                const existingStops = new Set(predictions.map(pred => {
                    if (!pred.id) {
                        throw new Error(`Missing id in ${predictionType}`);
                    }
                    const parts = pred.id.split('_');
                    parts.pop();
                    return parts.join('_');
                }));

                // Add missing stops
                if (addMissingStops) {
                    stops.forEach(stop => {
                        if (!existingStops.has(stop.prediction_id as string)) {
                            predictions.push({
                                id: `${stop.prediction_id}_${percentile}`,
                                time: 0,
                                available: false,
                            });
                        }
                    });
                }

                // Sort the predictions to maintain a consistent order (optional)
                predictionByPercentile[percentile] = predictions.sort((a, b) =>
                    stops.findIndex(stop => stop.id === a.stop_id) - stops.findIndex(stop => stop.id === b.stop_id)
                );
            });

            // Return filtered predictions based on the standardPercentage
            const filteredPredictions = predictionByPercentile[standardPercentage] || [];

            return {
                allPredictions: predictionByPercentile,
                filteredPredictions
            };
        };

        const getClusterId = (season: string, day: string, time: string): string => {
            const activeStops = getActiveStopsCode(linePlanningSettings.value.stop_combinations).join('_');
            const timeStops = getTimeStopsCode(linePlanningSettings.value.stop_combinations).join('_');
            const percentielSelector = parseFloat((selectedPercentiel.value / 100).toFixed(2)).toString();
            return percentielSelector + '_' + activeStops + '_' + timeStops + '_' + day + "_" + time + "_" + season;
        };

        function getActiveStopsCode(stops?: Route[]): string[] {
            if (!stops) {
                return [];
            }
            const activeStops: string[] = [];

            for (let i = 0; i < stops.length; i++) {
                const currentStop = stops[i];
                const nextStop = stops[i + 1];

                const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

                if (isTrack) {
                    if (currentStop && nextStop.meethalte) {
                        activeStops.push(currentStop.id_areacode_combination)
                    }
                }
            }
            return activeStops
        }


        function getTimeStopsCode(stops?: Route[]): string[] {
            if (!stops) {
                return [];
            }
            const activeStops: string[] = [];

            for (let i = 0; i < stops.length; i++) {
                const currentStop = stops[i];
                const nextStop = stops[i + 1];

                const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

                if (isTrack) {
                    if (currentStop && nextStop.tijdhalte) {
                        activeStops.push(currentStop.id_areacode_combination)
                    }
                }
            }
            return activeStops
        }

        const getTracksFromStopCombinations = (stops: Route[]): Route[] => {
            const filteredStops: Route[] = [];
            for (let i = 0; i < stops.length; i++) {
                const currentStop = stops[i];
                const nextStop = stops[i + 1];

                const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

                if (isTrack) {
                    if (currentStop && nextStop.meethalte) {
                        currentStop.meethalte = true;
                    }
                    else if (currentStop && nextStop.tijdhalte) {
                        currentStop.tijdhalte = true;
                    }
                    filteredStops.push(currentStop);
                }
            }
            return filteredStops;
        };

        const calibrateChosenSum = (totalsum: number, chosenTimes: Chosen[], clusteredPrediction: AveragedPrediction): Chosen[] => {
            const meethalteTimes = chosenTimes.filter(item => item.meethalte);
            const totalMeethalteTime = meethalteTimes.reduce((sum, item) => {
                if (!item.inputMeethalte) {
                    return 0;
                }
                return sum + item.inputMeethalte;
            }, 0);
            const activeStopsBetweenMeethaltes = getActiveStopsBetweenMeethaltes()
            const predictedStopTime = clusteredPrediction.single_prediction_halteer
                ?.filter(item => activeStopsBetweenMeethaltes.includes(item.id!))
                ?.reduce((sum, item) => sum + (item.time || 0), 0);

            let totalStopTime = 0;
            if (predictedStopTime) {
                totalStopTime = predictedStopTime
            }
            // Step 3: Calculate the difference
            const difference = totalsum - totalMeethalteTime - totalStopTime;

            // Step 4: Find the last meethalteTime where meethalte is true
            const lastMeethalte = meethalteTimes[meethalteTimes.length - 1];

            // Step 5: Subtract the difference from the last meethalteTime
            if (lastMeethalte && lastMeethalte.inputMeethalte) {
                lastMeethalte.inputMeethalte += difference;
            }

            let sum = 0;
            let sumStart = 0;
            let sumMeetStart = 0;
            let stopTime = 0;
            let addStopToNextBlock = false;
            chosenTimes.forEach((item) => {
                sum += item.inputBlock ? item.inputBlock : 0;
                sumStart += item.inputBlock ? item.inputBlock : 0;
                item.inputSum = sum;
                item.inputSumFromStart = sumStart;
                if (addStopToNextBlock) {
                    sumStart += stopTime ? stopTime : 0;
                    item.inputSumFromStart = sumStart;
                    addStopToNextBlock = false;
                }
                if (item.meethalte) {
                    addStopToNextBlock = true
                    sum = 0;
                    sumMeetStart += item.inputMeethalte ? item.inputMeethalte : 0;
                    sumMeetStart += stopTime ? stopTime : 0;
                    item.inputMeetFromStart = sumMeetStart;
                    const blockIdParts = (item.blockId as string).split('_');
                    const fifthElement = blockIdParts[4]; // The 5th element after splitting

                    // Find the matching stop ID in activeStopsBetweenMeethaltes based on the 5th element
                    const matchedStopId = activeStopsBetweenMeethaltes.find(stopId => stopId.split('_')[4] === fifthElement);

                    if (matchedStopId && clusteredPrediction.single_prediction_halteer) {
                        // Use the matchedStopId to find the corresponding stop in single_prediction_halteer
                        const stopPrediction = clusteredPrediction.single_prediction_halteer.find(stop => stop.id === matchedStopId)?.time;
                        stopTime += stopPrediction ? stopPrediction : 0;
                    }
                }
            })

            return chosenTimes
        }

        const distributeInputProportionally = (chosenTimes: Chosen[]): Chosen[] => {
            let lastMeethalteIndex = -1;
            const ranges: [number, number][] = [];
            // Identify ranges between meethaltes
            chosenTimes.forEach((item, index) => {
                if (item.meethalte) {
                    if (lastMeethalteIndex === -1) {
                        ranges.push([0, index + 1]);
                    } else {
                        ranges.push([lastMeethalteIndex + 1, index + 1]);
                    }
                    lastMeethalteIndex = index;
                }
            });

            // Include the remaining items after the last meethalte
            if (lastMeethalteIndex !== chosenTimes.length - 1) {
                ranges.push([lastMeethalteIndex + 1, chosenTimes.length]);
            }
            // Process each range between two meethaltes
            ranges.forEach((range) => {
                const rangeItems = chosenTimes.slice(range[0], range[1]);
                const timingstopsPresent = rangeItems.find(item => item.tijdhalte);
                const finalMeethalte = rangeItems[rangeItems.length - 1]; // Assuming the last item is the meethalte

                if (timingstopsPresent && finalMeethalte && finalMeethalte.inputMeethalte) {
                    // We have a range with timingstops in between which we have to deal with
                    let cumulativeTime = 0;
                    let lastindex = 0;
                    let sumToTimeStop = 0;

                    // Handle each tijdhalte within the range
                    rangeItems.forEach((item, index) => {
                        if (item.inputBlock) {
                            sumToTimeStop += item.inputBlock;
                        }
                        if (item.tijdhalte) {
                            // Calculate time based on the tijdhalte percentage
                            lastindex = index
                            cumulativeTime = sumToTimeStop;
                        }

                        // If it's the last tijdhalte before the final meethalte, we need to ensure the meethalte percentage
                        if (index === rangeItems.length - 1 || (rangeItems[index + 1] && rangeItems[index + 1].meethalte)) {
                            const totalTimeForRange = finalMeethalte.inputMeethalte;
                            if (!totalTimeForRange) {
                                throw new Error("There is no totaltime input for the meethalte")
                            }
                            const remainingTime = totalTimeForRange - cumulativeTime;
                            if (remainingTime > 0) {
                                // Distribute remaining time over the blocks after the last tijdhalte
                                const remainingItems = rangeItems.slice(lastindex + 1, rangeItems.length);
                                const totalBlockTime = remainingItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);

                                remainingItems.forEach((item) => {
                                    if (item.predictionBlock) {
                                        const timeProportion = (item.predictionBlock / totalBlockTime) || 0;
                                        item.inputBlock = item.predictionBlock + (remainingTime - totalBlockTime) * timeProportion;
                                    }
                                });
                            }
                        }
                    });
                } else {

                    // We have a range without any timing stops, we can distribute the time proportionally across all blocks
                    if (finalMeethalte.inputMeethalte === 0) {
                        finalMeethalte.inputMeethalte = 1;
                    }
                    if (!finalMeethalte || !finalMeethalte.inputMeethalte) {
                        throw new Error("Missing final meethalte");
                    }

                    let totalTimeForRange = finalMeethalte.inputMeethalte - rangeItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);
                    if (totalTimeForRange === 0) {
                        totalTimeForRange = 1;
                    }
                    if (!totalTimeForRange) {
                        throw new Error("Missing info for distributing time across range without timing stops");
                    }

                    // Calculate total block time for all items
                    const totalBlockTime = rangeItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);
                    // Distribute the remaining time proportionally across all blocks
                    rangeItems.forEach((item) => {
                        let timeProportion = 0;
                        if (!item.blockId) {
                            throw new Error("Missing item.blockId for distributing time");
                        }
                        if (item.predictionBlock) {
                            timeProportion = (item.predictionBlock / totalBlockTime) || 0;
                        }

                        // Distribute time proportionally, using the available total time for the range
                        item.inputBlock = (item.predictionBlock || 0) + totalTimeForRange * timeProportion;
                    });
                }
            });
            return chosenTimes
        };


        const getActiveStopsBetweenMeethaltes = () => {
            if (!linePlanningSettings.value.stop_combinations) {
                throw new Error("No stop combinations provided");
            }

            let activeStops: Route[] = [];
            let meethalteIndexes: number[] = [];
            const percentielSelector = parseFloat((selectedPercentiel.value / 100).toFixed(2)).toString();
            // Step 1: Identify the indexes of all meethalte stops
            linePlanningSettings.value.stop_combinations.forEach((item, index) => {
                if (item.meethalte && item.id !== undefined && item.type === 'rijtijd') {
                    meethalteIndexes.push(index);
                }
            });

            // Step 2: Push the stop between two meethaltes to activeStopsBetweenMeethaltes if there is more than one meethalte
            if (meethalteIndexes.length > 1) {
                for (let i = 0; i < meethalteIndexes.length - 1; i++) {
                    activeStops.push(linePlanningSettings.value.stop_combinations[meethalteIndexes[i] + 1])
                }
            }

            return activeStops.map(item => item.prediction_id + '_' + percentielSelector)
        };


        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////// Implementation of the chosenTimes initialization for all clusters /////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////// Implementation of the chosenTimes combined setting of values //////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // The goal here is to take the current filled in percentage values from the prediction table.
        // We use these values to calculate the inputs for all of the total meet and tijd haltes.
        // We can set all the times appropriately and use the distributeInputPropotrionally function to scale it over the underlying stops.

        const setPercentageAsStandard = async (season: string, day: string, time: string, totalPercentage: number) => {
            // If there are clusters added since the last time we initialized, we want to add these to our clusters
            await handleChosenTimesInitialization();
            const percentielSelector = parseFloat((selectedPercentiel.value / 100).toFixed(2)).toString();
            const chosenTimes = chosenMemory.value.find(item => item.clusterId === getClusterId(season, day, time))
            if (!chosenTimes) {
                throw new Error("No times were initialized yet")
            }
            // First determine the percentages we want to use.
            const meethaltes = chosenTimes.chosenTimes.filter(item => item.meethalte).map(meethalte => {
                return {
                    id: meethalte.blockId,
                    percentage: meethalte.percentageMeethalte ? meethalte.percentageMeethalte : 80
                }
            })
            const tijdhaltes = chosenTimes.chosenTimes
                .filter(item => item.tijdhalte)
                .map(tijdhalte => {
                    const indexInOriginalArray = chosenTimes.chosenTimes.findIndex(originalItem => originalItem === tijdhalte);
                    return {
                        index: indexInOriginalArray,  // Use the index from the original array
                        percentage: tijdhalte.percentageBlock ? tijdhalte.percentageBlock : 80
                    };
                });

            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("Missing time clusters")
            }
            linePlanningSettings.value.time_clusters.forEach(time_cluster => {
                // Start by computing all the times we need to enter for the same percentage
                // Set these times to the times in memory

                time_cluster.time_cluster.forEach(timeArray => {
                    if (!linePlanningSettings.value.stop_combinations) {
                        throw new Error("Missing stop combinations")
                    }

                    const timeId = timeArray.map(item => item.starttime).join('_');

                    const prediction = getClusterPrediction(time_cluster.season_cluster, time_cluster.day_cluster, timeId, linePlanningSettings.value.stop_combinations, percentielSelector);
                    if (!prediction) {
                        throw new Error("Missing a prediction in setPercentageAsStandard")
                    }

                    const memoryEntry = chosenMemory.value.find(item => item.clusterId === getClusterId(time_cluster.season_cluster, time_cluster.day_cluster, timeId))
                    if (memoryEntry) {
                        meethaltes.forEach(meethalte => {
                            if (!prediction.single_predictions_traject) {
                                throw new Error("Missing traject predictions")
                            }
                            const meethalteTime = getTime(prediction.single_predictions_traject, meethalte.id, meethalte.percentage)
                            const timeToChange = memoryEntry.chosenTimes.find(item => item.blockId === meethalte.id)
                            if (timeToChange) {
                                timeToChange.inputMeethalte = meethalteTime
                            }
                        })
                        tijdhaltes.forEach(tijdhalte => {
                            if (!prediction.single_predictions_dep) {
                                throw new Error("Missing dep predictions")
                            }
                            memoryEntry.chosenTimes = setBlockTimesTimeStop(prediction.single_predictions_dep, memoryEntry.chosenTimes, tijdhalte.index, tijdhalte.percentage)
                        })
                        if (!prediction.single_predictions_total) {
                            throw new Error("Missing total predictions")
                        }
                        memoryEntry.totalChosen = getTotalTimeFromPercentage(prediction.single_predictions_total, totalPercentage)

                        // Second distribute the times over the entries based on the distribution logic

                        // Distribute total over the meethaltes
                        if (!prediction.single_prediction_halteer) {
                            throw new Error("Missign the halteer prediction")
                        }
                        memoryEntry.chosenTimes = distributeTotalTimeOverSums(prediction.single_prediction_halteer, memoryEntry.chosenTimes, memoryEntry.totalChosen)
                        memoryEntry.chosenTimes = distributeInputProportionally(memoryEntry.chosenTimes)
                    }
                })
            })
        }

        const getTime = (prediction: PercentilePredictions, blockId: string, userPercentage: number): number => {
            if (!prediction) {
                throw new Error("There is no aggregatedPrediction");
            }
            let times = getTimesForId(blockId, prediction);
            // Convert user input percentage to a fraction
            const targetPercentile = userPercentage / 100;

            let calculatedTime = 0;
            if (targetPercentile <= times[0].percentile) {
                calculatedTime = times[0].time;
            } else if (targetPercentile >= times[times.length - 1].percentile) {
                calculatedTime = times[times.length - 1].time;
            } else {
                // Perform inverse interpolation between the surrounding percentiles
                for (let i = 0; i < times.length - 1; i++) {
                    const lower = times[i];
                    const upper = times[i + 1];

                    if (targetPercentile >= lower.percentile && targetPercentile <= upper.percentile) {
                        const percentileRange = upper.percentile - lower.percentile;
                        const percentileDelta = targetPercentile - lower.percentile;
                        const timeRange = upper.time - lower.time;
                        calculatedTime = lower.time + (percentileDelta / percentileRange) * timeRange;

                        break;
                    }
                }
            }
            return calculatedTime
        };

        const getTimesForId = (
            id: string,
            percentiles: PercentilePredictions,
        ): { percentile: number; time: number; }[] => {
            // Extract the base ID (everything before the last `_`)

            const idStop = id.split('_')[4];
            const times: { percentile: number; time: number; }[] = [];

            // Iterate over all percentiles to find matching base IDs
            for (const [percentile, timeDataList] of Object.entries(percentiles)) {
                const timeData = timeDataList.find((item) => {
                    if (!item.id) {
                        throw new Error("THere is no id")
                    }
                    return item.id.split('_')[4] === idStop;
                });
                if (timeData && timeData.time) {
                    times.push({
                        percentile: parseFloat(percentile),
                        time: timeData.time
                    });
                }
            }

            // Sort the times by percentile to ensure correct order for interpolation
            times.sort((a, b) => a.percentile - b.percentile);

            return times;
        }


        const getTotalTimeFromPercentage = (predictions: PercentilePredictions, percentage: number): number => {
            let calculatedTime = 0;

            // Get the times for the last active item's ID
            let times = getTimesForIdTotal(predictions);
            if (times && times.length > 0) {
                // Sort times by percentile to ensure they are in the correct order for interpolation
                times.sort((a, b) => a.percentile - b.percentile);
            }
            // Calculate the corresponding time for the given percentage
            const normalizedPercentage = percentage / 100;

            if (normalizedPercentage <= times[0].percentile) {
                calculatedTime = times[0].time;
            } else if (normalizedPercentage >= times[times.length - 1].percentile) {
                calculatedTime = times[times.length - 1].time;
            } else {
                // Perform linear interpolation between the surrounding times
                for (let i = 0; i < times.length - 1; i++) {
                    const lower = times[i];
                    const upper = times[i + 1];

                    if (normalizedPercentage >= lower.percentile && normalizedPercentage <= upper.percentile) {
                        const percentileRange = upper.percentile - lower.percentile;
                        const percentileDelta = normalizedPercentage - lower.percentile;
                        const timeRange = upper.time - lower.time;
                        calculatedTime = lower.time + (percentileDelta / percentileRange) * timeRange;
                        break;
                    }
                }
            }
            return calculatedTime;
        };

        const getTimesForIdTotal = (
            percentiles: PercentilePredictions,
        ): { percentile: number; time: number; }[] => {
            // Extract the base ID (everything before the last `_`)
            const times: { percentile: number; time: number; }[] = [];
            // Iterate over all percentiles to find matching base IDs
            for (const [percentile, timeDataList] of Object.entries(percentiles)) {
                if (timeDataList && timeDataList[0].time) {
                    times.push({
                        percentile: parseFloat(percentile),
                        time: timeDataList[0].time
                    });
                }
            }
            // Sort the times by percentile to ensure correct order for interpolation
            times.sort((a, b) => a.percentile - b.percentile);

            return times;
        };

        const distributeTotalTimeOverSums = (halteerPredictions: Block[], chosenTimes: Chosen[], newTotalTime: number): Chosen[] => {
            if (!chosenTimes || chosenTimes.length === 0) {
                throw new Error("There are no chosenTimes");
            }

            let totalSum = 0;
            chosenTimes.forEach(item => {
                if (item.meethalte && item.inputMeethalte) {
                    totalSum += item.inputMeethalte;
                }
            })
            const activeStopsBetweenMeethaltes = getActiveStopsBetweenMeethaltes();
            let stopTime = 0;
            activeStopsBetweenMeethaltes.forEach(meet => {
                const stopPrediction = halteerPredictions.find(stop => stop.id === meet)?.time;
                stopTime += stopPrediction ? stopPrediction : 0;
            })

            const extraTime = newTotalTime - totalSum - stopTime;
            const lastRangeIndex = chosenTimes.length - 1;

            // Check if the last range exists and has an inputSum
            const lastRangeItem = chosenTimes[lastRangeIndex];
            if (lastRangeItem && lastRangeItem.inputMeethalte !== undefined) {
                lastRangeItem.inputMeethalte += extraTime;
            } else {
                console.error('Could not find a valid last range to add extra time.');
            }
            return chosenTimes
        };


        const setBlockTimesTimeStop = (prediction: PercentilePredictions, chosenTimes: Chosen[], tijdhalteIndex: number, percentage: number) => {
            const previousIndex = findPreviousMeethalteOrTijdhalte(chosenTimes, tijdhalteIndex);
            // Distribute the time adjustment across the blocks in the range
            chosenTimes.slice(previousIndex, tijdhalteIndex + 1).forEach(item => {
                item.inputBlock = getTime(prediction, item.blockId as string, percentage);
            });
            return chosenTimes
        };

        const findPreviousMeethalteOrTijdhalte = (chosenTimes: Chosen[], currentIndex: number) => {
            for (let i = currentIndex - 1; i >= 0; i--) {
                if (chosenTimes[i].meethalte || chosenTimes[i].tijdhalte) {
                    return i + 1;
                }
            }
            return 0; // No previous meethalte or tijdhalte found
        };



        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        //////////////////////////////// Implementation of the chosenTimes combined setting of values //////////////////////////////////
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



        // Functions for handling storing the current state of the chosen times
        const updateChosenTimes = (clusterId: string, chosenTimes: Chosen[], totalChosen: number) => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("there is no active day_cluster");
            }
            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("No active time cluster");
            }
            const dayId = linePlanningSettings.value.day_clusters[activeDayCluster.value].map(day => day.day).join('_');
            const timeCluster = getActiveTimeClusters();
            if (!timeCluster) {
                throw new Error("There is no time cluster known");
            }
            const sortedClusterId = sortClusterIdTimes(clusterId)
            const seasonId = linePlanningSettings.value.season_clusters[activeSeasonCluster.value].map(season => season.season).join('_');
            const timeId = timeCluster[activeTimeCluster.value].map(time => time.starttime).join('_');
            const index = chosenMemory.value.findIndex(obj => obj.clusterId === sortedClusterId);
            if (index !== -1) {
                chosenMemory.value[index] = { clusterId: sortedClusterId, dayId, timeId, seasonId, chosenTimes, totalChosen };
            } else {
                chosenMemory.value.push({ clusterId: sortedClusterId, dayId, timeId, seasonId, chosenTimes, totalChosen });
            }
        }

        // sort the clusterID
        function sortClusterIdTimes(clusterId: string): string {
            // Split the clusterId into parts based on underscores
            let parts = clusterId.split('_');

            // Find the index where the time values start
            const timeStartIndex = parts.findIndex(part => part.match(/^\d{2}:\d{2}:\d{2}$/)); // First occurrence of a time format

            // If no times are found, return the original clusterId
            if (timeStartIndex === -1) {
                return clusterId;
            }

            // Find the index where the time values end (or use the length of the array if no more non-time parts)
            const timeEndIndex = parts.slice(timeStartIndex).findIndex(part => !part.match(/^\d{2}:\d{2}:\d{2}$/));
            const actualTimeEndIndex = (timeEndIndex === -1) ? parts.length : timeStartIndex + timeEndIndex;

            // Extract the time parts, sort them, and then reinsert them back
            const timeParts = parts.slice(timeStartIndex, actualTimeEndIndex).sort((a, b) => a.localeCompare(b));
            parts.splice(timeStartIndex, actualTimeEndIndex - timeStartIndex, ...timeParts);

            // Join the parts back together with underscores
            return parts.join('_');
        }


        const togglePercentielen = () => {
            isPercentielenOpen.value = !isPercentielenOpen.value;
        }

        const selectPercentiel = async (option: number) => {
            selectedPercentiel.value = option;
            isPercentielenOpen.value = false;
            showPredictionTable.value = false;
            tripsDataAvailable.value = false;
            if (!allArrays.value) {
                throw new Error("We cannot change the percentage before we retrieved the arrays")
            }
            tripsData.value = determineTotalTimesFromPrediction(allArrays.value)
            seasonsData.value = determineSeasonsData(tripsData.value)
            daysData.value = determineDaysData(tripsData.value)
            predictionTrigger.value += 1;
            reloadBarChartTrigger.value += 1;
            barChartDayTrigger.value += 1;
            barChartSeasonTrigger.value += 1;
            tripsDataAvailable.value = true;
            showPredictionTable.value = true;

        };

        // Helper functions for transforming data types 
        // function getTimeFromId(id: string): string {
        //     return id.split('_')[6];
        // }

        // function getDayFromId(id: string): string {
        //     return id.split('_')[4];
        // }

        // function getSeasonFromId(id: string): string {
        //     return id.split('_')[5];
        // }


        const formatDate = (datetime: string): string => {
            const listOfDate = datetime.split('-');
            let year = listOfDate[0];
            let month = listOfDate[1];
            let day = listOfDate[2].split('T')[0];
            let time = listOfDate[2].split('T')[1].split(':');
            let hours = time[0];
            let minutes = time[1];
            let seconds = time[2].split('.')[0]
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        function convertToTimeStrings(data?: TimeClusterData[][]): string[][] {
            if (!data) {
                return [];
            }
            return data.map(innerArray => innerArray.map(item => item.starttime || ''));
        }

        const getTimeClusterInfo = (index: number): string => {
            const timeStrings = convertToTimeStrings(getActiveTimeClusters())
            if (timeStrings[index].length === 0) {
                return '';
            }
            if (timeStrings[index].length === 1) {
                const timeParts = timeStrings[index][0].split(':');
                const hours = (parseInt(timeParts[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
                const minutes = timeParts[1];
                return `${hours}:${minutes}`;
            }
            const timePartsStart = timeStrings[index][0].split(':');
            const hoursStart = (parseInt(timePartsStart[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
            const minutesStart = timePartsStart[1];
            const timePartsEnd = timeStrings[index][timeStrings[index].length - 1].split(':');
            const hoursEnd = (parseInt(timePartsEnd[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
            const minutesEnd = timePartsEnd[1];
            return `${hoursStart}:${minutesStart} - ${hoursEnd}:${minutesEnd}`;
        }

        const dayClusterTexts = computed(() => {
            const dayStrings = linePlanningSettings.value.day_clusters

            return dayStrings.map(cluster => {
                const daysMapped = cluster.map(day => dayMapper[day.day?.toLowerCase() || ''] || '');

                if (daysMapped.length === 1) {
                    return daysMapped[0]; // Return the single day
                }

                else {
                    return daysMapped[0] + '-' + daysMapped[daysMapped.length - 1]; // Return the first and last day
                }
            });
        });

        const seasonClusterTexts = computed(() => {
            const seasonStrings = linePlanningSettings.value.season_clusters
            return seasonStrings.map(cluster => {
                const seasonsMapped = cluster.map(season => seasonMapper[season.season?.toLowerCase() || ''] || '');

                if (seasonsMapped.length === 1) {
                    return seasonsMapped[0]; // Return the single season
                }

                else {
                    return seasonsMapped.join(' - '); // Return the first and last season
                }
            });
        });

        const getActiveTimeClusters = (): TimeClusterData[][] | undefined => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("There is no active day cluster, this should not be possible");
            }
            const activeDaysCluster = linePlanningSettings.value.day_clusters[activeDayCluster.value];
            const activeDays = activeDaysCluster.map((dayObj) => dayObj.day).join("_");

            const activeSeasonsCluster = linePlanningSettings.value.season_clusters[activeSeasonCluster.value];
            const activeSeasons = activeSeasonsCluster.map((season) => season.season).join("_");
            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("there are no timeclusters according to typescript");
            }
            const activeTimeCluster = linePlanningSettings.value.time_clusters.find(item => item.day_cluster === activeDays && item.season_cluster === activeSeasons)?.time_cluster
            if (activeTimeCluster) {
                return activeTimeCluster;
            }
            return undefined;
        };


        // Initializing the linePlanningSettings depending on the scenario
        async function initializeStandardStops() {
            linePlanningSettings.value.stop_combinations = routeOrder.value;
            const getUniqueBaseIds = (data: string[]): string[] => {
                // Create a Set to hold unique values
                const uniqueValues = new Set<string>();

                // Iterate through the list
                data.forEach(item => {
                    // Remove the last part after the last underscore
                    const baseId = item.split('_')[4]
                    // Add the baseId to the Set (Set automatically handles uniqueness)
                    uniqueValues.add(baseId);
                });

                // Convert the Set back to an array and return
                return Array.from(uniqueValues);
            };
            let trajectStops: string[] = [];
            if (allArrays.value?.single_prediction_traject?.ids) {
                trajectStops = getUniqueBaseIds(allArrays.value?.single_prediction_traject?.ids)
            }
            // Figure out the predicted stops: 
            let predictedStops: string[] = [];
            if (allArrays.value?.single_prediction_dep?.ids) {
                predictedStops = getUniqueBaseIds(allArrays.value?.single_prediction_dep?.ids)
            }

            linePlanningSettings.value.stop_combinations.forEach((route, index) => {
                if (!linePlanningSettings.value.stop_combinations) {
                    throw new Error("No linePlanningSetti");
                }
                if (!route.id) {
                    throw new Error("There is a route id missing");
                }
                const idParts = route.id.split('_');
                const baseId = idParts.slice(0, -1).join('_');
                route.prediction_id = `${baseId}_${route.id_areacode_combination}`;
                let stopParts = route.prediction_id.split('_')
                let testStop = stopParts[stopParts.length - 1]
                if (index === 0) {
                    route.type = 'starthalte';
                } else if (index === linePlanningSettings.value.stop_combinations.length - 1) {
                    route.type = 'eindhalte';
                } else if (route.id_areacode_combination?.split('_')[0] !== route.id_areacode_combination?.split('_')[1]) {
                    route.type = 'rijtijd'
                } else if (trajectStops.includes(route.prediction_id.split('_')[4])) {
                    route.type = 'meethalte'
                } else if (!predictedStops.includes(testStop as string)) {
                    route.type = 'missing';
                } else {
                    route.type = 'tijdhalte';
                }
                route.active = ['starthalte', 'eindhalte', 'meethalte'].includes(route.type as string);
                route.meethalte = ['starthalte', 'eindhalte', 'meethalte'].includes(route.type as string);
                route.tijdhalte = false;
            });
        }

        function determineDaysData(data: Bus[]): Day[] {
            const dayMap: { [key: string]: { totalTime: number; count: number } } = {};
            let seasons = linePlanningSettings.value.season_clusters[activeSeasonCluster.value].map(item => item.season);
            if (!seasons) {
                seasons = allSeasons.value
            }
            data.forEach(bus => {
                const day = bus.day;
                const season = bus.season;
                if (day && seasons.includes(season)) {
                    if (!dayMap[day]) {
                        dayMap[day] = { totalTime: 0, count: 0 };
                    }
                    if (bus.total_time) {
                        dayMap[day].totalTime += bus.total_time;
                        dayMap[day].count += 1;
                    }
                }
            });
            const result: Day[] = Object.keys(dayMap).map(day => ({
                day,
                average_time: dayMap[day].totalTime / dayMap[day].count,
            }));
            let activeWeekdays: { day: string }[] = [];
            let activeSaturday: { day: string }[] = [];
            let activeSunday: { day: string }[] = [];
            let week = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
            result.sort((a, b) => week.indexOf(a.day as string) - week.indexOf(b.day as string));
            let weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
            let saturday = ['Saturday']
            let sunday = ['Sunday']
            result.forEach(day => {
                if (!day.day) {
                    throw new Error("unknown day!")
                }
                if (weekdays.includes(day.day)) {
                    activeWeekdays.push({ day: day.day })
                } else if (saturday.includes(day.day)) {
                    activeSaturday.push({ day: day.day })
                } else if (sunday.includes(day.day)) {
                    activeSunday.push({ day: day.day })
                }

            })
            linePlanningSettings.value.day_clusters = [];
            if (activeWeekdays.length !== 0) {
                linePlanningSettings.value.day_clusters.push(activeWeekdays)
            }
            if (activeSaturday.length !== 0) {
                linePlanningSettings.value.day_clusters.push(activeSaturday)
            }
            if (activeSunday.length !== 0) {
                linePlanningSettings.value.day_clusters.push(activeSunday)
            }

            return result;
        }

        function determineSeasonsData(data: Bus[]): Season[] {
            const seasonMap: { [key: string]: { totalTime: number; count: number } } = {};
            data.forEach(bus => {
                const season = bus.season;
                if (season) {
                    if (!seasonMap[season]) {
                        seasonMap[season] = { totalTime: 0, count: 0 };
                    }
                    if (bus.total_time) {
                        seasonMap[season].totalTime += bus.total_time;
                        seasonMap[season].count += 1;
                    }
                }
            });
            const result: Day[] = Object.keys(seasonMap).map(season => ({
                season,
                average_time: seasonMap[season].totalTime / seasonMap[season].count,
            }));
            return result;
        }

        function addClusterIdToSettings() {
            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("cannot call cluster id function on empty settings");
            }
            const identifiers: string[] = [];
            linePlanningSettings.value.time_clusters.forEach(timeCluster => {
                const dayString = timeCluster.day_cluster;
                const seasonString = timeCluster.season_cluster;
                timeCluster.time_cluster.forEach(timeArray => {
                    const timeString = timeArray.map(time => time.starttime).join('_');
                    identifiers.push(`${dayString}_${timeString}_${seasonString}`);
                })
            });
            linePlanningSettings.value.cluster_id = identifiers;
        }

        const handleUpdateStops = (routeOrderRef: Route[]) => {
            linePlanningSettings.value.stop_combinations = routeOrderRef;
            predictionTrigger.value += 1;
        }


        const handleReloadBarChartLine = () => {
            reloadBarChartTrigger.value += 1
        }

        const handleUpdateTimeCluster = (timeCluster: TimeClusterData[][]) => {
            if (!linePlanningSettings.value.day_clusters) {
                throw new Error("Undefined day_clusters");
            }
            const activeDaysCluster = linePlanningSettings.value.day_clusters?.[activeDayCluster.value];
            const activeSeasonsCluster = linePlanningSettings.value.season_clusters?.[activeSeasonCluster.value];
            const activeDays = activeDaysCluster
                ? activeDaysCluster.map(dayObj => dayObj.day).join('_')
                : '';
            const activeSeasons = activeSeasonsCluster ? activeSeasonsCluster.map(seasonObj => seasonObj.season).join('_') : '';
            if (!linePlanningSettings.value.time_clusters) {
                linePlanningSettings.value.time_clusters = [];
            }
            const existingIndex = linePlanningSettings.value.time_clusters.findIndex(
                (cluster) => cluster.day_cluster === activeDays && cluster.season_cluster === activeSeasons
            );
            if (existingIndex !== -1) {
                linePlanningSettings.value.time_clusters[existingIndex].time_cluster = timeCluster;
            } else {
                linePlanningSettings.value.time_clusters.push({
                    day_cluster: activeDays,
                    time_cluster: timeCluster,
                    season_cluster: activeSeasons
                });
            }

            // const activeSeasonClusters = linePlanningSettings.value.season_clusters.map(cluster => cluster.map(item => item.season).join('_'));
            // const activeDayClusters = linePlanningSettings.value.day_clusters.map(cluster => cluster.map(item => item.day).join('_'));
            // linePlanningSettings.value.time_clusters = linePlanningSettings.value.time_clusters.filter(cluster => {
            //     return activeSeasonClusters.includes(cluster.season_cluster) && activeDayClusters.includes(cluster.day_cluster)
            // });
            activeTimeCluster.value = 0;
            addClusterIdToSettings();

            // Check if a reload of the predictionTable is actually necessary:
            const clusterIds = combinedPredictions.value.map(item => getClusterId(item.seasonCluster as string, item.dayCluster as string, item.timeCluster as string));
            let reload = false;
            linePlanningSettings.value.time_clusters.forEach(cluster => {
                cluster.time_cluster.forEach(timeArray => {
                    const searchedId = getClusterId(cluster.season_cluster, cluster.day_cluster, timeArray.map(item => item.starttime).join('_'));
                    if (!clusterIds.includes(searchedId)) {
                        reload = true
                    }
                })
            })
            if (reload) {
                reloadPredictionTable();
            }
            predictionTrigger.value += 1;
        };


        // function groupDataByTimeDaySeason(data: PREDICTION[]): Record<string, PREDICTION[]> {
        //     const groupedData: Record<string, PREDICTION[]> = {};

        //     data.forEach(d => {
        //         const time = getTimeFromId(d.id);
        //         const day = getDayFromId(d.id);
        //         const season = getSeasonFromId(d.id);

        //         const key = `${time}_${day}_${season}`;
        //         if (!groupedData[key]) {
        //             groupedData[key] = [];
        //         }
        //         groupedData[key].push(d);
        //     });

        //     return groupedData;
        // }


        // function clusterData(data: PREDICTION[]): AveragedPrediction[] {
        //     const grouped: AveragedPrediction[] = [];
        //     const groupedData = groupDataByTimeDaySeason(data);

        //     if (!linePlanningSettings.value.time_clusters) {
        //         throw new Error("Undefined time clusters");
        //     }

        //     linePlanningSettings.value.time_clusters.forEach((timeCluster) => {
        //         const dayCluster = timeCluster.day_cluster.split('_');
        //         const seasonCluster = timeCluster.season_cluster.split('_');

        //         timeCluster.time_cluster.forEach(timeArray => {
        //             const times = timeArray.map(time => time.starttime);
        //             const sortedTimes = times.sort((a, b) => a.localeCompare(b));
        //             const timeClusterKey = sortedTimes.join('_');

        //             // Check if this cluster already exists in combinedPredictions
        //             const clusterExists = combinedPredictions.value.some(prediction =>
        //                 prediction.timeCluster === timeClusterKey &&
        //                 prediction.dayCluster === timeCluster.day_cluster &&
        //                 prediction.seasonCluster === timeCluster.season_cluster
        //             );

        //             if (!clusterExists) {
        //                 const matchedData: PREDICTION[] = [];
        //                 times.forEach(time => {
        //                     dayCluster.forEach(day => {
        //                         seasonCluster.forEach(season => {
        //                             const key = `${time}_${day}_${season}`;
        //                             if (groupedData[key]) {
        //                                 matchedData.push(...groupedData[key]);
        //                             }
        //                         });
        //                     });
        //                 });

        //                 if (matchedData.length) {
        //                     const newPredictionsDep = matchedData.flatMap(d => d.single_prediction_dep ?? []);
        //                     const newPredictionsArr = matchedData.flatMap(d => d.single_prediction_arr ?? []);
        //                     const newPredictionsDepStart = matchedData.flatMap(d => d.single_prediction_dep_start ?? []);
        //                     const newPredictionsArrStart = matchedData.flatMap(d => d.single_prediction_arr_start ?? []);
        //                     const newPredictionsTotal = matchedData.flatMap(d => d.single_prediction_total ?? []);
        //                     const newPredictionsHalteer = matchedData.flatMap(d => d.single_prediction_halteer ?? []);
        //                     const newPredictionsTraject = matchedData.flatMap(d => d.single_prediction_traject ?? []);

        //                     grouped.push({
        //                         id: timeClusterKey + '_' + timeCluster.day_cluster + '_' + timeCluster.season_cluster,
        //                         timeCluster: timeClusterKey,
        //                         dayCluster: timeCluster.day_cluster,
        //                         seasonCluster: timeCluster.season_cluster,
        //                         single_prediction_dep: newPredictionsDep as Block[],
        //                         single_prediction_arr: newPredictionsArr as Block[],
        //                         single_prediction_dep_start: newPredictionsDepStart as Block[],
        //                         single_prediction_arr_start: newPredictionsArrStart as Block[],
        //                         single_prediction_total: newPredictionsTotal as Block[],
        //                         single_prediction_halteer: newPredictionsHalteer as Block[],
        //                         single_prediction_traject: newPredictionsTraject as Block[],
        //                     });
        //                 }
        //             }
        //         });
        //     });

        //     return grouped;
        // }


        function groupDataByTimeDaySeasonArray(
            data: ArrayPredictions,
            linePlanningSettings: LinePlanningSettings
        ): Record<string, number[]> {
            // Loop over time clusters directly from linePlanningSettings
            if (!linePlanningSettings.time_clusters) {
                throw new Error("Missing time clusters")
            }

            const groupedIndexes: Record<string, number[]> = {};

            // Loop over time clusters directly from linePlanningSettings
            linePlanningSettings.time_clusters.forEach((timeCluster) => {
                const dayCluster = timeCluster.day_cluster.split('_');
                const seasonCluster = timeCluster.season_cluster.split('_');

                timeCluster.time_cluster.forEach((timeArray) => {
                    const times = timeArray.map(time => time.starttime);
                    const timeClusterKey = times.join('_');

                    // Loop over the starttimes to match the structure like "EBS_3033_1_1001_Wednesday_Autumn_06:14:00"
                    data.starttimes.forEach((starttime, index) => {
                        const starttimeparts = starttime.split('_');
                        const day = starttimeparts[4]; // Extract the day part from the starttime
                        const season = starttimeparts[5]; // Extract the season part from the starttime
                        const time = starttimeparts[6]; // Extract the time part

                        if (times.includes(time) && dayCluster.includes(day) && seasonCluster.includes(season)) {
                            const key = `${timeCluster.season_cluster}_${timeCluster.day_cluster}_${timeClusterKey}`;
                            if (!groupedIndexes[key]) {
                                groupedIndexes[key] = [];
                            }
                            groupedIndexes[key].push(index); // Save the index for matching starttimes
                        }
                    });
                });
            });

            return groupedIndexes;
        }


        let wasm: WasmExports | null = null;  // Typed WebAssembly exports

        async function loadWasm() {
            const wasmModule = await fetch('/computations/average.wasm')
                .then(response => response.arrayBuffer())
                .then(bytes => WebAssembly.instantiate(bytes, {}));

            wasm = wasmModule.instance.exports as unknown as WasmExports;
            console.warn(wasm)
            // Verify that the correct functions are exported
        }


        // const average = (timesArray: Float32Array[], indexes: Uint32Array[]): Float32Array => {
        //     const numRows = timesArray[0].length;  // Assuming all rows are the same length
        //     const numIndexes = indexes.length;
        //     const result = new Float32Array(numRows);

        //     if (!wasm) {
        //         throw new Error("WebAssembly module is not loaded");
        //     }

        //     // Flatten the 2D array for WebAssembly
        //     const timesArrayFlat = new Float32Array(numRows * numIndexes);
        //     for (let i = 0; i < numIndexes; i++) {
        //         timesArrayFlat.set(timesArray[indexes[i]], i * numRows);
        //     }

        //     // Check WebAssembly memory size before copying data
        //     const wasmMemory = new Float32Array(wasm['a'].buffer);  // Access the WebAssembly memory buffer

        //     // Check memory size and ensure it's large enough
        //     const memorySize = wasm['a'].buffer.byteLength;
        //     const requiredMemorySize = (timesArrayFlat.length * Float32Array.BYTES_PER_ELEMENT) +
        //         (indexes.length * Uint32Array.BYTES_PER_ELEMENT) +
        //         (result.length * Float32Array.BYTES_PER_ELEMENT);

        //     if (requiredMemorySize > memorySize) {
        //         console.error('Insufficient memory in WebAssembly to handle the data.');
        //     }

        //     // Memory looks sufficient, proceed to copy data
        //     wasmMemory.set(timesArrayFlat, 0);  // Simplify memory handling, place at the start
        //     wasmMemory.set(new Uint32Array(indexes), timesArrayFlat.length);  // Set indexes after timesArrayFlat

        //     // Call the WebAssembly average function with simplified memory access
        //     wasm['b'](0, timesArrayFlat.length, numRows, numIndexes, timesArrayFlat.length);  // Simplified call

        //     // Copy the result back from WebAssembly memory to JavaScript
        //     const finalResult = new Float32Array(wasm['a'].buffer, 0, numRows);

        //     return finalResult;
        // };

        function average(timesArray: Float32Array[], indexes: Uint32Array): Float32Array {
            const numRows = timesArray[0].length;  // Assuming all rows have the same length
            const result = new Float32Array(numRows);

            // Loop over each element in the rows (i.e., the columns)
            for (let i = 0; i < numRows; i++) {
                let sum = 0;

                // Sum the values at the given indexes
                for (let j = 0; j < indexes.length; j++) {
                    const rowIndex = indexes[j];
                    sum += timesArray[rowIndex][i];
                }

                // Calculate the average
                result[i] = sum / indexes.length;
            }

            return result;
        }


        // function averageOverRowsFloat32(data: Float32Array[]): Float32Array {
        //     // Ensure the data is not empty
        //     if (!data.length) {
        //         throw new Error("The data array is empty.");
        //     }

        //     // Initialize a Float32Array to hold the sums of each column
        //     const numColumns = data[0].length;
        //     const columnSums = new Float32Array(numColumns);

        //     // Iterate over each row (which is a Float32Array)
        //     data.forEach(row => {
        //         // Add each value to the corresponding column sum
        //         row.forEach((value, colIndex) => {
        //             columnSums[colIndex] += value;
        //         });
        //     });

        //     // Calculate the average by dividing each column sum by the number of rows
        //     const numRows = data.length;
        //     const columnAverages = columnSums.map(sum => sum / numRows);

        //     return new Float32Array(columnAverages);
        // }

        function clusterArray(data: ArrayPredictions): AveragedPrediction[] {

            // Call this once before running the clusterArray function

            const grouped: AveragedPrediction[] = [];
            console.time("groupIndexes")
            const groupedIndexes = groupDataByTimeDaySeasonArray(data, linePlanningSettings.value);
            console.timeEnd("groupIndexes")

            if (!linePlanningSettings.value.time_clusters) {
                throw new Error("Undefined time clusters");
            }

            linePlanningSettings.value.time_clusters.forEach((timeCluster) => {

                timeCluster.time_cluster.forEach(timeArray => {
                    const times = timeArray.map(time => time.starttime);
                    const timeClusterKey = times.join('_');
                    const groupKey = `${timeCluster.season_cluster}_${timeCluster.day_cluster}_${timeClusterKey}`;

                    // Check if this cluster already exists in combinedArrays
                    const clusterExists = combinedPredictions.value.some(prediction =>
                        prediction.timeCluster === timeClusterKey &&
                        prediction.dayCluster === timeCluster.day_cluster &&
                        prediction.seasonCluster === timeCluster.season_cluster
                    );

                    if (!clusterExists) {
                        const indexes = new Uint32Array(groupedIndexes[groupKey]);
                        if (!indexes) return; // Skip if no indexes found

                        if (
                            !data.single_prediction_arr ||
                            !data.single_prediction_dep ||
                            !data.single_prediction_dep_start ||
                            !data.single_prediction_arr_start ||
                            !data.single_prediction_total ||
                            !data.single_prediction_halteer ||
                            !data.single_prediction_traject
                        ) {
                            throw new Error("Missing prediction data")
                        }
                        console.time("combineIdsAndTimeTotal")
                        // THIS SEEMS TO BE CORRECT STILL!
                        // Calculate averages for each prediction type
                        const newPredictionsArr = combineIdsAndTimes(data.single_prediction_arr.ids, average(data.single_prediction_arr.times, indexes));
                        const newPredictionsDep = combineIdsAndTimes(data.single_prediction_dep.ids, average(data.single_prediction_dep.times, indexes));
                        const newPredictionsDepStart = combineIdsAndTimes(data.single_prediction_dep_start.ids, average(data.single_prediction_dep_start.times, indexes));
                        const newPredictionsArrStart = combineIdsAndTimes(data.single_prediction_arr_start.ids, average(data.single_prediction_arr_start.times, indexes));
                        const newPredictionsTotal = combineIdsAndTimes(data.single_prediction_total.ids, average(data.single_prediction_total.times, indexes));
                        const newPredictionsHalteer = combineIdsAndTimes(data.single_prediction_halteer.ids, average(data.single_prediction_halteer.times, indexes));
                        const newPredictionsTraject = combineIdsAndTimes(data.single_prediction_traject.ids, average(data.single_prediction_traject.times, indexes));
                        console.timeEnd("combineIdsAndTimeTotal")
                        grouped.push({
                            id: `${timeClusterKey}_${timeCluster.day_cluster}_${timeCluster.season_cluster}`,
                            timeCluster: timeClusterKey,
                            dayCluster: timeCluster.day_cluster,
                            seasonCluster: timeCluster.season_cluster,
                            single_prediction_arr: newPredictionsArr as Block[],
                            single_prediction_dep: newPredictionsDep as Block[],
                            single_prediction_dep_start: newPredictionsDepStart as Block[],
                            single_prediction_arr_start: newPredictionsArrStart as Block[],
                            single_prediction_total: newPredictionsTotal as Block[],
                            single_prediction_halteer: newPredictionsHalteer as Block[],
                            single_prediction_traject: newPredictionsTraject as Block[]
                        });
                    }
                });
            });

            return grouped;
        }

        function combineIdsAndTimes(ids: string[], times: Float32Array): { id: string, time: number }[] {
            const length = ids.length;
            if (ids.length !== times.length) {
                throw new Error("Ids and times not the same length")
            }
            const result: { id: string, time: number }[] = new Array(length);

            for (let i = 0; i < length; i++) {
                result[i] = { id: ids[i], time: times[i] };
            }
            return result;
        }


        const runClusterAndAverage = async (): Promise<void> => {
            if (!isLoading.value) {
                try {
                    if (!linePlanningSettings.value.time_clusters) {
                        throw new Error("Missing time_cluster data");
                    }

                    console.time("initTime");
                    await initiateTimeClusters();
                    console.timeEnd("initTime");

                    // Prediction data

                    if (!allArrays.value) {
                        throw new Error("Missing arrays")
                    }
                    console.time("array")
                    const clusteredArray = clusterArray(allArrays.value)
                    console.timeEnd("array")

                    // Append new predictions to combinedPredictions
                    combinedPredictions.value = [
                        ...combinedPredictions.value,
                        ...clusteredArray
                    ];
                } catch (error) {
                    console.error("Error during clustering and averaging:", error);
                }
            }
        };

        // function averageValuesPrediction(predictions: Block[]): Block[] {
        //     const grouped: Record<string, Block[]> = {};

        //     // Group predictions by id
        //     predictions.forEach(prediction => {
        //         if (prediction.id) {
        //             if (!grouped[prediction.id]) {
        //                 grouped[prediction.id] = [];
        //             }
        //             grouped[prediction.id].push(prediction);
        //         }
        //     });

        //     return Object.keys(grouped).map(id => {
        //         const blocks = grouped[id];
        //         const keys = Object.keys(blocks[0]) as (keyof Block)[];

        //         // Initialize an accumulator for each key
        //         const sumAccumulator: Partial<Block> = { id: blocks[0].id };

        //         // Initialize a count for each key
        //         const blockCount = blocks.length;

        //         // Accumulate values for each key in one pass
        //         keys.forEach(key => {
        //             if (key !== 'id') {
        //                 let sum = 0;
        //                 blocks.forEach(block => {
        //                     const value = block[key];
        //                     sum += value !== null && !isNaN(value as number) ? (value as number) : 0;
        //                 });
        //                 (sumAccumulator as any)[key] = sum / blockCount;
        //             }
        //         });

        //         return sumAccumulator as Block;
        //     });
        // }


        // function applyAverageToPredictions(predictionDicts: AveragedPrediction[]): AveragedPrediction[] {
        //     return predictionDicts.map(predictionDict => ({
        //         id: predictionDict.id,
        //         timeCluster: predictionDict.timeCluster,
        //         dayCluster: predictionDict.dayCluster,
        //         seasonCluster: predictionDict.seasonCluster,
        //         single_prediction_dep: predictionDict.single_prediction_dep ? averageValuesPrediction(predictionDict.single_prediction_dep) : [],
        //         single_prediction_arr: predictionDict.single_prediction_arr ? averageValuesPrediction(predictionDict.single_prediction_arr) : [],
        //         single_prediction_dep_start: predictionDict.single_prediction_dep_start ? averageValuesPrediction(predictionDict.single_prediction_dep_start) : [],
        //         single_prediction_arr_start: predictionDict.single_prediction_arr_start ? averageValuesPrediction(predictionDict.single_prediction_arr_start) : [],
        //         single_prediction_total: predictionDict.single_prediction_total ? averageValuesPrediction(predictionDict.single_prediction_total) : [],
        //         single_prediction_halteer: predictionDict.single_prediction_halteer ? averageValuesPrediction(predictionDict.single_prediction_halteer) : [],
        //         single_prediction_traject: predictionDict.single_prediction_traject ? averageValuesPrediction(predictionDict.single_prediction_traject) : [],
        //     }));
        // }

        // Code for handling some of the UI buttons and animations
        const fullReset = () => {
            try {
                saveToServer();
                chosenMemory.value = [];
                predictionTrigger.value += 1;
                const baseMessage = 'De gekozen tijden zijn gereset voor lijn: ';
                resetMessage.value = baseMessage.concat(props.lineId);
                messageType.value = 'good';
                setTimeout(() => {
                    resetMessage.value = '';
                }, 3000);
            } catch (err) {
                console.error("Error message: ", err);
                messageType.value = 'bad';
                const baseMessage = "De lijnen zijn niet gereset";
                resetMessage.value = baseMessage.concat(err as string);
            }
        }

        const handleRetrainDropdown = () => {
            expandRetrainButton.value = !expandRetrainButton.value
        }

        const handleModelDropdown = () => {
            expandModelButton.value = !expandModelButton.value
        }

        // Toggle logic for each dropdown
        const toggleCollapseChooseStops = () => {
            collapseChooseStops.value = !collapseChooseStops.value;
            if (!collapseChooseStops.value) {
                // Close other dropdowns if 'Choose Stops' is open
                collapseChooseSeasons.value = true;
                collapseChooseDays.value = true;
            }
        };

        const toggleCollapseChooseSeasons = () => {
            collapseChooseSeasons.value = !collapseChooseSeasons.value;
            // Close 'Choose Stops' if 'Choose Seasons' is opened
            if (!collapseChooseSeasons.value) collapseChooseStops.value = true;
        };

        const toggleCollapseChooseDays = () => {
            collapseChooseDays.value = !collapseChooseDays.value;
            // Close 'Choose Stops' if 'Choose Days' is opened
            if (!collapseChooseDays.value) collapseChooseStops.value = true;
        };


        const messageClass = computed(() => {
            if (route.params.messagetype === 'bad') {
                return 'bg-red-200';
            }
            if (route.params.messagetype === 'good') {
                return 'bg-green-200';
            } else {
                return 'bg-green-200';
            }
        });

        const handleDayClusterClick = async (index: number) => {
            activeDayCluster.value = index;
            activeTimeCluster.value = 0;
            predictionTrigger.value += 1;
        }

        const timeClusterTrigger = ref<number>(0);

        const handleTimeClusterClick = (index: number) => {
            activeTimeCluster.value = index;
            timeClusterTrigger.value += 1;
        }

        const timeClusterAvailable = ref<boolean>(false)
        // write some code to check that the timecluster is actually available, which is set through linePlanningSettings
        const handleBarchartFinished = async () => {
            timeClusterAvailable.value = true;
            if (!isLoading.value && dayClustersAvailable.value && displayTool.value) {
                await runClusterAndAverage();
                predictionTrigger.value += 1
            }
        }

        const dayClustersAvailable = ref<boolean>(true)

        const handleDaychartFinished = () => {
            dayClustersAvailable.value = true;
            if (!isLoading.value && timeClusterAvailable.value && displayTool.value) {

                showPredictionTable.value = true;
            }
        }

        const reloadPredictionTable = async () => {
            await runClusterAndAverage();
            predictionTrigger.value += 1;
            showPredictionTable.value = true;
        };

        const hidePredictionTable = () => {
            showPredictionTable.value = false;
        };

        const handleUpdateClusters = async () => {
            hidePredictionTable();
            await Promise.all([handleReloadBarChartLine(), addClusterIdToSettings(), reloadPredictionTable()]);
        };

        const seasonClustersAvailable = ref<boolean>(false)
        // Probably have to edit this a bit to enter in the season cluster
        // TODO: fix this functionality
        const handleSeasonchartFinished = () => {
            seasonClustersAvailable.value = true;
        }

        const handleUpdateDays = async (dayCluster: DayData[][]) => {
            linePlanningSettings.value.day_clusters = dayCluster;
            await initiateTimeClusters();
            hidePredictionTable();
            if (!loadedTimeClustersFromDb.value) {
                timeClusterAvailable.value = false;
                activeDayCluster.value = 0;
                await handleUpdateClusters();
            }
            loadedTimeClustersFromDb.value = false;
        };

        const handleUpdateSeasons = async (seasonCluster: SeasonData[][]) => {
            linePlanningSettings.value.season_clusters = seasonCluster;
            await initiateTimeClusters();
            hidePredictionTable();
            if (!loadedTimeClustersFromDb.value) {
                timeClusterAvailable.value = false;
                daysData.value = determineDaysData(tripsData.value)
                activeSeasonCluster.value = 0;
                await handleUpdateClusters();
            }
            barChartDayTrigger.value += 1;
            predictionTrigger.value += 1
            reloadBarChartTrigger.value += 1
            loadedTimeClustersFromDb.value = false;
        };

        watch(
            () => activeSeasonCluster.value,
            async () => {
                daysData.value = determineDaysData(tripsData.value)
                predictionTrigger.value += 1
                barChartDayTrigger.value += 1
                reloadBarChartTrigger.value += 1
            }, { deep: true, immediate: true }
        );

        // functions om naar de HomePage terug te routen
        const router = useRouter();

        const returnToHome = () => {
            router.push({ name: 'HomePage' })
        }

        return {
            message,
            returnToHome,
            messageClass,
            fullReset,
            resetMessage,
            tripsData,
            fetchRoute,
            routeOrder,
            toggleCollapseChooseStops,
            collapseChooseStops,
            handleUpdateStops,
            handleUpdateTimeCluster,
            linePlanningSettings,
            toggleCollapseChooseDays,
            collapseChooseDays,
            daysData,
            handleUpdateDays,
            dayClusterTexts,
            handleDayClusterClick,
            activeDayCluster,
            handleTimeClusterClick,
            activeTimeCluster,
            combinedPredictions,
            showPredictionTable,
            getTimeClusterInfo,
            isLoading,
            userNameLocalOrProps,
            getActiveTimeClusters,
            lineNameLocalOrProps,
            journeyPatternCodeLocalOrProps,
            linePublicNumberLocalOrProps,
            updateChosenTimes,
            chosenMemory,
            saveToServer,
            handleLoadButton,
            loadDropdown,
            sessionList,
            selectSession,
            saveMessage,
            reloadBarChartTrigger,
            predictionTrigger,
            handleBarchartFinished,
            handleDaychartFinished,
            timeClusterTrigger,
            togglePercentielen,
            selectPercentiel,
            selectedPercentiel,
            isPercentielenOpen,
            percentielen,
            tripsDataAvailable,
            barChartDayTrigger,
            lineSchedule,
            allPredictions,
            lineScheduleTotals,
            expandRetrainButton,
            beginAndEndStop,
            trainperiod,
            handleRetrainDropdown,
            handleRetrain,
            isModelRunning,
            retrainTimes,
            toggleCollapseChooseSeasons,
            collapseChooseSeasons,
            seasonsData,
            handleUpdateSeasons,
            barChartSeasonTrigger,
            handleSeasonchartFinished,
            seasonClusterTexts,
            activeSeasonCluster,
            exportToCSV,
            setPercentageAsStandard,
            displayTool,
            handleModelDropdown,
            expandModelButton,
            predictionInformation,
            modelInformation,
            toggleModel,
            modelId,
            generatePDFWithMultipleCharts,
            modelMinMax
        }
    },
}) 
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
    transition: max-height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to

/* .slide-leave-active in <2.1.8 */
    {
    max-height: 0;
    opacity: 0;
}

.slide-enter-to,
.slide-leave

/* for entering and leaving active states */
    {
    max-height: 1000px;
    /* Set this to a maximum height that covers the content size */
    opacity: 1;
}


.block-route-cluster {
    height: 100%;
    /* Ensure the parent takes full height */
    overflow: hidden;
    /* Prevent scrolling on the parent */
    display: flex;
    flex-direction: column;
}

.scrollable-content {
    flex: 1 1 auto;
    /* Allow the child to grow and shrink */
    overflow-y: auto;
    /* Enable vertical scrolling on the child */
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
}

.active-day-cluster {
    @apply bg-white
}

.active-time-cluster {
    @apply bg-white
}

.scrollbar-none::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-none {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}
</style>