import { createApp, h } from 'vue';
import App from './App.vue';
import './index.css'; // Needed for Tailwind
import router from './router';

console.log(process.env.VUE_APP_BACKEND_URL);

const app = createApp(App);

// Set up global error handler
app.config.errorHandler = (err, vm, info) => {
  console.error(`Error: ${(err as string)}\nInfo: ${info}`);
  // Swap the main app with ErrorPage on an error
  router.push({ name: 'ErrorPage' });
};

app.use(router).mount('#app');
