import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex-grow h-96 overflow-auto" }
const _hoisted_2 = { class: "block-route-cluster flex flex-col flex-grow overflow-hidden" }
const _hoisted_3 = { class: "relative flex flex-col items-start overflow-y-auto overflow-x-hidden whitespace-normal h-auto pl-1 pr-2 mb-0" }
const _hoisted_4 = { class: "relative flex flex-col items-start w-full pl-1" }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center hover:bg-gray-200 w-full"
}
const _hoisted_6 = { class: "checkbox-wrapper h-auto flex items-center my-1 w-full" }
const _hoisted_7 = ["onUpdate:modelValue", "disabled", "onChange"]
const _hoisted_8 = {
  key: 2,
  class: "text-sm ml-2 w-full text-red-400"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routeOrderRef, (block, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "block-container h-auto flex items-center w-full"
            }, [
              (block.type !== 'rijtijd')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("label", _hoisted_6, [
                      (block.type !== 'rijtijd' && block.type !== 'missing')
                        ? _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 0,
                            "onUpdate:modelValue": ($event: any) => ((block.tijdhalte) = $event),
                            disabled: index === 0 || index === _ctx.routeOrderRef.length - 1 || block.type === 'meethalte',
                            onChange: ($event: any) => (_ctx.onTijdhalteChange(block)),
                            type: "checkbox",
                            class: "mr-2 transform scale-150 cursor-pointer"
                          }, null, 40, _hoisted_7)), [
                            [_vModelCheckbox, block.tijdhalte]
                          ])
                        : _createCommentVNode("", true),
                      (block.id_userstopname_combination && block.type !== 'missing')
                        ? (_openBlock(), _createElementBlock("span", {
                            key: 1,
                            class: _normalizeClass([_ctx.lineClasses(block), "text-sm ml-2 w-full"])
                          }, _toDisplayString(block.id_userstopname_combination), 3))
                        : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(block.id_userstopname_combination), 1))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}