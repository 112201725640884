<template>
    <div class=" bg-gray-200 p-4 rounded-lg shadow-md" style="width: 560px">
        <!-- Dropdown Header -->
        <div class="mb-2 flex justify-between">
            <span class="text-sm text-black font-bold w-1/3">Selecteer een model:</span>
            <span class="text-sm text-black font-bold pl-2 w-2/3">Model informatie:</span>
        </div>

        <!-- Dropdown Content -->
        <div class="flex space-x-2">
            <!-- Left half: Scrollable list of models -->
            <div class="w-1/3 overflow-y-auto h-32 bg-white rounded-lg border border-gray-300 p-2 shadow">
                <div v-for="(model, index) in modelInformation" :key="index" @click="selectModel(index)" :class="[
                    'cursor-pointer text-sm block text-black text-right pr-2',
                    selectedModel && selectedModel.prediction_id === model.prediction_id ? 'font-bold' : '',
                    'hover:bg-gray-200'
                ]">
                    {{ model.prediction_id }}
                </div>
            </div>

            <!-- Right half: Information display -->
            <div class="w-2/3 p-2 bg-white rounded-lg border border-gray-300 text-black shadow">
                <div v-if="selectedModel" class="bg-white rounded-lg">
                    <!-- Model Details Card -->

                    <div class="flex justify-between text-sm">
                        <div class="font-bold">Naam:</div>
                        <div>{{ selectedModel.prediction_id }}</div>
                    </div>

                    <div class="flex justify-between text-sm mt-2">
                        <div class="font-bold">Eigenaar:</div>
                        <div>{{ selectedModel.owner }}</div>
                    </div>

                    <div class="flex justify-between text-sm mt-2">
                        <div class="font-bold">Train periode:</div>
                        <div>{{ selectedModel.train_period }}</div>
                    </div>

                    <div class="flex justify-between text-sm mt-2">
                        <div class="font-bold">Train set lengte:</div>
                        <div>{{ selectedModel.train_volume }} ritten</div>
                    </div>
                </div>

                <!-- Message if no model is selected -->
                <p v-else class="text-gray-500 p-4">Selecteer een model om details te zien.</p>
            </div>

        </div>

        <button v-if="selectedModel" :class="[
            'w-full font-semibold mt-2 py-2 px-4 rounded-lg',
            activeModel?.prediction_id === selectedModel?.prediction_id
                ? 'bg-slate-600'
                : 'bg-slate-800 hover:bg-slate-600 text-white'
        ]" @click="toggleModel(selectedModel.prediction_id)">
            <p v-if="activeModel?.prediction_id === selectedModel?.prediction_id">
                Kies eerst een ander model dan: {{ selectedModel.prediction_id }}
            </p>
            <p v-else>
                Gebruik het model: {{ selectedModel.prediction_id }}
            </p>
        </button>



        <div v-if="activeModel && factorData"
            class="w-full bg-white rounded-lg border border-gray-300 mt-2 p-4 text-black">

            <!-- Card Container with Flex Layout -->
            <div class="flex flex-wrap gap-4">

                <!-- General Information Card -->
                <div class="bg-gray-100 rounded-lg shadow p-4 flex-1 min-w-[250px]">
                    <p class="text-lg font-semibold mb-2">Huidige model: {{ activeModel.prediction_id }}</p>
                    <p class="font-bold text-sm">Algemene informatie</p>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Train periode:</div>
                        <div>{{ activeModel.train_period }}</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Train set lengte:</div>
                        <div>{{ activeModel.train_volume }} ritten</div>
                    </div>
                </div>

                <!-- Time-Related Factors Card -->
                <div class="bg-gray-100 rounded-lg shadow p-4 flex-1 min-w-[250px]">
                    <p class="font-bold text-sm">Tijdgebonden factoren</p>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Basis tijd:</div>
                        <div>{{ factorData.baseTime.toFixed(0) }} secondes</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Variatie seizoenen:</div>
                        <div>{{ (modelMinMax.seasonTimeRange.max - modelMinMax.seasonTimeRange.min).toFixed(0) }} secondes</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Variatie dagen:</div>
                        <div>{{ (modelMinMax.dayTimeRange.max - modelMinMax.dayTimeRange.min).toFixed(0) }} secondes</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Variatie tijden doordeweeks:</div>
                        <div>{{ (modelMinMax.weekdaysTimeRange.max - modelMinMax.weekdaysTimeRange.min).toFixed(0) }} secondes</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Variatie tijden zaterdag:</div>
                        <div>{{ (modelMinMax.saturdayTimeRange.max - modelMinMax.saturdayTimeRange.min).toFixed(0) }} secondes</div>
                    </div>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Variatie tijden zondag:</div>
                        <div>{{ (modelMinMax.sundayTimeRange.max - modelMinMax.sundayTimeRange.min).toFixed(0) }} secondes</div>
                    </div>
                </div>

                <!-- External Factors Card -->
                <div class="bg-gray-100 rounded-lg shadow p-4 flex-1 min-w-[250px]">
                    <p class="font-bold text-sm">Externe factoren</p>
                    <div class="flex justify-between text-sm mt-2">
                        <div>Contributie OV drukte:</div>
                        <div>{{ (factorData.ovDrukte * modelMinMax.crowdingTimeRange.min).toFixed(0) }} - {{ (factorData.ovDrukte * modelMinMax.crowdingTimeRange.max).toFixed(0) }} secondes</div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, onMounted } from 'vue';
import { PredictionParts, ModelInfo, ModelDifferences, ModelMinMax } from '@/types';

export default defineComponent({
    name: 'ModelInformation',
    emits: ['toggle-model'],
    props: {
        predictionInformation: {
            type: Object as PropType<PredictionParts | undefined>,
            required: true
        },
        modelInformation: {
            type: Array as PropType<ModelInfo[]>,
            required: true
        },
        percentiel: {
            type: Number,
            required: true
        },
        percentielen: {
            type: Array as PropType<Number[]>,
            required: true
        },
        modelId: {
            type: String,
            required: true
        },
        modelMinMax: {
            type: Object as PropType<ModelMinMax>,
                required: true
        }
    },
    setup(props, { emit }) {
        const selectedModel = ref<ModelInfo>();
        const activeModel = ref<ModelInfo>();
        const factorData = ref<ModelDifferences>();


        const selectModel = (index: number) => {
            selectedModel.value = props.modelInformation[index];
        }


        const setStandardModel = () => {
            selectedModel.value = props.modelInformation.find(model => model.prediction_id === props.modelId);
            activeModel.value = props.modelInformation.find(model => model.prediction_id === props.modelId)
        }

        function calculateDifferences(data: PredictionParts, index: number): ModelDifferences {
            // Get base time
            const baseTime = data.constant[index];
            const ovDrukte = data.public_transport_crowding[index];
            // Extract seasonal values
            const seasons = [
                data.autumn[index],
                data.spring[index],
                data.summer[index],
                data.winter[index]
            ];
            const seasonDifference = Math.max(...seasons) - Math.min(...seasons);

            // Extract daily values
            const days = [
                data.monday[index],
                data.tuesday[index],
                data.wednesday[index],
                data.thursday[index],
                data.friday[index],
                data.saturday[index],
                data.sunday[index]
            ];
            const dayDifference = Math.max(...days) - Math.min(...days);
            // Helper function to extract times based on the prefix
            function extractTimes(data: PredictionParts, prefix: string, index: number): number[] {
                return Object.keys(data)
                    .filter(key => key.startsWith(prefix))  // Find keys starting with the prefix
                    .map(key => (data[key as keyof typeof data] as number[])[index]);  // Extract values for the index
            }

            // Extract time values for weekdays, Saturdays, and Sundays
            const weekdayTimes = extractTimes(data, 'hours_week_', index);
            const saturdayTimes = extractTimes(data, 'hours_saturday_', index);
            const sundayTimes = extractTimes(data, 'hours_sunday_', index);

            // Calculate time differences
            const weekdayTimeDifference = Math.max(...weekdayTimes) - Math.min(...weekdayTimes);
            const saturdayTimeDifference = Math.max(...saturdayTimes) - Math.min(...saturdayTimes);
            const sundayTimeDifference = Math.max(...sundayTimes) - Math.min(...sundayTimes);

            // Return the calculated differences with keys
            return {
                baseTime: baseTime,
                ovDrukte: ovDrukte,
                seasonDifference: {
                    highest: Math.max(...seasons),
                    lowest: Math.min(...seasons),
                    difference: seasonDifference
                },
                dayDifference: {
                    highest: Math.max(...days),
                    lowest: Math.min(...days),
                    difference: dayDifference
                },
                weekdayTimeDifference: {
                    highest: Math.max(...weekdayTimes),
                    lowest: Math.min(...weekdayTimes),
                    difference: weekdayTimeDifference
                },
                saturdayTimeDifference: {
                    highest: Math.max(...saturdayTimes),
                    lowest: Math.min(...saturdayTimes),
                    difference: saturdayTimeDifference
                },
                sundayTimeDifference: {
                    highest: Math.max(...sundayTimes),
                    lowest: Math.min(...sundayTimes),
                    difference: sundayTimeDifference
                }

            };
        }

        const determineFactors = () => {
            const percentiel = parseFloat((props.percentiel / 100).toFixed(2)).toString()
            const activeIndex = props.predictionInformation!.id.length - 1 - props.predictionInformation!.id.slice().reverse().findIndex(id => id.endsWith(`_${percentiel}`));

            if (!props.predictionInformation) {
                throw new Error("Missing predictionInformation")
            }
            factorData.value = calculateDifferences(props.predictionInformation, activeIndex)

        }

        const toggleModel = (predictionId: string) => {
            emit('toggle-model', predictionId);
        }

        onMounted(() => {
            setStandardModel();
            determineFactors();
        });
        return {
            selectedModel,
            selectModel,
            activeModel,
            factorData,
            toggleModel
        };
    },
});
</script>

<style scoped></style>
