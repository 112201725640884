<template>
  <div v-if="isLoading">
    <SkeletonLoader />
  </div>
  <div class="h-full flex">
    <!-- Left Menu Bar -->
    <div class="w-1/4 bg-slate-800 p-4">
      <div class="flex rounded-sm shadow-md p-4 mb-4 bg-slate-700 justify-between items-center h-20"
        v-if="selectedUser">
        <div class="flex">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <path
              d="M12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12ZM12 14C8.13401 14 0 16.268 0 20.4V24H24V20.4C24 16.268 15.866 14 12 14Z" />
          </svg>
          <span class="text-md text-white">{{ selectedUser.name }}</span>
        </div>
        <button class=" px-2 py-2 bg-slate-300 h-12 text-black rounded-sm" @click="logOut">Uitloggen</button>
      </div>
      <div class="rounded-sm bg-slate-700 p-4 shadow-lg flex-grow overflow-auto flex flex-col">
        <div class="flex-col items-center justify-center">
          <h2 v-if="!selectedPublicNumber" class="text-xl text-white mb-2">Buslijnen:</h2>
          <h2 v-if="selectedPublicNumber" class="text-xl text-white mb-2">Routepatronen voor buslijn: {{
            selectedPublicNumber }}</h2>
          <button v-if="selectedPublicNumber" class="px-2 py-2 w-36 mb-2 bg-slate-300 h-12 text-black rounded-sm"
            @click="selectedPublicNumber = null">Buslijn selectie</button>
        </div>
        <div ref="listDiv" class="flex-grow overflow-auto shadow-xl p-2 rounded-sm border-gray-200 border-2"
          :style="{ 'height': 'auto', 'max-height': `${availableTableHeight}px` }">

          <div v-if="!selectedPublicNumber">
            <ul>
              <li v-for="(lines, linepublicnumber) in groupedPublicLines" :key="linepublicnumber"
                class="text-lg text-white py-2 border-b border-gray-300 last:border-b-0 hover:text-blue-500"
                @click="selectedPublicNumber = linepublicnumber">
                {{ "Buslijn " + linepublicnumber }}
              </li>
            </ul>
          </div>
          <div v-else>
            <ul>
              <li v-for="line in groupedPublicLines[selectedPublicNumber]" :key="line.id"
                class="text-lg text-white py-2 border-b border-gray-300 last:border-b-0 hover:text-blue-500"
                @click="goToLinePlanner(line.id)">
                {{ "Buslijn " + line.linepublicnumber + " | routepatroon " + line.journeypatterncode + " | " +
                line.linename }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- Right Picture Display -->
    <div class="w-3/4">
      <div class="min-h-screen bg-cover bg-center flex flex-col items-center justify-center"
        :style="{ backgroundImage: `url(${backgroundImage})` }">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, nextTick, computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router'
import SkeletonLoader from '@/components/SkeletonLoader.vue';
import { LineOverview } from '@/types'

export default defineComponent({
  name: 'HomePage',
  components: {
    SkeletonLoader
  },
  setup() {
    // Loading media
    const backgroundImage = require('@/assets/ebs.jpg');
    const listDiv = ref<HTMLElement | null>(null)
    const availableTableHeight = ref<number>(0)

    // Setting UI heigths
    const calculateAvailableTableHeigth = () => {
      if (listDiv.value) {
        const rect = listDiv.value.getBoundingClientRect();
        availableTableHeight.value = window.innerHeight - rect.top - 96;
      }
    };

    // Declaration of responsive variables
    const lines = ref<LineOverview[]>([])
    const isLoading = ref<boolean>(true)
    const selectedUser = ref<{ id: string, name: string } | null>(null)
    const selectedPublicNumber = ref<string | null>(null);

    // Data collection from the backend
    const fetchLijnen = async () => {
      try {
        const response = await axios.get<{ lineoverview: LineOverview[] }>(`${process.env.VUE_APP_BACKEND_URL}/lineoverview`);
        lines.value = response.data.lineoverview
      } catch (error) {
        console.error('Failed to fetch names:', error);
      }
    };

    // Step 1: Group lines by lineplanningnumber
    const groupedPublicLines = computed(() => {
      return lines.value.reduce((acc, line) => {
        if (!acc[line.linepublicnumber]) {
          acc[line.linepublicnumber] = [];
        }
        acc[line.linepublicnumber].push(line);
        return acc;
      }, {} as Record<string, typeof lines.value>);
    });


    // Preload image function
    const preloadImage = (src: string): Promise<void> => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });
    };

    
    const loadData = async () => {
      await Promise.all([fetchLijnen(), preloadImage(backgroundImage)]);
      isLoading.value = false;
      nextTick(() => {
        calculateAvailableTableHeigth();
        window.addEventListener('resize', calculateAvailableTableHeigth);
      });
    };

    onMounted(() => {
      loadData();
      if (localStorage.getItem('userId')) {
        selectedUser.value = { id: localStorage.getItem('userId') as string, name: localStorage.getItem('userName') as string }
      }
    });

    // Logout function
    const errorMessage = ref('');
    const logOut = async () => {
      try {
        const response = await axios.post(`${process.env.VUE_APP_BACKEND_URL}/logout`);
        if (response.status === 200) {
          localStorage.removeItem('userId');  // Clear userId
          localStorage.removeItem('userName'); // Clear userName
          localStorage.setItem('loggedIn', "false")
          router.push('/login');               // Redirect to login page
        }
      } catch (error) {
        errorMessage.value = 'Logout failed. Please try again.';
        console.error("Logout error:", error);
      }
    };

    // Clean up the event listener on component unmount
    onBeforeUnmount(() => {
      window.removeEventListener('resize', calculateAvailableTableHeigth);
    });
    // Handle routing to the next page with the necessary parameters
    const router = useRouter();
    const goToLinePlanner = (id: string) => {

      const linepublicnumber = findPropertyById(lines.value, id, 'linepublicnumber')
      const linename = findPropertyById(lines.value, id, 'linename')
      const journeypatterncode = findPropertyById(lines.value, id, 'journeypatterncode')

      localStorage.setItem('linePublicNumber', linepublicnumber as string)
      localStorage.setItem('lineName', linename as string)
      localStorage.setItem('journeyPatternCode', journeypatterncode as string)

      router.push({ name: 'SingleLijnSchedule', params: { lineId: id } });
    };


    // Helper functions 
    function findPropertyById<T extends { id: string }>(items: T[], id: string, property: keyof T): string | undefined {
      const item = items.find(item => item.id === id);
      return item ? (item[property] as unknown as string) : undefined;
    }


    return {
      lines,
      backgroundImage,
      goToLinePlanner,
      isLoading,
      selectedUser,
      listDiv,
      availableTableHeight,
      logOut,
      selectedPublicNumber,
      groupedPublicLines
    };
  }
})
</script>

<style scoped></style>
