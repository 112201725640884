<template>
  <div class="overflow-auto flex-grow pt-1">
    <div ref="timeClusterInfoDiv" class="time-cluster-info w-full flex justify-between items-center px-4">
      <div class="flex w-3/4 bottom-0">
        <div class="text-sm mr-2 rounded-full bg-white border-gray-300 border-2 shadow-md p-1 text-center w-1/4">{{
          dayClusterInfo }}</div>
        <div class="text-sm mr-2 rounded-full bg-white border-gray-300 border-2 shadow-md p-1 text-center w-1/4">{{
          timeClusterInfo }}</div>
        <div v-if="collapseTable" class="text-sm mr-2 rounded-full bg-blue-100 shadow-md p-1 text-center w-24">Totaal
        </div>
        <div v-if="!collapseTable" class="text-sm mr-2 rounded-full bg-green-100 shadow-md p-1 text-center w-24">
          Meethalte</div>
        <div v-if="!collapseTable" class="text-sm mr-2 rounded-full bg-orange-100 shadow-md p-1 text-center w-24">
          Tijdhalte</div>
        <div v-if="!collapseTable" class="text-sm mr-2 rounded-full bg-yellow-100 shadow-md p-1 text-center w-24">
          Halteertijd</div>
        <div v-if="!collapseTable" class="text-sm mr-2 rounded-full bg-red-200 shadow-md p-1 text-center w-24">
          Missend</div>
      </div>
      <div class="flex">
        <button v-if="collapseTable" @click="setPercentageButton"
          class="w-52 bg-gray-200 hover:bg-gray-300 text-black text-sm py-2 px-1 mr-2 rounded-sm my-1 items-center">
          <span class="text-center items-center">% standaard toepassen</span>
        </button>
        <button @click="toggleCollapseTable"
          class="w-48 bg-gray-200 hover:bg-gray-300 text-black text-sm py-2 pl-2 rounded-sm my-1 flex items-center right-0">
          <svg v-if="collapseTable" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none"
            viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
          <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ml-2" fill="none" viewBox="0 0 24 24"
            stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
          </svg>
          <span class="pl-2">{{ collapseTable ? "Tabel uitvouwen" : "Tabel inklappen" }}</span>
        </button>
      </div>
    </div>
    <div class="flex-grow flex-col w-full px-4 py-2 flex">
      <div class="relative overflow-hidden shadow-md sm:rounded-sm flex-grow flex flex-col">
        <div class="flex-grow overflow-auto" :style="{ 'height': 'auto', 'max-height': `${availableTableHeight}px` }">
          <table class="text-sm w-full text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
              <tr>

                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">Trajectdeel</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">Trajectdeel</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">Type</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">Type</th>

                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">Oude dienstregeling</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">Gekozen per trajectdeel</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">% Op tijd per trajectdeel</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">Gekozen vanaf start</th>
                <th v-if="collapseTable" scope="col" class="px-6 py-3 text-xs">% Op tijd vanaf start</th>

                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">Oude dienstregeling</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">Gekozen per trajectdeel</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">% Op tijd per trajectdeel</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">Gekozen vanaf start</th>
                <th v-if="!collapseTable" scope="col" class="px-6 py-3 text-xs">% Op tijd vanaf start</th>

              </tr>
            </thead>
            <tbody class="overflow-y-auto">
              <tr v-for="(item, index) in displayedStopCombinations" :key="index" :class="computeRowClass(item, index)">

                <td v-if="collapseTable" class="px-6 py-2">
                  {{ item.label }}
                </td>

                <td v-if="!collapseTable" class="px-6 py-2">
                  <span
                    v-if="item.type === 'meethalte_totaal' || item.type === 'halteer_block' || item.type === 'tijdhalte_totaal'">{{
                      item.label }}</span>
                  <span v-else>{{ item.id_userstopname_combination?.replace('-', " — ") }}</span>
                </td>


                <td v-if="collapseTable" class="px-6 py-2">
                  <span v-if="item.type !== 'halteer'">
                    Rijtijd
                  </span>
                  <span v-else>
                    Halteertijd
                  </span>
                </td>
                <td v-else class="px-6 py-2">
                  <span v-if="item.type !== 'halteer' && !item.meethalte">
                    Rijtijd
                  </span>
                  <span v-else-if="item.type === 'halteer_block'">Halteertijd</span>
                  <span v-else-if="item.type !== 'halteer' && item.type !== 'meethalte_totaal' && item.meethalte">
                    Rijtijd tot aankomst
                  </span>
                  <span v-else-if="item.type === 'meethalte_totaal' || 'tijdhalte_totaal'">Rijtijd</span>
                  <span v-else>
                    Halteertijd
                  </span>
                </td>


                <!-- This is visible when the table is collapsed, so summed and averaged values -->
                <!-- Schedule -->
                <td v-if="collapseTable" class="px-6 py-2">
                  <span v-if="item.type === 'starthalte' || item.type === 'halteer'">---</span>
                  <span v-else>
                    {{ getScheduleSum(item.track_id as string) }} </span>
                </td>

                <!-- Chosen time sum -->
                <td v-if="collapseTable" class="px-6 py-2">
                  <form v-if="item.type !== 'starthalte' && item.type !== 'halteer'"
                    class="bg-white rounded shadow-md w-16" @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="secondsToMMSS(getChosenTimeSum(item.track_id as string))"
                        @blur="event => { formatTimeInput(event, secondsToMMSS(getChosenTimeSum(item.track_id as string))); updateChosenTimeSum(item.track_id as string, (event.target as HTMLInputElement).value) }"
                        @keydown.enter="handleEnter" id="gekozen-tijd" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <button class="mr-2" type="button" @mousedown="startIncrementSum(item.track_id as string, 5)"
                        @mouseleave="stopIncrement()" @mouseup="stopIncrement()">▲</button>
                      <button type="button" @mousedown="startIncrementSum(item.track_id as string, -5)"
                        @mouseleave="stopIncrement()" @mouseup="stopIncrement()">▼</button>
                    </div>
                  </form>
                  <span v-else-if="item.type === 'starthalte' || item.type === 'halteer'">
                    {{ secondsToMMSS((typeof halteerLookup[item.prediction_id as string]?.time === 'number'
                      ? Math.round(halteerLookup[item.prediction_id as string]?.time ?? 0)
                      : 0)) }}
                  </span>
                  <span v-else>{{ secondsToMMSS(getChosenTimeSum(item.track_id as string)) }}</span>
                </td>

                <!-- Chosen time percentage-->
                <td v-if="collapseTable" class="px-6 py-2 w-32">
                  <form v-if="item.type !== 'starthalte' && item.type !== 'halteer'" @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="getChosenPercentageSum(item.track_id as string)" @blur="event => {
                        formatPercentInput(event);
                        updateChosenPercentSum(item.track_id as string, parseFloat((event.target as HTMLInputElement).value))
                      }" id="gekozen-tijd" @keydown.enter="handleEnter" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border bg-white shadow-md border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <span>%</span>
                      <button :class="determineButtonClassUp(Math.round(50))" class="mr-2" type="button"
                        @mousedown="startIncrementPercentageSum(item.track_id as string, 1)"
                        @mousemove="stopIncrementPercentageSum()" @mouseup="stopIncrementPercentageSum()">▲</button>
                      <button :class="determineButtonClassDown(Math.round(50))" type="button"
                        @mousedown="startIncrementPercentageSum(item.track_id as string, -1)"
                        @mousemove="stopIncrementPercentageSum()" @mouseup="stopIncrementPercentageSum()">▼</button>
                    </div>
                  </form>
                  <span v-else-if="item.type === 'starthalte' || item.type === 'halteer'">---</span>
                  <span v-else>Missing data</span>
                </td>


                <!-- Chosentime from start -->
                <td v-if="collapseTable" class="px-6 py-2">
                  <span v-if="item.type !== 'halteer'">
                    {{ secondsToMMSS(getChosenMeetFromStart(item.track_id as string)) }}
                  </span>
                  <span v-else>
                    {{ secondsToMMSS(
                      getChosenMeetFromStart(item.track_id as string) +
                      (typeof halteerLookup[item.prediction_id as string]?.time === 'number'
                        ? Math.round(halteerLookup[item.prediction_id as string]?.time ?? 0)
                        : 0)
                    ) }}
                  </span>
                </td>
                <!-- Chosentime percentage from start -->
                <td v-if="collapseTable" class="px-6 py-2">
                  <span v-if="item.type !== 'halteer'">
                    {{ getChosenMeetPercentageFromStart(item.track_id as string) }}%
                  </span>
                  <span v-else>
                    ---
                  </span>
                </td>







                <!-- This is visible when the table is expanded -->
                <!-- Schedule -->
                <td v-if="!collapseTable">
                  <span v-if="item.type === 'starthalte' || item.type === 'eindhalte'">---</span>
                  <span v-else-if="item.type === 'meethalte_totaal'">{{ getScheduleSum(item.prediction_id as string)
                    }}</span>
                  <span v-else-if="item.type === 'tijdhalte_totaal'">{{ getScheduleSum(item.prediction_id as string)
                    }}</span>
                  <span v-else>
                    {{ getSchedule(item.prediction_id as string) }}
                  </span>
                </td>

                <!-- Chosen time per block -->
                <td v-if="!collapseTable" class="px-6 py-2">
                  <span v-if="item.type === 'meethalte_totaal'">{{ secondsToMMSS(getChosenTimeSum(item.prediction_id as
                    string)) }}</span>

                  <span v-else-if="item.type === 'tijdhalte_totaal'">{{
                    secondsToMMSS(getChosenTimeSumTimeStop(item.prediction_id as
                      string)) }}</span>
                  <span v-else-if="item.type === 'halteer_block'">
                    {{ secondsToMMSS(Math.round((typeof halteerLookupBlock[item.prediction_id as string]?.time ===
                      'number'
                      ? Math.round(halteerLookupBlock[item.prediction_id as string]?.time ?? 0)
                      : 0))) }}
                  </span>
                  <span v-else>{{ secondsToMMSS(getChosenTimeBlock(item.prediction_id as string)) }}</span>
                </td>

                <!-- Chosen percentage per block -->
                <td v-if="!collapseTable" class="px-6 py-2 w-32">
                  <span v-if="item.type === 'meethalte_totaal'">{{ getChosenPercentageSum(item.prediction_id as
                    string) }}%</span>
                  <span v-else>---</span>
                </td>

                <!-- Chosen time from start -->
                <td v-if="!collapseTable" class="px-6 py-2">
                  <div v-if="item.type === 'tijdhalte_totaal'" class="flex">
                    <form @submit.prevent="handleSubmit">
                      <div class="flex">
                        <input type="text" @keydown.enter="handleEnter"
                          :value="secondsToMMSS(getChosenTimeBlockFromStart(item.prediction_id as string))" @blur="event => {
                            formatTimeInput(event, secondsToMMSS(getChosenTimeBlockFromStart(item.prediction_id as string)));
                            updateChosenTimeBlock(item.prediction_id as string, (event.target as HTMLInputElement).value)
                          }"
                          class="w-16 p-1 text-sm border bg-white shadow-md border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      </div>
                    </form>
                  </div>
                  <span v-else-if="item.type === 'meethalte_totaal'">{{
                    secondsToMMSS(getChosenTimeFromStart(item.prediction_id as string)) }}
                  </span>
                  <span v-else-if="item.type === 'halteer_block'">{{
                    secondsToMMSS(getChosenTimeFromStart(item.track_id as string) +
                      Math.round((typeof halteerLookupBlock[item.prediction_id as string]?.time === 'number'
                        ? Math.round(halteerLookupBlock[item.prediction_id as string]?.time ?? 0)
                        : 0))) }}
                  </span>
                  <span v-else>{{ secondsToMMSS(getChosenTimeBlockFromStart(item.prediction_id as string)) }}</span>
                </td>

                <!-- Chosen percentage from start -->
                <td v-if="!collapseTable" class="px-6 py-2">

                  <span v-if="item.type === 'meethalte_totaal'">
                    {{ getChosenMeetPercentageFromStart(item.prediction_id as string) }}%
                  </span>
                  <span v-else-if="item.type !== 'halteer_block' && item.meethalte">
                    {{ getChosenMeetPercentageFromStart(item.prediction_id as string) }}%
                  </span>
                  <div v-else-if="item.type === 'tijdhalte_totaal'" class="flex">
                    <form @submit.prevent="handleSubmit">
                      <div class="flex">
                        <input type="number" @keydown.enter="handleEnter"
                          :value="getChosenPercentageFromStart(item.prediction_id as string)" @blur="event => {
                            formatPercentInput(event);
                            updateChosenTimeBlockPercentage(item.prediction_id as string, parseFloat((event.target as HTMLInputElement).value))
                          }"
                          class="w-16 p-1 text-sm border bg-white shadow-md border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                          <span>%</span>
                      </div>
                    </form>
                  </div>
                  <span
                    v-else-if="!checkAvailableData(item.prediction_id as string) && item.type !== 'halteer_block'">Missing
                    data</span>
                  <span v-else-if="item.type !== 'halteer_block'">
                    {{ getChosenPercentageFromStart(item.prediction_id as string) }}%
                  </span>
                  <span v-else>
                    ---
                  </span>
                </td>
              </tr>




              <!-- The total journey -->

              <!-- Schedule -->
              <tr v-if="collapseTable" class="bg-blue-100 font-bold">
                <td class="px-6 py-4">
                  {{ beginAndEndStop }}
                </td>
                <td class="px-6 py-4">Totaal</td>
                <td class="px-6 py-4">{{ totalSchedule }}</td>

                <!-- Chosen total time -->
                <td class="px-6 py-4">
                  <form class="bg-white rounded shadow-md w-16" @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="secondsToMMSS(Math.round(totalsumchosen))"
                        @blur="event => { formatTimeInput(event, secondsToMMSS(Math.round(totalsumchosen))); updateTotalChosenTime(mmssToSeconds((event.target as HTMLInputElement).value)) }"
                        @keydown.enter="handleEnter" id="gekozen-tijd" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <button class="mr-2" type="button" @mousedown="startIncrementTotal(5)"
                        @mouseup="stopIncrementTotal()">▲</button>
                      <button type="button" @mousedown="startIncrementTotal(-5)"
                        @mouseup="stopIncrementTotal()">▼</button>
                    </div>
                  </form>
                </td>

                <!-- Chosen total percentage -->
                <td class="px-6 py-4 w-32">
                  <form @submit.prevent="handleSubmit">
                    <div class="flex">
                      <input type="text" :value="totalPercentageChosen.toFixed(0)" @blur="event => {
                        formatPercentInput(event);
                        updateTotalPercentage(parseFloat((event.target as HTMLInputElement).value))
                      }" id="gekozen-tijd" @keydown.enter="handleEnter" name="gekozen-tijd"
                        class="w-16 p-1 text-sm border bg-white shadow-md border-gray-300 rounded-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 mr-2" />
                      <span>%</span>
                      <button :class="determineButtonClassUp(Math.round(50))" class="mr-2" type="button"
                        @mousedown="startIncrementPercentageTotal(1)"
                        @mouseup="stopIncrementPercentageTotal()">▲</button>
                      <button :class="determineButtonClassDown(Math.round(50))" type="button"
                        @mousedown="startIncrementPercentageTotal(-1)"
                        @mouseup="stopIncrementPercentageTotal()">▼</button>
                    </div>
                  </form>
                </td>

                <!-- Total chosen from start -->
                <td class="px-6 py-4">
                  <span>{{ secondsToMMSS(Math.round(totalsumchosen)) }}</span>
                </td>

                <!-- Total chosen percentage from start -->
                <td class="px-6 py-4">
                  <span>{{ totalPercentageChosen.toFixed(0) }}%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, ref, watch } from "vue";
import {
  Route,
  LinePlanningSettings,
  TimeClusterData,
  DayClusterData,
  AveragedPrediction,
  Block,
  Chosen,
  ChosenMemory,
  PercentilePredictions,
  ClusteredSchedule,
  LineSchedule,
  SeasonClusterData,
} from "@/types";

export default defineComponent({
  name: "PredictionTable",
  emits: ["update-chosen-times", "set-percentage"],
  props: {
    route: {
      type: Array as () => Route[],
      required: true,
    },
    percentiel: {
      type: Number,
      required: true
    },
    predictions: {
      type: Array as () => AveragedPrediction[],
      required: true,
    },
    schedule: {
      type: Array as () => LineSchedule[],
      required: true,
    },
    activeTimeCluster: {
      type: Number,
      required: true,
    },
    timeClusterTrigger: {
      type: Number,
      required: true,
    },
    activeDayCluster: {
      type: Number,
      required: true,
    },
    activeSeasonCluster: {
      type: Number,
      required: true,
    },
    linePlanningSettings: {
      type: Object as PropType<LinePlanningSettings>,
      required: true,
    },
    chosenMemory: {
      type: Array as PropType<ChosenMemory[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    /*
    The prediction table provides the user with all the predictions and the ability to build and test different scheduletimes.

    The script is build up as follows:
    1. Reactive variable declaration and state management.
    2. Data functionality like sorting and clustering of data.
    3. Responsive data functionality, interactions and calculations based on the chosen times.
    4. Functions for displaying and formatting data for the ui.
    5. Helper functions.
    6. State management and data loading.
    7. Pure UI visual functions.
    */
    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 1. Reactive variable declaration and state management /////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////
    const timeClusterInfo = ref<string>("");
    const timeClusterInfoLength = ref<number>(0);
    const dayClusterInfo = ref<string>("");
    const dayClusterInfoLength = ref<number>(0);
    const timeIdentifier = ref<string>("");
    const dayIdentifier = ref<string>("");
    const seasonIdentifier = ref<string>("");
    const journeyIdentifier = ref<string>("");
    const dayMapper: { [key: string]: string } = {
      monday: "Maandag",
      tuesday: "Dinsdag",
      wednesday: "Woensdag",
      thursday: "Donderdag",
      friday: "Vrijdag",
      saturday: "Zaterdag",
      sunday: "Zondag",
    };

    const lineNameLocal = ref<string | null>(localStorage.getItem('lineName'));
    const journeyPatternCodeLocal = ref<string | null>(localStorage.getItem('journeyPatternCode'));
    const linePublicNumberLocal = ref<string | null>(localStorage.getItem('linePublicNumber'));

    const currentPrediction = ref<AveragedPrediction>();
    const aggregatedPrediction = ref<AveragedPrediction>();
    const chosenTimes = ref<Chosen[]>([]);
    const singleTimeCluster = ref<TimeClusterData[][]>([]);
    const timeClusterInfoDiv = ref<HTMLElement | null>(null);
    const availableTableHeight = ref<number>(0);
    const clusteredSchedule = ref<ClusteredSchedule[]>([]);
    const totalSchedule = ref<string>("");

    const totalsum = ref<number>(0);
    const totalscheduledtime = ref<number>(0);
    const totalsumchosen = ref<number>(0);
    const totalPercentageChosen = ref<number>(50);
    const timer = ref<number>(0);
    const speed = ref<number>(300);
    const speedMultiplier = ref<number>(1.1);
    const standardPercentage = ref<string>(parseFloat((props.percentiel / 100).toFixed(2)).toString())
    const collapseTable = ref<boolean>(true);


    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 2. Data functionality /////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////


    // 2.1 Schedule data functionality
    const runSchedule = async () => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("No lineplanninginfo on the selected stops");
      }
      clusteredSchedule.value = clusterSchedule(props.schedule, timeIdentifier.value, dayIdentifier.value, seasonIdentifier.value, journeyIdentifier.value, props.linePlanningSettings.stop_combinations);
      totalSchedule.value = calculateTotalSchedule();
    }

    function clusterSchedule(data: LineSchedule[], timeCluster: string, dayCluster: string, seasonCluster: string, journeyCluster: string, orderedRoute: Route[]): ClusteredSchedule[] {
      const grouped: ClusteredSchedule[] = [];
      const journeyClusterArray = journeyCluster.split('_');
      const dayClusterArray = dayCluster.split('_');
      const seasonClusterArray = seasonCluster.split('_');
      const activeStops = getActiveStopsCode(orderedRoute);
      const matchedData = data.filter(d => {
        const journeyNumber = getJourneyNumberFromId(d.id);
        const day = getDayFromScheduleId(d.id);
        const season = getSeasonFromScheduleId(d.id);
        return journeyClusterArray.includes(journeyNumber) && dayClusterArray.includes(day) && seasonClusterArray.includes(season);
      });
      if (matchedData.length) {
        const newId = matchedData.map(d => d.id).join('__');
        const stopTimeRanges: { [id_areacode_combination: string]: string } = {};
        const stopSumRanges: { [id_areacode_combination: string]: string } = {};

        let minScheduleLine: number | null = null;
        let maxScheduleLine: number | null = null;
        const filteredRoute = orderedRoute.filter(item => item.id_areacode_combination.split('_')[0] !== item.id_areacode_combination.split('_')[1])
        const orderMap = new Map();
        filteredRoute.forEach((item, index) => {
          orderMap.set(item.id_areacode_combination, index);
        });

        matchedData.forEach(lineSchedule => {
          let sumTripTotal = 0;
          let tripMinTotal = 0;
          let tripMaxTotal = 0;

          // Filter the schedule to only include stops present in orderedRoute
          lineSchedule.schedule = lineSchedule.schedule.filter(stop => {
            return orderMap.has(stop.id_areacode_combination);
          });


          filteredRoute.forEach((stop, index) => {
            if (!stop.id_areacode_combination) {
              throw new Error("NO stop combination");
            }
            const userstopcode = stop.id_areacode_combination;
            const scheduleForStop = lineSchedule.schedule.find(item => item.id_areacode_combination === userstopcode)
            let seconds = 0;
            if (scheduleForStop) {
              seconds = hhmmssToSeconds(scheduleForStop.time_planned as string);
            }
            sumTripTotal += seconds;

            // Time Range Calculation
            if (!stopTimeRanges[userstopcode]) {
              stopTimeRanges[userstopcode] = `${seconds}-${seconds}`;
            } else {
              const [min, max] = stopTimeRanges[userstopcode].split('-').map(Number);
              const newMin = Math.min(min, seconds);
              const newMax = Math.max(max, seconds);
              stopTimeRanges[userstopcode] = `${newMin}-${newMax}`;
            }

            // Sum Range Calculation
            if (!stopSumRanges[userstopcode]) {
              stopSumRanges[userstopcode] = `${sumTripTotal}-${sumTripTotal}`;
            } else {
              const [min, max] = stopSumRanges[userstopcode].split('-').map(Number);

              const newMinSum = Math.min(min, sumTripTotal);
              const newMaxSum = Math.max(max, sumTripTotal);
              stopSumRanges[userstopcode] = `${newMinSum}-${newMaxSum}`;
            }

            tripMinTotal = index === 0 ? seconds : tripMinTotal + seconds;
            tripMaxTotal = index === 0 ? seconds : tripMaxTotal + seconds;
            if (activeStops.includes(userstopcode)) {
              sumTripTotal = 0;
            }
          });

          // Update the overall min/max for the entire schedule
          if (minScheduleLine === null || tripMinTotal < minScheduleLine) {
            minScheduleLine = tripMinTotal;
          }
          if (maxScheduleLine === null || tripMaxTotal > maxScheduleLine) {
            maxScheduleLine = tripMaxTotal;
          }
        });

        const timeRangeArray = Object.entries(stopTimeRanges).map(([userstopcode, range]) => {
          return { [userstopcode]: range };
        });

        const sumRangeArray = Object.entries(stopSumRanges).map(([userstopcode, sumRange]) => {
          return { [userstopcode]: sumRange };
        });
        grouped.push({
          id: newId,
          time_cluster: timeCluster,
          day_cluster: dayCluster,
          journey_cluster: journeyCluster,
          season_cluster: seasonCluster,
          total_schedule: `${minScheduleLine}-${maxScheduleLine}`,
          time_range: timeRangeArray,
          sum_range: sumRangeArray,
        });
      }

      return grouped;
    }

    // 2.2 Prediction data functionality
    const updateAggregatedPrediction = () => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("No lineplanninginfo on the selected stops");
      }
      const rawPrediction = currentPrediction.value;
      if (!rawPrediction) {
        aggregatedPrediction.value = {
          id: "loading",
          timeCluster: "loading",
          dayCluster: "loading",
          single_prediction_dep: [],
          single_predictions_dep: {},
          single_prediction_arr: [],
          single_predictions_arr: {},
          single_prediction_dep_start: [],
          single_predictions_dep_start: {},
          single_prediction_arr_start: [],
          single_predictions_arr_start: {},
          single_prediction_total: [],
          single_predictions_total: {},
          single_prediction_halteer: [],
          single_predictions_halteer: {},
          single_prediction_traject: [],
          single_predictions_traject: {}
        }
      } else {
        const sortedPrediction = sortRoute(
          props.linePlanningSettings.stop_combinations,
          rawPrediction
        );

        const activeStops = getActiveStops(props.linePlanningSettings.stop_combinations);
        const tijdStops = getTijdStops(props.linePlanningSettings.stop_combinations);
        const sortedActivePrediction = determineActiveStops(sortedPrediction, activeStops, tijdStops);
        aggregatedPrediction.value = calculateSumValues(sortedActivePrediction);

        initTotalChosen();
        calculatePercentages();
      }
    };

    const processPredictions = (predictionType: string, predictionData: Block[], stops: Route[], standardPercentage: string, addMissingStops: boolean) => {
      // Reduce the prediction data into a structure keyed by percentile
      const predictionByPercentile = predictionData.reduce((acc: Record<string, Block[]>, pred: Block) => {
        const percentile = (pred.id as string).split('_').pop(); // Get the last part of the ID which is the percentage
        if (!acc[percentile as string]) {
          acc[percentile as string] = [];
        }
        acc[percentile as string].push(pred);
        return acc;
      }, {});

      // Ensure each prediction contains all stops
      Object.keys(predictionByPercentile).forEach(percentile => {
        const predictions = predictionByPercentile[percentile];

        // Create a map of existing stops in predictions
        const existingStops = new Set(predictions.map(pred => {
          if (!pred.id) {
            throw new Error(`Missing id in ${predictionType}`);
          }
          const parts = pred.id.split('_');
          parts.pop();
          return parts.join('_');
        }));

        // Add missing stops
        if (addMissingStops) {
          stops.forEach(stop => {
            if (!existingStops.has(stop.prediction_id as string)) {
              predictions.push({
                id: `${stop.prediction_id}_${percentile}`,
                time: 0,
                available: false,
              });
            }
          });
        }

        // Sort the predictions to maintain a consistent order (optional)
        predictionByPercentile[percentile] = predictions.sort((a, b) =>
          stops.findIndex(stop => stop.id === a.stop_id) - stops.findIndex(stop => stop.id === b.stop_id)
        );
      });

      // Return filtered predictions based on the standardPercentage
      const filteredPredictions = predictionByPercentile[standardPercentage] || [];

      return {
        allPredictions: predictionByPercentile,
        filteredPredictions
      };
    };

    const processPredictionsFromStartArr = (
      predictionData: Block[],
      ): Block[] => {

      let lastId = predictionData[predictionData.length - 1].id
      let prefix = lastId?.split('_').slice(0, -1).join('_');
      
      // Filter predictions from start_arr that match the total IDs
      const totalPredictions = predictionData.filter(pred => {
        if (!pred.id) { 
          throw new Error("We are missing a prediction id")
        }
        const predIdWithoutPercentile = pred.id.split('_').slice(0, -1).join('_'); // Remove the last part (percentile)
        // Match the base ID (excluding percentile) with the last element of total ids
        return predIdWithoutPercentile === prefix;
      });
      return totalPredictions
    };

    const setCurrentPrediction = async () => {
      const prediction = props.predictions.find(
        (entry) =>
          entry.dayCluster == dayIdentifier.value &&
          entry.timeCluster == timeIdentifier.value &&
          entry.seasonCluster === seasonIdentifier.value
      );



      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("Missing stop combinations");
      }
      const stops = getTracksFromStopCombinations(props.linePlanningSettings.stop_combinations);

      if (prediction) {
        const single_prediction_dep = prediction.single_prediction_dep;
        const single_prediction_arr = prediction.single_prediction_arr;
        const single_prediction_dep_start = prediction.single_prediction_dep_start;
        const single_prediction_arr_start = prediction.single_prediction_arr_start;
        const single_prediction_total = prediction.single_prediction_total;
        const single_prediction_halteer = prediction.single_prediction_halteer;
        const single_prediction_traject = prediction.single_prediction_traject;

        if (!single_prediction_dep || !single_prediction_arr || !single_prediction_total || !single_prediction_halteer || !single_prediction_traject || !single_prediction_dep_start || !single_prediction_arr_start) {
          throw new Error("No single prediction available");
        }

        // Process all types of predictions
        const depPredictions = processPredictions('single_prediction_dep', single_prediction_dep, stops, standardPercentage.value, true);
        const arrPredictions = processPredictions('single_prediction_arr', single_prediction_arr, stops, standardPercentage.value, true);
        const depPredictionsStart = processPredictions('single_prediction_dep_start', single_prediction_dep_start, stops, standardPercentage.value, true);
        const arrPredictionsStart = processPredictions('single_prediction_arr_start', single_prediction_arr_start, stops, standardPercentage.value, true);

        // Use start_arr to generate total predictions
        const totalPredictions = processPredictions('single_prediction_total', processPredictionsFromStartArr(single_prediction_arr_start), stops, standardPercentage.value, true);

        // Process other prediction types
        const halteerPredictions = processPredictions('single_prediction_halteer', single_prediction_halteer, stops, standardPercentage.value, true);
        const trajectPredictions = processPredictions('single_prediction_traject', single_prediction_traject, stops, standardPercentage.value, false);


        // Store all results in the current prediction
        currentPrediction.value = {
          ...prediction,
          single_prediction_dep: depPredictions.filteredPredictions,
          single_predictions_dep: depPredictions.allPredictions,
          single_prediction_arr: arrPredictions.filteredPredictions,
          single_predictions_arr: arrPredictions.allPredictions,
          single_prediction_dep_start: depPredictionsStart.filteredPredictions,
          single_predictions_dep_start: depPredictionsStart.allPredictions,
          single_prediction_arr_start: arrPredictionsStart.filteredPredictions,
          single_predictions_arr_start: arrPredictionsStart.allPredictions,
          single_prediction_total: totalPredictions.filteredPredictions,
          single_predictions_total: totalPredictions.allPredictions,  // Assuming you need both filtered and all
          single_prediction_halteer: halteerPredictions.filteredPredictions,
          single_predictions_halteer: halteerPredictions.allPredictions,
          single_prediction_traject: trajectPredictions.filteredPredictions,
          single_predictions_traject: trajectPredictions.allPredictions
        };
        if (!currentPrediction.value.single_prediction_total) {
          throw new Error("Missing the prediction for the total time");
        }
        calculateTotalSum(currentPrediction.value.single_prediction_total);
      }
    };

    const sortRoute = (
      orderedRoute: Route[],
      unorderedPrediction: AveragedPrediction
    ): AveragedPrediction => {
      const orderMap = new Map();

      // Create a map of the prediction_id to its order in the orderedRoute
      orderedRoute.forEach((item, index) => {
        // Assuming you want to sort all predictions (including percentiles)
        orderMap.set(item.prediction_id, index);
      });

      if (!unorderedPrediction.single_prediction_dep) {
        throw new Error("There was no single_prediction available");
      }

      // Sort the different prediction types: dep, arr, total, halteer
      const sortedDep = sortPrediction(unorderedPrediction, 'single_prediction_dep', 'single_predictions_dep', orderMap);
      const sortedArr = sortPrediction(unorderedPrediction, 'single_prediction_arr', 'single_predictions_arr', orderMap);
      const sortedHalteer = sortPrediction(unorderedPrediction, 'single_prediction_halteer', 'single_predictions_halteer', orderMap);


      return {
        ...unorderedPrediction,
        ...sortedDep,
        ...sortedArr,
        ...sortedHalteer,
      };
    };


    const updatePredictions = (
      data: AveragedPrediction,
      predictionKey: keyof AveragedPrediction,
      percentileKey: keyof AveragedPrediction,
      activeStops: string[],
      tijdStops: string[]
    ) => {
      // Update single_prediction
      const updatedSinglePrediction = data[predictionKey]
        ? determineActiveForBlocks(data[predictionKey] as Block[], standardPercentage.value, activeStops, tijdStops)
        : undefined;

      // Update single_predictions (percentiles)
      const updatedSinglePredictions = data[percentileKey]
        ? Object.keys(data[percentileKey] as PercentilePredictions).reduce((acc, percentile) => {
          acc[percentile] = determineActiveForBlocks(
            (data[percentileKey] as PercentilePredictions)[percentile],
            percentile,
            activeStops,
            tijdStops
          );
          return acc;
        }, {} as PercentilePredictions)
        : undefined;

      return {
        [predictionKey]: updatedSinglePrediction,
        [percentileKey]: updatedSinglePredictions,
      };
    };

    const calculateSumValues = (data: AveragedPrediction): AveragedPrediction => {

      // Function to calculate sums for a given array of Block objects
      const calculateSumForBlocks = (blocks: Block[], traject: Block[]): Block[] => {
        let partsum = 0;
        let sumActive = 0;
        let totalSum = 0;
        let scheduleTime = 0;
        let scheduleTimeActive = 0;
        let totalScheduleTime = 0;

        return blocks.map((item) => {
          let scheduleId = item.id?.split('_').slice(0, item.id?.split('_').length - 1).join('_') + '_' + standardPercentage.value
          if (partsum === 0) {
            sumActive = 0;
            scheduleTimeActive = 0;
          }
          if (!item.id) {
            return {
              ...item,
            };
          }

          // if we also want the schedule run the shedule part
          if (item.meethalte) {
            // If the item is active
            // in case it is active but we do not have the time
            let time = 0;
            if (!item.time) {
              if (getSchedule(scheduleId) !== 'No schedule') {
                var scheduledTime = getSchedule(scheduleId).split('-')
                if (scheduledTime.length === 1) {
                  time = mmssToSeconds(scheduledTime[0])
                } else {
                  time = (mmssToSeconds(scheduledTime[0]) + mmssToSeconds(scheduledTime[1])) / 2 // add the average schedule time to the total
                }
              } else {
                // edge case no schedule or prediction
                time = 0;
              }
              scheduleTimeActive += time
              totalScheduleTime += time;
            } else {
              time = item.time
            }
            // in case it is active but we do have the time
            let meethalteTime = findMeethaltePredictionById(item.id, traject)
            totalSum += time
            sumActive += time;
            partsum = 0;
            scheduleTime = 0;
            return {
              ...item,
              sum: sumActive,
              totalsum: totalSum,
              scheduletime: scheduleTimeActive,
              totalscheduletime: totalScheduleTime,
              meethaltetime: meethalteTime
            };
          } else {
            // If we are dealing with a regular item 
            // Regular item but we miss the time
            let time = 0;
            if (!item.time) {
              if (getSchedule(scheduleId) !== 'No schedule') {
                scheduledTime = getSchedule(scheduleId).split('-')
                if (scheduledTime.length === 1) {
                  time = mmssToSeconds(scheduledTime[0])
                } else {
                  time = (mmssToSeconds(scheduledTime[0]) + mmssToSeconds(scheduledTime[1])) / 2 // add the average schedule time to the total
                }
              } else {
                // edge case no schedule and prediction
                time = 0;
              }
              scheduleTime += time;
              totalScheduleTime += time;
              scheduleTimeActive = scheduleTime
            } else {
              time = item.time;
            }
            // regular item when we have the time         
            partsum += time;
            totalSum += time
            sumActive = partsum;
            return {
              ...item,
              sum: partsum,
              totalsum: totalSum,
              scheduletime: scheduleTime,
              totalscheduletime: totalScheduleTime
            };
          }
        });
      };

      if (!data.single_prediction_dep && !data.single_predictions_dep) {
        throw new Error("There is no departure prediction available here");
      }
      if (!data.single_prediction_traject && !data.single_predictions_traject) {
        throw new Error("There is no traject prediction available here");
      }
      // Update single_prediction
      const updatedSinglePrediction = data.single_prediction_dep
        ? calculateSumForBlocks(data.single_prediction_dep, data.single_prediction_traject!)
        : undefined;
      // Update single_predictions_dep (percentiles)
      const updatedSinglePredictions = data.single_predictions_dep
        ? Object.keys(data.single_predictions_dep).reduce((acc, percentile) => {
          if (!data.single_predictions_dep) {
            throw new Error("There is a currentPrediction without a single_predictions_dep attribute")
          }
          acc[percentile] = calculateSumForBlocks(data.single_predictions_dep[percentile], data.single_predictions_traject![percentile]);
          return acc;
        }, {} as PercentilePredictions)
        : undefined;

      return {
        ...data,
        single_prediction_dep: updatedSinglePrediction,
        single_predictions_dep: updatedSinglePredictions,
      };
    };
    
    const handleChosenTimesInitialization = () => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("We are missing the necessary stop information");
      }
      // If there is a memory entry we load the memory from there
      if (props.chosenMemory.some((obj) => obj.clusterId === getClusterId())) {
        const memoryEntry = props.chosenMemory.find((obj) => {
          return obj.clusterId === getClusterId()
        });

        if (!memoryEntry?.chosenTimes) {
          throw new Error("There is some corrupted data");
        }
        chosenTimes.value = memoryEntry.chosenTimes
      } else {
        // Otherwise it is initialized from the prediction values
        const tracks = getTracksFromStopCombinations(props.linePlanningSettings.stop_combinations);
        if (currentPrediction.value?.single_prediction_dep && currentPrediction.value?.single_prediction_traject) {
          chosenTimes.value = tracks.map(track => {
            const blockId = track.prediction_id + '_' + standardPercentage.value;
            let missing = false;
            let scheduleMissing = false;
            let prediction = 0;
            if (track.meethalte) {
              let pred = currentPrediction.value?.single_prediction_arr?.find(item => item.id === blockId)?.time;
              if (pred) {
                prediction = pred;
              }
            } else {
              let pred = currentPrediction.value?.single_prediction_dep?.find(item => item.id === blockId)?.time;
              if (pred) {
                prediction = pred;
              }
            }

            if (prediction !== 0) {
              var input = prediction
            } else if (getSchedule(blockId) !== 'No schedule') {
              missing = true;
              var scheduledTime = getSchedule(blockId).split('-')
              if (scheduledTime.length === 1) {
                input = mmssToSeconds(scheduledTime[0])
              } else {
                input = (mmssToSeconds(scheduledTime[0]) + mmssToSeconds(scheduledTime[1])) / 2
              }
              prediction = input;
            } else {
              input = 0;
              missing = true;
              scheduleMissing = true;
            }
            let inputMeethalte = 0;
            if (track.meethalte) {
              inputMeethalte = findMeethaltePredictionById(blockId, currentPrediction.value?.single_prediction_traject!);
            }
            return {
              blockId,
              predictionBlock: prediction,
              inputBlock: input,
              meethalte: track.meethalte,
              tijdhalte: track.tijdhalte,
              inputMeethalte: inputMeethalte,
              missing,
              scheduleMissing
            }
          })
          initChosenSum();
          calibrateChosenSum();
          determineAllInputSums();
        }
      }
      updateAggregatedPrediction();
      handleSaveToMemory();
      distributeInputProportionally(); // distribute the excess meethalte time over the single stops
    };

    const initChosenSum = () => {
      let sum = 0;
      let sumActive = 0;
      let sumTijdhalte = 0;
      chosenTimes.value.forEach((item) => {
        if (sum === 0) {
          sumActive = 0;
          sumTijdhalte = 0;
        }
        if (!item.blockId) {
          item.missing = true;
          item.inputSum = 0;
          item.inputBlock = 0;
          item.sumTijdhalte = 0;
        } else {
          if (item.meethalte) {
            sumActive += item.inputBlock ? item.inputBlock : 0;
            sum = 0;
            item.inputSum = sumActive;
            if (!item.inputMeethalte) {
              item.inputMeethalte = sumActive;
            }
          } else if (item.tijdhalte) {
            sum += item.inputBlock ? item.inputBlock : 0;
            sumActive = sum;
            item.inputSum = sum;
            item.sumTijdhalte = sumTijdhalte;
            sumTijdhalte = 0;
          } else {
            sum += item.inputBlock ? item.inputBlock : 0;
            sumActive = sum;
            sumTijdhalte = sum;
            item.inputSum = sum;
          }
        }
      });
    }

    const calibrateChosenSum = () => {
      if (!totalsum.value) {
        throw new Error("We are missing the total predicted time")
      }
      const meethalteTimes = chosenTimes.value.filter(item => item.meethalte);
      const totalMeethalteTime = meethalteTimes.reduce((sum, item) => {
        if (!item.inputMeethalte) {
          return 0;
        }
        return sum + item.inputMeethalte;
      }, 0);

      const predictedStopTime = currentPrediction.value?.single_prediction_halteer
        ?.filter(item => activeStopsBetweenMeethaltes.value.includes(item.id!))
        ?.reduce((sum, item) => sum + (item.time || 0), 0);

      let totalStopTime = 0;
      if (predictedStopTime) {
        totalStopTime = predictedStopTime
      }
      // Step 3: Calculate the difference
      const difference = totalsum.value - totalMeethalteTime - totalStopTime;

      // Step 4: Find the last meethalteTime where meethalte is true
      const lastMeethalte = meethalteTimes[meethalteTimes.length - 1];

      // Step 5: Subtract the difference from the last meethalteTime
      if (lastMeethalte && lastMeethalte.inputMeethalte) {
        lastMeethalte.inputMeethalte += difference;
      }
    }

    const distributeInputProportionally = () => {
      let lastMeethalteIndex = -1;
      const ranges: [number, number][] = [];
      // Identify ranges between meethaltes
      chosenTimes.value.forEach((item, index) => {
        if (item.meethalte) {
          if (lastMeethalteIndex === -1) {
            ranges.push([0, index + 1]);
          } else {
            ranges.push([lastMeethalteIndex + 1, index + 1]);
          }
          lastMeethalteIndex = index;
        }
      });

      // Include the remaining items after the last meethalte
      if (lastMeethalteIndex !== chosenTimes.value.length - 1) {
        ranges.push([lastMeethalteIndex + 1, chosenTimes.value.length]);
      }
      // Process each range between two meethaltes
      ranges.forEach((range) => {
        const rangeItems = chosenTimes.value.slice(range[0], range[1]);
        const timingstopsPresent = rangeItems.find(item => item.tijdhalte);
        const finalMeethalte = rangeItems[rangeItems.length - 1]; // Assuming the last item is the meethalte

        if (timingstopsPresent && finalMeethalte && finalMeethalte.inputMeethalte) {
          // We have a range with timingstops in between which we have to deal with
          let cumulativeTime = 0;
          let lastindex = 0;
          let sumToTimeStop = 0;

          // Handle each tijdhalte within the range
          rangeItems.forEach((item, index) => {
            if (item.inputBlock) {
              sumToTimeStop += item.inputBlock;
            }
            if (item.tijdhalte) {
              // Calculate time based on the tijdhalte percentage
              lastindex = index
              cumulativeTime = sumToTimeStop;
            }

            // If it's the last tijdhalte before the final meethalte, we need to ensure the meethalte percentage
            if (index === rangeItems.length - 1 || (rangeItems[index + 1] && rangeItems[index + 1].meethalte)) {
              const totalTimeForRange = finalMeethalte.inputMeethalte;
              if (!totalTimeForRange) {
                throw new Error("There is no totaltime input for the meethalte")
              }
              const remainingTime = totalTimeForRange - cumulativeTime;
              if (remainingTime > 0) {
                // Distribute remaining time over the blocks after the last tijdhalte
                const remainingItems = rangeItems.slice(lastindex + 1, rangeItems.length);
                const totalBlockTime = remainingItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);

                remainingItems.forEach((item) => {
                  if (item.predictionBlock) {
                    const timeProportion = (item.predictionBlock / totalBlockTime) || 0;
                    item.inputBlock = item.predictionBlock + (remainingTime - totalBlockTime) * timeProportion;
                  }
                });
              }
            }
          });
        } else {
          // We have a range without any timing stops, we can distribute the time proportionally across all blocks
          if (!finalMeethalte || !finalMeethalte.inputMeethalte) {
            throw new Error("Missing final meethalte");
          }

          const totalTimeForRange = finalMeethalte.inputMeethalte - rangeItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);
          if (!totalTimeForRange) {
            throw new Error("Missing info for distributing time across range without timing stops");
          }

          // Calculate total block time for all items
          const totalBlockTime = rangeItems.reduce((sum, item) => sum + (item.predictionBlock || 0), 0);
          // Distribute the remaining time proportionally across all blocks
          rangeItems.forEach((item) => {
            let timeProportion = 0;
            if (!item.blockId) {
              throw new Error("Missing item.blockId for distributing time");
            }
            if (item.predictionBlock) {
              timeProportion = (item.predictionBlock / totalBlockTime) || 0;
            }

            // Distribute time proportionally, using the available total time for the range
            item.inputBlock = (item.predictionBlock || 0) + totalTimeForRange * timeProportion;
          });
        }
      });
      determineAllInputSums();
      calculatePercentages();
      handleSaveToMemory();
    };

    const determineAllInputSums = () => {
      let sum = 0;
      let sumStart = 0;
      let sumMeetStart = 0;
      let stopTime = 0;
      let addStopToNextBlock = false;
      let sumTijdhalte = 0;
      chosenTimes.value.forEach((item) => {
        sum += item.inputBlock ? item.inputBlock : 0;
        sumStart += item.inputBlock ? item.inputBlock : 0;
        sumTijdhalte += item.inputBlock ? item.inputBlock : 0;
        item.inputSum = sum;
        item.inputSumFromStart = sumStart;
        if (addStopToNextBlock) {
          sumStart += stopTime ? stopTime : 0;
          item.inputSumFromStart = sumStart;
          addStopToNextBlock = false;
        }
        if (item.meethalte) {
          addStopToNextBlock = true
          sum = 0;
          sumTijdhalte = 0;
          sumMeetStart += item.inputMeethalte ? item.inputMeethalte : 0;
          sumMeetStart += stopTime ? stopTime : 0;
          item.inputMeetFromStart = sumMeetStart;
          const blockIdParts = (item.blockId as string).split('_');
          const fifthElement = blockIdParts[4]; // The 5th element after splitting

          // Find the matching stop ID in activeStopsBetweenMeethaltes based on the 5th element
          const matchedStopId = activeStopsBetweenMeethaltes.value.find(stopId => stopId.split('_')[4] === fifthElement);

          if (matchedStopId && currentPrediction.value?.single_prediction_halteer) {
            // Use the matchedStopId to find the corresponding stop in single_prediction_halteer
            const stopPrediction = currentPrediction.value?.single_prediction_halteer.find(stop => stop.id === matchedStopId)?.time;
            stopTime = stopPrediction ? stopPrediction : 0;
          }
        }
        if (item.tijdhalte) {
          item.sumTijdhalte = sumTijdhalte;
          sumTijdhalte = 0;
        }
      })
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 3. Responsive data functionality, interactions and calculations based on the chosen times./////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const updateTotalChosenTime = (value: number) => {
      totalsumchosen.value = value;
      distributeTotalTimeOverSums(totalsumchosen.value)
      totalPercentageChosen.value = calculateTotalPercentageFromValue(totalsumchosen.value)
      translateSumToBlock();
      handleSaveToMemory();
    };

    const updateTotalPercentage = (userPercentage: number): void => {
      const newTotalTime = calculateTotalTimeFromPercentage(userPercentage)
      updateTotalChosenTime(newTotalTime)
      handleSaveToMemory();
    };


    const updateChosenTimeSum = (id: string, value: string) => {
      const chosenTime = chosenTimes.value.find(item => {
        return item.blockId === id
      });

      if (!chosenTime) {
        throw new Error("missing the ChosenTime for that id")
      }
      if (chosenTime) {
        chosenTime.inputMeethalte = mmssToSeconds(value);
      }
      let totalSum = 0;
      chosenTimes.value.forEach(item => {
        if (item.meethalte && item.inputMeethalte) {
          totalSum += item.inputMeethalte;
        }
      })
      activeStopsBetweenMeethaltes.value.forEach(stop => {
        totalSum += halteerLookupBlock.value[stop as string].time!

      })
      translateSumToBlock();
      updateTotalChosenTime(totalSum);
      handleSaveToMemory();
    };

    // Handling updates in the time stops
    const updateChosenTimeBlock = (id: string, value: string) => {
      const seconds = mmssToSeconds(value);
      setBlockInputs(id, seconds);
      distributeInputProportionally();
    }

    const setBlockInputs = (id: string, value: number) => {
      let lastTijdIndex = 0;
      let activeIndex = 0;
      let sum = 0;
      let searching = true;
      let difference = 0;
      let stopTime = 0;
      chosenTimes.value.forEach((time, index) => {
        sum += time.inputBlock ? time.inputBlock : 0;
        if (time.blockId === id) {
          // We are dealing with the actual time stop.
          difference = value - sum;
          searching = false;
          activeIndex = index;
        } else if ((time.meethalte || time.tijdhalte) && searching) {
          lastTijdIndex = index;
          if (time.meethalte) {
            const blockIdParts = (time.blockId as string).split('_');
            const fifthElement = blockIdParts[4]; // The 5th element after splitting

            // Find the matching stop ID in activeStopsBetweenMeethaltes based on the 5th element
            const matchedStopId = activeStopsBetweenMeethaltes.value.find(stopId => stopId.split('_')[4] === fifthElement);

            if (matchedStopId && currentPrediction.value?.single_prediction_halteer) {
              // Use the matchedStopId to find the corresponding stop in single_prediction_halteer
              const stopPrediction = currentPrediction.value?.single_prediction_halteer.find(stop => stop.id === matchedStopId)?.time;
              stopTime += stopPrediction ? stopPrediction : 0;
            }
          }
        }
      })

      const timeBlock = chosenTimes.value.slice(lastTijdIndex + 1, activeIndex + 1)
      difference -= stopTime;
      if (difference !== 0) {
        const timeBlockSum = timeBlock.reduce((acc, item) => item.inputBlock ? acc + item.inputBlock : 0, 0);
        const ratio = (timeBlockSum + difference) / timeBlockSum;
        timeBlock.forEach(item => {
          item.inputBlock = item.inputBlock ? item.inputBlock * ratio : 0;
        })
      }
    }

    const updateChosenTimeBlockPercentage = (id: string, percentage: number) => {
      // Write a updateChosenPercentageTijd function? 
      const seconds = percentageFromStartToBlock(id, percentage);
      const time = secondsToMMSS(seconds);
      updateChosenTimeBlock(id, time)
    }


    const calculatePercentages = (): void => {
      // Iterate over each entry in chosenTimeBlock
      let previousPercentageSum = parseFloat(standardPercentage.value);
      let previousPercentageFromStart = parseFloat(standardPercentage.value)
      let previousMeethalteId = "";
      if (chosenTimes.value[0]) {
        previousMeethalteId = chosenTimes.value[0].blockId;
      }
      chosenTimes.value.forEach((chosenTime) => {
        if (!aggregatedPrediction.value) {
          throw new Error("There is no aggregatedPrediction")
        }
        if (!aggregatedPrediction.value.single_prediction_dep || !aggregatedPrediction.value.single_predictions_dep) {
          throw new Error("No single prediction or single predictions available for the depdep");
        }

        if (!aggregatedPrediction.value.single_prediction_traject || !aggregatedPrediction.value.single_predictions_traject) {
          throw new Error("No single prediction or single predictions available for the traject");
        }

        if (!aggregatedPrediction.value.single_prediction_arr || !aggregatedPrediction.value.single_predictions_arr) {
          throw new Error("No single prediction or single predictions available for the arrivals");
        }

        if (!aggregatedPrediction.value.single_prediction_arr_start || !aggregatedPrediction.value.single_predictions_arr_start) {
          throw new Error("No single prediction or single predictions available for the arrivals");
        }

        if (!aggregatedPrediction.value.single_prediction_dep_start || !aggregatedPrediction.value.single_predictions_dep_start) {
          throw new Error("No single prediction or single predictions available for the arrivals");
        }

        const id = chosenTime.blockId;
        // Find the corresponding single prediction by ID
        // Get the times for the given ID
        const times = getTimesForId(id as string, aggregatedPrediction.value.single_predictions_dep);
        // [0.2: 100, 0.5: 120, 0.8: ....]
        if (times.length === 0) {
          chosenTime.percentageBlock = parseFloat(standardPercentage.value);
          chosenTime.percentageSum = previousPercentageSum;
          chosenTime.percentageFromStart = previousPercentageFromStart;

        } else {
          // Calculate the percentage based on the targetTime
          let calculatedPercentageBlock = 0;
          const targetTimeBlock = chosenTime.inputBlock; // Assuming sum is the time we want to match

          if (!targetTimeBlock) {
            throw new Error("There is no chosenTImes")
          }

          // calculate the percentage for the blocks
          if (targetTimeBlock <= times[0].time) {
            calculatedPercentageBlock = times[0].percentile * 100;
          } else if (targetTimeBlock >= times[times.length - 1].time) {
            calculatedPercentageBlock = times[times.length - 1].percentile * 100;
          } else {
            // Perform linear interpolation between the surrounding percentiles
            for (let i = 0; i < times.length - 1; i++) {
              const lower = times[i];
              const upper = times[i + 1];

              if (targetTimeBlock >= lower.time && targetTimeBlock <= upper.time) {
                const timeRange = upper.time - lower.time;
                const timeDelta = targetTimeBlock - lower.time;
                const percentileRange = upper.percentile - lower.percentile;
                calculatedPercentageBlock = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

                break;
              }
            }
          }
          // Store the calculated percentage in chosenTimes
          chosenTime.percentageBlock = calculatedPercentageBlock;
        }


        const idParts = id.split('_');
        const previousParts = previousMeethalteId.split('_');
        const depStartId = idParts[0] + '_' + idParts[1] + '_' + idParts[2] + '_' + previousParts[3] + '_' + idParts[4] + '_' + idParts[5]
        const timesBlockStart = getTimesForBlockStartId(depStartId as string, aggregatedPrediction.value.single_predictions_dep_start);
        if (timesBlockStart.length === 0) {
          chosenTime.percentageFromStart = parseFloat(standardPercentage.value);
        } else {
          // Calculate the percentage based on the targetTime
          let calculatedPercentageBlockStart = 0;
          const targetTimeBlockStart = chosenTime.inputSumFromStart;
          if (!targetTimeBlockStart) {
            console.warn("missing targettimeblockstart")
          } else {
            // calculate the percentage for the blocks
            if (targetTimeBlockStart <= timesBlockStart[0].time) {
              calculatedPercentageBlockStart = timesBlockStart[0].percentile * 100;
            } else if (targetTimeBlockStart >= timesBlockStart[timesBlockStart.length - 1].time) {
              calculatedPercentageBlockStart = timesBlockStart[timesBlockStart.length - 1].percentile * 100;
            } else {
              // Perform linear interpolation between the surrounding percentiles
              for (let i = 0; i < timesBlockStart.length - 1; i++) {
                const lower = timesBlockStart[i];
                const upper = timesBlockStart[i + 1];

                if (targetTimeBlockStart >= lower.time && targetTimeBlockStart <= upper.time) {
                  const timeRange = upper.time - lower.time;
                  const timeDelta = targetTimeBlockStart - lower.time;
                  const percentileRange = upper.percentile - lower.percentile;
                  calculatedPercentageBlockStart = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

                  break;
                }
              }
            }
            // Store the calculated percentage in chosenTimes
            chosenTime.percentageFromStart = calculatedPercentageBlockStart;
          }
        }


        if (chosenTime.meethalte) {
          const timesMeethaltes = getMeethalteTimesForId(id as string, aggregatedPrediction.value.single_predictions_traject);
          if (timesMeethaltes.length === 0) {
            console.warn(`No times found for the given id: ${id}`);
            chosenTime.percentageMeethalte = parseFloat(standardPercentage.value);
          } else {
            // Calculate the percentage based on the targetTime
            let calculatedPercentageMeethalte = 0;
            const targetTimeMeet = chosenTime.inputMeethalte;
            if (!targetTimeMeet) {
              throw new Error("There is no chosenTImes for the meethalte")
            }
            // calculate the percentage for the blocks
            if (targetTimeMeet <= timesMeethaltes[0].time) {
              calculatedPercentageMeethalte = timesMeethaltes[0].percentile * 100;
            } else if (targetTimeMeet >= timesMeethaltes[timesMeethaltes.length - 1].time) {
              calculatedPercentageMeethalte = timesMeethaltes[timesMeethaltes.length - 1].percentile * 100;
            } else {
              // Perform linear interpolation between the surrounding percentiles
              for (let i = 0; i < timesMeethaltes.length - 1; i++) {
                const lower = timesMeethaltes[i];
                const upper = timesMeethaltes[i + 1];

                if (targetTimeMeet >= lower.time && targetTimeMeet <= upper.time) {
                  const timeRange = upper.time - lower.time;
                  const timeDelta = targetTimeMeet - lower.time;
                  const percentileRange = upper.percentile - lower.percentile;
                  calculatedPercentageMeethalte = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

                  break;
                }
              }
            }
            // Store the calculated percentage in chosenTimes
            chosenTime.percentageMeethalte = calculatedPercentageMeethalte;
          }

          // start times for Arrivals
          const timesMeethaltesStart = getMeethalteTimesForId(id as string, aggregatedPrediction.value.single_predictions_arr_start);
          if (timesMeethaltesStart.length === 0) {
            chosenTime.percentageMeethalteStart = parseFloat(standardPercentage.value);
          } else {
            // Calculate the percentage based on the targetTime
            let calculatedPercentageMeethalteStart = 0;
            const targetTimeMeet = chosenTime.inputMeetFromStart;

            if (!targetTimeMeet) {
              throw new Error("There is no chosenTImes for the meethalte")
            }
            // calculate the percentage for the blocks
            if (targetTimeMeet <= timesMeethaltesStart[0].time) {
              calculatedPercentageMeethalteStart = timesMeethaltesStart[0].percentile * 100;
            } else if (targetTimeMeet >= timesMeethaltesStart[timesMeethaltesStart.length - 1].time) {
              calculatedPercentageMeethalteStart = timesMeethaltesStart[timesMeethaltesStart.length - 1].percentile * 100;
            } else {
              // Perform linear interpolation between the surrounding percentiles
              for (let i = 0; i < timesMeethaltesStart.length - 1; i++) {
                const lower = timesMeethaltesStart[i];
                const upper = timesMeethaltesStart[i + 1];

                if (targetTimeMeet >= lower.time && targetTimeMeet <= upper.time) {
                  const timeRange = upper.time - lower.time;
                  const timeDelta = targetTimeMeet - lower.time;
                  const percentileRange = upper.percentile - lower.percentile;
                  calculatedPercentageMeethalteStart = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

                  break;
                }
              }
            }
            // Store the calculated percentage in chosenTimes
            chosenTime.percentageMeethalteStart = calculatedPercentageMeethalteStart;
          }
        }

        // start times for departures
        // Determine the id for the dep start table


        const arrTimes = getTimesForIdArr(id as string, aggregatedPrediction.value.single_predictions_arr)
        if (arrTimes.length !== 0) {
          let calculatedPercentageBlockArr = 0;
          const targetTimeBlockArr = chosenTime.inputBlock;
          if (!targetTimeBlockArr) {
            throw new Error("Missing the arr prediction data")
          }
          // calculate the percentage for blocks of arr
          if (targetTimeBlockArr <= arrTimes[0].time) {
            calculatedPercentageBlockArr = arrTimes[0].percentile * 100;
          } else if (targetTimeBlockArr >= arrTimes[arrTimes.length - 1].time) {
            calculatedPercentageBlockArr = arrTimes[arrTimes.length - 1].percentile * 100;
          } else {
            // Perform linear interpolation between the surrounding percentiles
            for (let i = 0; i < arrTimes.length - 1; i++) {
              const lower = arrTimes[i];
              const upper = arrTimes[i + 1];

              if (targetTimeBlockArr >= lower.time && targetTimeBlockArr <= upper.time) {
                const timeRange = upper.time - lower.time;
                const timeDelta = targetTimeBlockArr - lower.time;
                const percentileRange = upper.percentile - lower.percentile;
                calculatedPercentageBlockArr = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;

                break;
              }
            }
          }
          chosenTime.percentageBlockArr = calculatedPercentageBlockArr;
        }
      });
    };

    const updateChosenPercentSum = (blockId: string, userPercentage: number): void => {
      if (!aggregatedPrediction.value) {
        throw new Error("There is no aggregatedPrediction");
      }
      if (!aggregatedPrediction.value.single_prediction_traject || !aggregatedPrediction.value.single_predictions_traject) {
        throw new Error("No single prediction or single predictions available");
      }

      let times = getMeethalteTimesForId(blockId, aggregatedPrediction.value.single_predictions_traject);
      // Convert user input percentage to a fraction
      const targetPercentile = userPercentage / 100;

      let calculatedTime = 0;
      let newPercentage = userPercentage;
      if (targetPercentile <= times[0].percentile) {
        calculatedTime = times[0].time;
        newPercentage = times[0].percentile * 100;
      } else if (targetPercentile >= times[times.length - 1].percentile) {
        calculatedTime = times[times.length - 1].time;
        newPercentage = times[times.length - 1].percentile * 100;
      } else {
        // Perform inverse interpolation between the surrounding percentiles
        for (let i = 0; i < times.length - 1; i++) {
          const lower = times[i];
          const upper = times[i + 1];

          if (targetPercentile >= lower.percentile && targetPercentile <= upper.percentile) {
            const percentileRange = upper.percentile - lower.percentile;
            const percentileDelta = targetPercentile - lower.percentile;
            const timeRange = upper.time - lower.time;
            calculatedTime = lower.time + (percentileDelta / percentileRange) * timeRange;

            break;
          }
        }
      }

      // Update the input time in chosenTimes
      const chosenTime = chosenTimes.value.find((item) => item.blockId === blockId);
      if (chosenTime) {
        chosenTime.inputMeethalte = calculatedTime;
        chosenTime.percentageSum = newPercentage;
      } else {
        console.error("Could not find the chosenTime entry for the provided trackId");
      }
      let totalSum = 0;
      chosenTimes.value.forEach(item => {
        if (item.meethalte && item.inputMeethalte) {
          totalSum += item.inputMeethalte;
        }
      })
      activeStopsBetweenMeethaltes.value.forEach(stop => {
        totalSum += halteerLookupBlock.value[stop as string].time!
      })
      updateTotalChosenTime(totalSum);
      translateSumToBlock();
    };

    const calculateTotalSchedule = (): string => {
      const timeCluster = getActiveTimeClusters();
      if (!timeCluster || !props.linePlanningSettings.day_clusters) {
        throw new Error("There is no time or day cluster data");
      }
      const timeIdentifier = timeCluster[props.activeTimeCluster]
        .map((time) => time.starttime)
        .join("_");
      const dayIdentifier = props.linePlanningSettings.day_clusters[
        props.activeDayCluster
      ]
        .map((day) => day.day)
        .join("_");
      const seasonIdentifier = props.linePlanningSettings.season_clusters[props.activeSeasonCluster].map(item => item.season).join('_')

      const activeSchedule = clusteredSchedule.value.find((item) => {
        return item.day_cluster === dayIdentifier && item.time_cluster === timeIdentifier && item.season_cluster === seasonIdentifier;
      })

      if (activeSchedule && activeSchedule.total_schedule) {
        const minmax = activeSchedule.total_schedule?.split('-');
        if (minmax[0] === minmax[1]) {
          return `${secondsToMMSS(parseInt(minmax[0]))}`
        } else {
          return `${secondsToMMSS(parseInt(minmax[0]))} - ${secondsToMMSS(parseInt(minmax[1]))}`;
        }
      } else {
        return "Missing schedule"
      }
    }

    // Functions for calculating the aggregated and single chosen times, again the very tricky part
    const calculateTotalSum = (totalPrediction: Block[]) => {
      if (!totalPrediction || totalPrediction.length === 0 || totalPrediction[0]?.time === undefined) {
        throw new Error("Missing the total time");
      } else {
        // Assert that time is not undefined here
        totalsum.value = totalPrediction[0].time!;
      }
    };

    const initTotalChosen = () => {
      if (!props.chosenMemory.some((obj) => obj.clusterId === getClusterId())) {
        totalsumchosen.value = totalsum.value;
        totalPercentageChosen.value = calculateTotalPercentageFromValue(totalsumchosen.value);
      } else {
        let totalSum = 0;
        chosenTimes.value.forEach(item => {
          if (item.meethalte && item.inputMeethalte) {
            totalSum += item.inputMeethalte;
          }
        })
        activeStopsBetweenMeethaltes.value.forEach(stop => {
          totalSum += halteerLookupBlock.value[stop as string].time!
        })
        totalsumchosen.value = totalSum
        totalPercentageChosen.value = calculateTotalPercentageFromValue(totalSum)
      }
    };

    const calculateTotalPercentageFromValue = (totalValue: number): number => {
      let totalPercentage = 0;
      let lastActiveItem = {};

      if (!lastActiveItem) {
        console.error("No active item found in chosenTimes");
        return totalPercentage;
      }

      // Ensure aggregatedPrediction is available
      if (!aggregatedPrediction.value) {
        throw new Error("No aggregated predictions");
      }
      if (!aggregatedPrediction.value.single_predictions_total) {
        throw new Error("No single predictions");
      }

      let times = getTimesForIdTotal(aggregatedPrediction.value.single_predictions_total);


      if (totalValue <= times[0].time) {
        totalPercentage = times[0].percentile * 100;
      } else if (totalValue >= times[times.length - 1].time) {
        totalPercentage = times[times.length - 1].percentile * 100;
      } else {
        // Perform linear interpolation between the surrounding sums
        for (let i = 0; i < times.length - 1; i++) {
          const lower = times[i];
          const upper = times[i + 1];

          if (totalValue >= lower.time && totalValue <= upper.time) {
            const timeRange = upper.time - lower.time;
            const timeDelta = totalValue - lower.time;
            const percentileRange = upper.percentile - lower.percentile;
            totalPercentage = (lower.percentile + (timeDelta / timeRange) * percentileRange) * 100;
            break;
          }
        }
      }
      return totalPercentage;
    };


    const calculateTotalTimeFromPercentage = (percentage: number): number => {
      let calculatedTime = 0;

      // Ensure aggregatedPrediction is available
      if (!aggregatedPrediction.value) {
        throw new Error("No aggregated predictions");
      }
      if (!aggregatedPrediction.value.single_predictions_total) {
        throw new Error("No single predictions");
      }

      // Get the times for the last active item's ID
      let times = getTimesForIdTotal(aggregatedPrediction.value.single_predictions_total);
      if (times && times.length > 0) {
        // Sort times by percentile to ensure they are in the correct order for interpolation
        times.sort((a, b) => a.percentile - b.percentile);
      }
      // Calculate the corresponding time for the given percentage
      const normalizedPercentage = percentage / 100;

      if (normalizedPercentage <= times[0].percentile) {
        calculatedTime = times[0].time;
      } else if (normalizedPercentage >= times[times.length - 1].percentile) {
        calculatedTime = times[times.length - 1].time;
      } else {
        // Perform linear interpolation between the surrounding times
        for (let i = 0; i < times.length - 1; i++) {
          const lower = times[i];
          const upper = times[i + 1];

          if (normalizedPercentage >= lower.percentile && normalizedPercentage <= upper.percentile) {
            const percentileRange = upper.percentile - lower.percentile;
            const percentileDelta = normalizedPercentage - lower.percentile;
            const timeRange = upper.time - lower.time;
            calculatedTime = lower.time + (percentileDelta / percentileRange) * timeRange;
            break;
          }
        }
      }
      return calculatedTime;
    };

    // Functions for handling the setting of total times
    const distributeTotalTimeOverSums = (newTotalTime: number) => {
      if (!chosenTimes.value || chosenTimes.value.length === 0) {
        throw new Error("There are no chosenTimes");
      }

      let totalSum = 0;
      chosenTimes.value.forEach(item => {
        if (item.meethalte && item.inputMeethalte) {
          totalSum += item.inputMeethalte;
        }
      })
      activeStopsBetweenMeethaltes.value.forEach(stop => {
        totalSum += halteerLookupBlock.value[stop as string].time!

      })
      const extraTime = newTotalTime - totalSum;
      const lastRangeIndex = chosenTimes.value.length - 1;

      // Check if the last range exists and has an inputSum
      const lastRangeItem = chosenTimes.value[lastRangeIndex];
      if (lastRangeItem && lastRangeItem.inputMeethalte !== undefined) {
        lastRangeItem.inputMeethalte += extraTime;
      } else {
        console.error('Could not find a valid last range to add extra time.');
      }
    };


    const percentageFromStartToBlock = (blockId: string, percentage: number) => {
      if (!aggregatedPrediction.value) {
        throw new Error("There is no aggregatedPrediction");
      }
      if (!aggregatedPrediction.value.single_prediction_dep_start || !aggregatedPrediction.value.single_predictions_dep_start) {
        throw new Error("No single prediction or single predictions available");
      }

      // Get the times for the given ID
      const times = getMeethalteTimesForId(blockId, aggregatedPrediction.value.single_predictions_dep_start);

      if (times.length === 0) {
        console.error(`No times found for the given id: ${blockId}`);
        return 0; // or throw an error
      }

      // Normalize the percentage to be used for interpolation
      const normalizedPercentage = percentage / 100;

      let calculatedTime = 0;

      if (normalizedPercentage <= times[0].percentile) {
        calculatedTime = times[0].time;
      } else if (normalizedPercentage >= times[times.length - 1].percentile) {
        calculatedTime = times[times.length - 1].time;
      } else {
        // Perform linear interpolation between the surrounding percentiles
        for (let i = 0; i < times.length - 1; i++) {
          const lower = times[i];
          const upper = times[i + 1];

          if (normalizedPercentage >= lower.percentile && normalizedPercentage <= upper.percentile) {
            const percentileRange = upper.percentile - lower.percentile;
            const timeRange = upper.time - lower.time;
            const percentileDelta = normalizedPercentage - lower.percentile;
            calculatedTime = lower.time + (percentileDelta / percentileRange) * timeRange;
            break;
          }
        }
      }

      return calculatedTime;
    }


    // Handling of the buttons:
    const incrementTimeTotal = (amount: number) => {
      totalsumchosen.value = Math.max(totalsumchosen.value + amount, 0);
    };

    const incrementPercentageTotal = (amount: number) => {
      totalPercentageChosen.value += amount;
    };


    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 4. Functions for displaying and formatting data for the ui. ///////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const formatTimeInput = (event: Event, originalValue: string) => {
      const input = event.target as HTMLInputElement;
      let value = input.value.replace(/[^0-9:]/g, ""); // Allow only numbers and colon

      if (!value.includes(":") && value.length > 2) {
        value = value.slice(0, -2) + ":" + value.slice(-2);
      }

      const parts = value.split(":");
      if (parts.length === 2) {
        const minutes = parts[0];
        const seconds = parts[1];

        if (seconds.length > 2 || parseInt(seconds) >= 60) {
          alert("Invalid time format. Please enter a valid time in MM:SS format.");
          input.value = originalValue;
          return;
        }

        if (minutes.length > 0 && seconds.length === 2) {
          input.value = value;
        } else {
          input.value = originalValue;
        }
      } else {
        input.value = originalValue;
      }
    };

    const secondsToMMSS = (seconds: number) => {
      const mins = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
    };

    const mmssToSeconds = (mmss: string) => {
      const [mins, secs] = mmss.split(":").map(Number);
      return mins * 60 + secs;
    };

    const hhmmssToSeconds = (hhmmss: string): number => {
      if (hhmmss.length > 8) {
        // Some hacky error handling for the error on the schedule part
        const parts = hhmmss.split(':');
        const hours = parts[0].slice(-2);
        return 24 * 3600 - Number(hours) * 3600 - Number(parts[1]) * 60 - Number(parts[2])
      }

      const [h, m, s] = hhmmss.split(':').map(Number);
      return h * 3600 + m * 60 + s
    }



    const startIncrementSum = (id: string, amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementTimeSum(id, amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10); // Decrease interval time to increase speed
        timer.value = setTimeout(repeatIncrement, speed.value);
      };

      // Start the first increment
      incrementTimeSum(id, amount);

      // Start the repeating increment
      timer.value = setTimeout(repeatIncrement, speed.value);

      // Add a global mouseup event to ensure stopIncrement is called if mouseup occurs outside the button
      window.addEventListener('mouseup', stopIncrement);
    };

    const stopIncrement = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      calculatePercentages();
      // Remove the global mouseup event listener
      window.removeEventListener('mouseup', stopIncrement);
    };

    const incrementPercentageSum = (id: string, amount: number) => {
      const chosenTime = chosenTimes.value.find(item => item.blockId === id);
      if (chosenTime) {
        if (chosenTime.percentageMeethalte === undefined) {
          chosenTime.percentageMeethalte = 0; // Default to 0 if no percentage is set
        }

        // Update the percentage by the specified amount, ensuring it stays within 0-100%
        chosenTime.percentageMeethalte = Math.max(0, Math.min(chosenTime.percentageMeethalte + amount, 100));

        // Calculate and update the corresponding time based on the new percentage
        updateChosenPercentSum(id, chosenTime.percentageMeethalte);
      } else {
        console.error(`Could not find chosenTimeSum entry for id: ${id}`);
      }
    };

    const incrementTimeSum = (id: string, amount: number) => {
      const chosenTime = chosenTimes.value.find(item => item.blockId === id);
      if (chosenTime) {
        if (!chosenTime.inputSum) {
          throw new Error("There is no input available for this value");
        }
        chosenTime.inputSum = Math.max(mmssToSeconds(secondsToMMSS(chosenTime.inputSum)) + amount, 0);
        const formattedTime = secondsToMMSS(chosenTime.inputSum);
        updateChosenTimeSum(id, formattedTime);
      }
    };

    const startIncrementPercentageSum = (id: string, amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementPercentageSum(id, amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10); // Decrease interval time to increase speed
        timer.value = setTimeout(repeatIncrement, speed.value);
      };

      // Start the first increment
      incrementPercentageSum(id, amount);

      // Start the repeating increment
      timer.value = setTimeout(repeatIncrement, speed.value);

      // Add a global mouseup event to ensure stopIncrementPercentageSum is called if mouseup occurs outside the button
      window.addEventListener('mouseup', stopIncrementPercentageSum);
    };

    const stopIncrementPercentageSum = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      // Remove the global mouseup event listener
      window.removeEventListener('mouseup', stopIncrementPercentageSum);
    };

    const startIncrementTotal = (amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementTimeTotal(amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10); // Decrease interval time to increase speed
        timer.value = setTimeout(repeatIncrement, speed.value);
      };
      incrementTimeTotal(amount);
      timer.value = setTimeout(repeatIncrement, speed.value);
      window.addEventListener('mouseup', stopIncrementTotal);
    };

    const startIncrementPercentageTotal = (amount: number) => {
      if (timer.value) return;

      const repeatIncrement = () => {
        incrementPercentageTotal(amount);
        speed.value = Math.max(speed.value / speedMultiplier.value, 10);
        timer.value = setTimeout(repeatIncrement, speed.value);
      };
      incrementPercentageTotal(amount);
      timer.value = setTimeout(repeatIncrement, speed.value);
      window.addEventListener('mouseup', stopIncrementPercentageTotal);
    };

    const stopIncrementPercentageTotal = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      updateTotalPercentage(totalPercentageChosen.value);
      window.removeEventListener('mouseup', stopIncrementPercentageTotal);
    };

    const stopIncrementTotal = () => {
      clearTimeout(timer.value);
      timer.value = 0;
      speed.value = 300; // Reset speed
      updateTotalChosenTime(totalsumchosen.value)
      window.removeEventListener('mouseup', stopIncrementTotal);
    }


    const formatPercentInput = (event: Event) => {
      const input = event.target as HTMLInputElement;
      let value = Number(input.value.replace(/[^0-9]/g, "")); // Allow only numbers and colon
      input.value = value.toString();
    };

    const beginAndEndStop = computed(() => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("no stop combinations provided");
      }
      return props.linePlanningSettings.stop_combinations[0].id_userstopname_combination + ' — ' + props.linePlanningSettings.stop_combinations[props.linePlanningSettings.stop_combinations.length - 1].id_userstopname_combination
    })

    const activeStopCombinations = computed(() => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("no stop combinations provided");
      }

      let previousId = props.linePlanningSettings.stop_combinations[1].prediction_id + '_' + standardPercentage.value;
      let filteredStops: Route[] = [];
      props.linePlanningSettings.stop_combinations.forEach((item) => {
        if (item.id !== undefined && item.meethalte && item.type !== 'rijtijd' && item.type !== 'starthalte') {
          filteredStops.push({
            ...item,
            track_id: previousId, // Save the previous item's ID
          });
        }
        if (!item.id) {
          throw new Error("Missing an item_id for the time between the previous two stops")
        }
        previousId = item.prediction_id as string + '_' + standardPercentage.value; // Update previousId to the current item's ID
      });
      let previousStopName = props.linePlanningSettings.stop_combinations[0].id_userstopname_combination;
      return filteredStops.map((item) => {
        let label = previousStopName + " — " + item.id_userstopname_combination; // Previous stop name + current stop name
        previousStopName = item.id_userstopname_combination ? item.id_userstopname_combination : "Unknown stop"; // Update the previous stop name
        return {
          ...item,
          label
        };
      });
    });


    const activeStopsBetweenMeethaltes = computed(() => {
      if (!props.linePlanningSettings.stop_combinations) {
        throw new Error("No stop combinations provided");
      }

      let activeStops: Route[] = [];
      let meethalteIndexes: number[] = [];

      // Step 1: Identify the indexes of all meethalte stops
      props.linePlanningSettings.stop_combinations.forEach((item, index) => {
        if (item.meethalte && item.id !== undefined && item.type === 'rijtijd') {
          meethalteIndexes.push(index);
        }
      });

      // Step 2: Push the stop between two meethaltes to activeStopsBetweenMeethaltes if there is more than one meethalte
      if (meethalteIndexes.length > 1) {
        for (let i = 0; i < meethalteIndexes.length - 1; i++) {
          activeStops.push(props.linePlanningSettings.stop_combinations[meethalteIndexes[i] + 1])
        }
      }

      return activeStops.map(item => item.prediction_id + '_' + standardPercentage.value)
    });


    const displayedStopCombinations = computed(() => {
      if (!collapseTable.value) {
        const activeStops = new Set(getActiveStops(props.linePlanningSettings.stop_combinations));
        const tijdStops = new Set(getTijdStops(props.linePlanningSettings.stop_combinations));
        const filtered = props.linePlanningSettings.stop_combinations?.filter(item => item.type === 'rijtijd');

        let result: Route[] = [];
        let previousMeetStop = '';
        let previousTijdStop = '';

        if (filtered && filtered[0].id_userstopname_combination) {
          previousMeetStop = filtered[0].id_userstopname_combination.split(' - ')[0];
          previousTijdStop = previousMeetStop;
        }

        filtered?.forEach((item, index) => {
          const meethalte = activeStops.has(item.prediction_id as string);
          const tijdhalte = tijdStops.has(item.prediction_id as string);
          const newItem = {
            ...item,
            meethalte,
            tijdhalte,
            prediction_id: item.prediction_id + '_' + standardPercentage.value,
            deparr: false // Default `deparr` to false
          };

          // Add the current item to the result
          result.push(newItem);

          // Handle meethalte: Add an extra stop with type "meethalte_totaal"
          if (meethalte) {
            result.push({
              ...newItem,
              type: 'meethalte_totaal',
              prediction_id: newItem.prediction_id,
              label: previousMeetStop + ' — ' + newItem.id_userstopname_combination?.split(' - ')[1]
            });

            // If not the last item, add halteer_block
            if (index < filtered.length - 1) {
              let idParts = item.prediction_id?.split('_');
              if (!idParts) {
                throw new Error("invalid id");
              }
              let newId = idParts[0] + '_' + idParts[1] + '_' + idParts[2] + '_' + idParts[4] + '_' + idParts[4] + '_' + standardPercentage.value;
              result.push({
                ...newItem,
                type: 'halteer_block',
                prediction_id: newId,
                track_id: item.prediction_id + '_' + standardPercentage.value,
                label: newItem.id_userstopname_combination?.split(' - ')[1]
              });
            }

            previousMeetStop = newItem.id_userstopname_combination?.split(' - ')[1] as string;
            previousTijdStop = previousMeetStop;
          }

          // Handle tijdhalte: Add an extra stop with type "tijdhalte_totaal" (but without adding halteer_block)
          if (tijdhalte) {
            result.push({
              ...newItem,
              type: 'tijdhalte_totaal',
              prediction_id: newItem.prediction_id,
              label: previousTijdStop + ' — ' + newItem.id_userstopname_combination?.split(' - ')[1]
            });

            previousTijdStop = newItem.id_userstopname_combination?.split(' - ')[1] as string;
          }
        });

        return result;
      } else {
        let result: Route[] = [];

        if (activeStopCombinations.value.length > 1) {
          activeStopCombinations.value.forEach((item, index) => {
            result.push(item);

            // Check if we are not at the last item, to insert between stops
            if (index < activeStopCombinations.value.length - 1) {
              result.push({
                ...item,
                type: 'halteer',
                label: item.id_userstopname_combination,
                prediction_id: item.prediction_id
              });
            }
          });
        } else {
          result = activeStopCombinations.value; // If only one entry, no change
        }
        return result;
      }
    });

    // Getter functions
    const getChosenTimeFromStart = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.inputSumFromStart === undefined) {
        return 0;
      }
      return Math.round(chosenTime.inputSumFromStart);
    };

    const getChosenMeetFromStart = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.inputMeetFromStart === undefined) {
        return 0;
      }
      return Math.round(chosenTime.inputMeetFromStart);
    };

    const getChosenMeetPercentageFromStart = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.percentageMeethalteStart === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentageMeethalteStart);
    };

    const getChosenTimeSum = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.inputMeethalte === undefined) {
        return 0;
      }
      return Math.round(chosenTime.inputMeethalte);
    };

    const getChosenTimeSumTimeStop = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.sumTijdhalte === undefined) {
        return 0;
      }
      return Math.round(chosenTime.sumTijdhalte);
    }


    const getChosenTimeBlockFromStart = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.inputSumFromStart === undefined) {
        return 0;
      }
      return Math.round(chosenTime.inputSumFromStart);
    }

    const getChosenPercentageSum = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.percentageMeethalte === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentageMeethalte);
    };

    const getChosenPercentageFromStart = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => {
        return item.blockId === id
      }
      );
      if (!chosenTime || chosenTime.percentageFromStart === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentageFromStart);
    };

    const getChosenTimeBlock = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }
      const chosenTime = chosenTimes.value.find((item) => item.blockId === id);
      if (!chosenTime || chosenTime.inputBlock === undefined) {
        return 0;
      }
      return Math.round(chosenTime.inputBlock);
    };

    const getChosenPercentageBlock = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }
      const chosenTime = chosenTimes.value.find((item) => item.blockId === id);
      if (!chosenTime || chosenTime.percentageBlock === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentageBlock);
    };

    const getChosenPercentageBlockArr = (id: string): number => {
      if (chosenTimes.value.length === 0) {
        return 0;
      }

      const chosenTime = chosenTimes.value.find((item) => item.blockId === id);
      if (!chosenTime || chosenTime.percentageBlockArr === undefined) {
        return 0;
      }
      return Math.round(chosenTime.percentageBlockArr);
    }

    const getSchedule = (id: string): string => {
      const idParts = id.split('_')
      const stopcode = idParts[3] + '_' + idParts[4]
      const activeSchedule = clusteredSchedule.value.find((item) => {
        return item.day_cluster === dayIdentifier.value && item.time_cluster === timeIdentifier.value && item.season_cluster === seasonIdentifier.value;
      })

      if (activeSchedule) {
        const foundItem = activeSchedule.time_range?.find((item) => item[stopcode] !== undefined);

        if (foundItem) {
          const minmax = foundItem[stopcode].split('-')
          if (foundItem[stopcode] === '0-0') {
            return 'No schedule'
          } else if (minmax[0] === minmax[1]) {
            return `${secondsToMMSS(parseInt(minmax[0]))}`
          } else {
            return `${secondsToMMSS(parseInt(minmax[0]))} - ${secondsToMMSS(parseInt(minmax[1]))}`;
          }

        }
        return 'No schedule'; // Handle the case where nothing is found
      }
      return "work in progress"
    }

    const getScheduleSum = (id: string): string => {
      const idParts = id.split('_')
      const stopcode = idParts[3] + '_' + idParts[4]
      const activeSchedule = clusteredSchedule.value.find((item) => {
        return item.day_cluster === dayIdentifier.value && item.time_cluster === timeIdentifier.value && item.season_cluster === seasonIdentifier.value;
      })

      if (activeSchedule) {
        const foundItem = activeSchedule.sum_range?.find((item) => item[stopcode] !== undefined);
        if (foundItem) {
          const minmax = foundItem[stopcode].split('-')
          if (foundItem[stopcode] === '0-0') {
            return 'No schedule'
          } else if (minmax[0] === minmax[1]) {
            return `${secondsToMMSS(parseInt(minmax[0]))}`
          } else {
            return `${secondsToMMSS(parseInt(minmax[0]))} - ${secondsToMMSS(parseInt(minmax[1]))}`;
          }
        }
        return 'No schedule'; // Handle the case where nothing is found
      }
      return "work in progress"
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 5. Helper functions. //////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const updateTimeDayIdentifiers = () => {
      const days = convertSettingsToFullDayStrings(
        props.linePlanningSettings.day_clusters
      )[props.activeDayCluster];
      dayIdentifier.value = days.join("_");
      seasonIdentifier.value = convertSettingsToFullSeasonStrings(props.linePlanningSettings.season_clusters)[props.activeSeasonCluster].join('_')
      const times = props.linePlanningSettings.time_clusters?.find(
        (cluster) => cluster.day_cluster === dayIdentifier.value && cluster.season_cluster === seasonIdentifier.value
      );


      if (!times) {
        throw new Error("There is no time cluster for this day cluster");
      }
      singleTimeCluster.value = times.time_cluster;
      timeIdentifier.value = times.time_cluster[props.activeTimeCluster]
        .sort((a, b) => a.starttime.localeCompare(b.starttime))
        .map((timeItem) => timeItem.starttime)
        .join("_");
      journeyIdentifier.value = times.time_cluster[props.activeTimeCluster]
        .map((timeItem) => timeItem.journeynumber)
        .join("_");
    };

    function convertSettingsToTimeStrings(data?: TimeClusterData[][]): string[][] {
      if (!data) {
        return [];
      }
      return data.map((innerArray) => innerArray.map((item) => item.starttime || ""));
    }

    function convertSettingsToFullDayStrings(data?: DayClusterData[][]): string[][] {
      if (!data) {
        return [];
      }
      return data.map((innerArray) => innerArray.map((item) => item.day || ""));
    }

    function convertSettingsToFullSeasonStrings(data?: SeasonClusterData[][]): string[][] {
      if (!data) {
        return [];
      }
      return data.map((innerArray) => innerArray.map((item) => item.season || ""));
    }

    function getDayFromScheduleId(id: string): string {
      return id.split('_')[4];
    }

    function getSeasonFromScheduleId(id: string): string {
      return id.split('_')[5];
    }

    function getJourneyNumberFromId(id: string): string {
      return id.split('_')[3];
    }

    const getTimesForId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number; sum: number; total: number }[] => {
      // Extract the base ID (everything before the last `_`)

      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; time: number; sum: number; total: number }[] = [];
      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.time && timeData.totalsum) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeData.time,
            sum: timeData.sum,
            total: timeData.totalsum
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };

    const getTimesForBlockStartId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number; }[] => {
      // Extract the base ID (everything before the last `_`)

      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; time: number; }[] = [];
      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.time) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeData.time,
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };

    const getTimesForIdArr = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number; }[] => {
      // Extract the base ID (everything before the last `_`)

      const baseId = id.substring(0, id.lastIndexOf('_'));
      const times: { percentile: number; time: number; }[] = [];
      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.startsWith(baseId)
        });
        if (timeData && timeData.time) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeData.time,
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };

    const getMeethalteTimesForId = (
      id: string,
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number; }[] => {
      // Extract the base ID (everything before the last `_`)

      const idStop = id.split('_')[4];
      const times: { percentile: number; time: number; }[] = [];

      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        const timeData = timeDataList.find((item) => {
          if (!item.id) {
            throw new Error("THere is no id")
          }
          return item.id.split('_')[4] === idStop;
        });
        if (timeData && timeData.time) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeData.time
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    }

    const getTimesForIdTotal = (
      percentiles: PercentilePredictions,
    ): { percentile: number; time: number; }[] => {
      // Extract the base ID (everything before the last `_`)
      const times: { percentile: number; time: number; }[] = [];
      // Iterate over all percentiles to find matching base IDs
      for (const [percentile, timeDataList] of Object.entries(percentiles)) {
        if (timeDataList && timeDataList[0].time) {
          times.push({
            percentile: parseFloat(percentile),
            time: timeDataList[0].time
          });
        }
      }

      // Sort the times by percentile to ensure correct order for interpolation
      times.sort((a, b) => a.percentile - b.percentile);

      return times;
    };

    const sortBlocksByOrder = (blocks: Block[], orderMap: Map<string, number>): Block[] => {
      return blocks.sort((a, b) => {
        const baseIdA = a.id?.split('_').slice(0, -1).join('_');
        const baseIdB = b.id?.split('_').slice(0, -1).join('_');

        if (!baseIdA || !baseIdB) {
          throw new Error("Missing id for one of the blocks");
        }

        const orderA = orderMap.get(baseIdA);
        const orderB = orderMap.get(baseIdB);

        if (orderA === undefined || orderB === undefined) {
          throw new Error(`Order not found for baseIdA: ${baseIdA} or baseIdB: ${baseIdB}`);
        }

        return orderA - orderB;
      });
    };

    // Helper function to sort predictions of any type
    const sortPrediction = (
      unorderedPrediction: AveragedPrediction,
      predictionKey: keyof AveragedPrediction,
      percentileKey: keyof AveragedPrediction,
      orderMap: Map<string, number>
    ) => {
      const singlePrediction = unorderedPrediction[predictionKey] as Block[];
      const singlePredictions = unorderedPrediction[percentileKey] as PercentilePredictions;

      const sortedSinglePrediction = singlePrediction ? sortBlocksByOrder(singlePrediction, orderMap) : undefined;
      const sortedSinglePredictions = singlePredictions
        ? Object.keys(singlePredictions).reduce((acc, percentile) => {
          acc[percentile] = sortBlocksByOrder(singlePredictions[percentile], orderMap);
          return acc;
        }, {} as PercentilePredictions)
        : undefined;

      return {
        [predictionKey]: sortedSinglePrediction,
        [percentileKey]: sortedSinglePredictions,
      };
    };

    function getActiveStops(stops?: Route[]): string[] {
      if (!stops) {
        return [];
      }

      const activeStops: string[] = [];
      let previousItem: Route | null = null;

      stops.forEach((item) => {
        if (item.meethalte && item.prediction_id !== undefined) {
          if (previousItem && previousItem.prediction_id !== undefined) {
            // If there is a previous item, set it as active as well
            activeStops.push(previousItem.prediction_id as string);
          }
          // Push the current item's prediction_id to activeStops
          activeStops.push(item.prediction_id as string);
        }
        // Update previousItem for the next iteration
        previousItem = item;
      });

      return activeStops;
    }

    function getTijdStops(stops?: Route[]): string[] {
      if (!stops) {
        return [];
      }

      const tijdStops: string[] = [];
      let previousItem: Route | null = null;

      stops.forEach((item) => {
        if (item.tijdhalte && item.prediction_id !== undefined) {
          if (previousItem && previousItem.prediction_id !== undefined) {
            // If there is a previous item, set it as active as well
            tijdStops.push(previousItem.prediction_id as string);
          }
          // Push the current item's prediction_id to activeStops
          tijdStops.push(item.prediction_id as string);
        }
        // Update previousItem for the next iteration
        previousItem = item;
      });

      return tijdStops;
    }

    function getActiveStopsCode(stops?: Route[]): string[] {
      if (!stops) {
        return [];
      }
      const activeStops: string[] = [];

      for (let i = 0; i < stops.length; i++) {
        const currentStop = stops[i];
        const nextStop = stops[i + 1];

        const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

        if (isTrack) {
          if (currentStop && nextStop.meethalte) {
            activeStops.push(currentStop.id_areacode_combination)
          }
        }
      }
      return activeStops
    }


    function getTimeStopsCode(stops?: Route[]): string[] {
      if (!stops) {
        return [];
      }
      const activeStops: string[] = [];

      for (let i = 0; i < stops.length; i++) {
        const currentStop = stops[i];
        const nextStop = stops[i + 1];

        const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

        if (isTrack) {
          if (currentStop && nextStop.tijdhalte) {
            activeStops.push(currentStop.id_areacode_combination)
          }
        }
      }
      return activeStops
    }


    const determineActiveForBlocks = (
      blocks: Block[],
      percentage: string,
      activeStops: string[],
      tijdStops: string[]
    ): Block[] => {
      const activeSet = new Set(
        activeStops.map((stop) => `${stop}_${percentage}`)
      );
      const tijdSet = new Set(
        tijdStops.map((stop) => `${stop}_${percentage}`)
      );

      return blocks.map((item) => {
        let meethalte = false;
        let tijdhalte = false;
        if (!item.id) {
          meethalte = false; // If there is no prediction for the item, set the active parameter to false
          tijdhalte = false;
        } else {
          meethalte = activeSet.has(item.id); // Check if the active set includes the id
          tijdhalte = tijdSet.has(item.id);
        }

        return { ...item, meethalte, tijdhalte };
      });
    };

    const determineActiveStops = (
      data: AveragedPrediction,
      activeStops: string[],
      tijdStops: string[],
    ): AveragedPrediction => {
      if (!data.single_prediction_dep && !data.single_predictions_dep) {
        throw new Error("There is no prediction available here");
      }

      // Update predictions for dep, arr, total, and halteer
      const updatedDep = updatePredictions(data, 'single_prediction_dep', 'single_predictions_dep', activeStops, tijdStops);
      const updatedArr = updatePredictions(data, 'single_prediction_arr', 'single_predictions_arr', activeStops, tijdStops);
      const updatedDepStart = updatePredictions(data, 'single_prediction_dep_start', 'single_predictions_dep_start', activeStops, tijdStops);
      const updatedArrStart = updatePredictions(data, 'single_prediction_arr_start', 'single_predictions_arr_start', activeStops, tijdStops);
      const updatedTotal = updatePredictions(data, 'single_prediction_total', 'single_predictions_total', activeStops, tijdStops);
      const updatedHalteer = updatePredictions(data, 'single_prediction_halteer', 'single_predictions_halteer', activeStops, tijdStops);
      const updateTraject = updatePredictions(data, 'single_prediction_traject', 'single_predictions_traject', activeStops, tijdStops);

      return {
        ...data,
        ...updatedDep,
        ...updatedArr,
        ...updatedDepStart,
        ...updatedArrStart,
        ...updatedTotal,
        ...updatedHalteer,
        ...updateTraject
      };
    };


    const checkAvailableData = (id: string): boolean => {
      if (!currentPrediction.value?.single_prediction_dep || !currentPrediction.value?.single_prediction_arr) {
        throw new Error("No current prediction available")
      }
      const prediction = currentPrediction.value.single_prediction_dep.find(data => data.id === id);
      if (prediction && prediction.available !== false) {
        return true
      }
      const predictionArr = currentPrediction.value.single_prediction_arr.find(data => data.id === id);
      if (predictionArr && predictionArr.available !== false) {
        return true
      }
      return false;
    }

    const getActiveTimeClusters = (): TimeClusterData[][] | undefined => {
      if (!props.linePlanningSettings.day_clusters) {
        throw new Error("There is no active day cluster, this should not be possible");
      }
      const activeDaysCluster = props.linePlanningSettings.day_clusters[props.activeDayCluster];
      const activeDays = activeDaysCluster.map((dayObj) => dayObj.day).join("_");
      const activeSeasonsCluster = props.linePlanningSettings.season_clusters[props.activeSeasonCluster];
      const activeSeasons = activeSeasonsCluster.map((season) => season.season).join("_");
      if (!props.linePlanningSettings.time_clusters) {
        throw new Error("there are no timeclusters according to typescript")
      }
      const activeTimeCluster = props.linePlanningSettings.time_clusters.find(
        (cluster) => cluster.day_cluster === activeDays && cluster.season_cluster === activeSeasons
      );
      return activeTimeCluster ? activeTimeCluster.time_cluster : undefined;
    };

    const predictionLookup = computed(() => {
      const map: Record<string, Block> = {};
      if (aggregatedPrediction.value?.single_prediction_dep) {
        aggregatedPrediction.value.single_prediction_dep.forEach((pred) => {
          map[pred.id as string] = pred;
        });
      }
      return map;
    });

    const predictionArrLookup = computed(() => {
      const map: Record<string, Block> = {};
      if (aggregatedPrediction.value?.single_prediction_arr) {
        aggregatedPrediction.value.single_prediction_arr.forEach((pred) => {
          map[pred.id as string] = pred;
        });
      }
      return map;
    });

    const halteerLookup = computed(() => {
      const map: Record<string, Block> = {};
      if (aggregatedPrediction.value?.single_prediction_halteer) {
        aggregatedPrediction.value.single_prediction_halteer.forEach((pred) => {
          const id = pred.id?.split('_').slice(0, pred.id.split('_').length - 1).join('_')
          map[id as string] = pred;
        });
      }
      return map;
    });

    const halteerLookupBlock = computed(() => {
      const map: Record<string, Block> = {};
      if (aggregatedPrediction.value?.single_prediction_halteer) {
        aggregatedPrediction.value.single_prediction_halteer.forEach((pred) => {
          map[pred.id as string] = pred;
        });
      }
      return map;
    });


    const getClusterId = () => {
      const activeStops = getActiveStopsCode(props.linePlanningSettings.stop_combinations).join('_');
      const timeStops = getTimeStopsCode(props.linePlanningSettings.stop_combinations).join('_');
      return standardPercentage.value + '_' + activeStops + '_' + timeStops + '_' + dayIdentifier.value + "_" + timeIdentifier.value + "_" + seasonIdentifier.value;
    };


    const findMeethaltePredictionById = (id: string, traject: Block[]): number => {
      let codeMeethalte = id.split('_')[4];
      let trajectPrediction = traject.find(item => {
        let code = item.id?.split('_')[4];
        return code === codeMeethalte
      })?.time
      return trajectPrediction ? trajectPrediction : 0;
    }

    const getTracksFromStopCombinations = (stops: Route[]): Route[] => {
      const filteredStops: Route[] = [];
      for (let i = 0; i < stops.length; i++) {
        const currentStop = stops[i];
        const nextStop = stops[i + 1];

        const isTrack = currentStop.id_areacode_combination?.split('_')[0] !== currentStop.id_areacode_combination?.split('_')[1];

        if (isTrack) {
          if (currentStop && nextStop.meethalte) {
            currentStop.meethalte = true;
          }
          else if (currentStop && nextStop.tijdhalte) {
            currentStop.tijdhalte = true;
          }
          filteredStops.push(currentStop);
        }
      }
      return filteredStops;
    };

    const translateSumToBlock = () => {
      calculatePercentages();
      distributeInputProportionally();
      handleSaveToMemory();
    };

    const convertSettingsToDayStrings = (): string[] => {
      const dayStrings = props.linePlanningSettings.day_clusters
      let clusterLengths: number[] = [];
      const formattedDayStrings = dayStrings.map(cluster => {
        const daysMapped = cluster.map(day => dayMapper[day.day?.toLowerCase() || ''] || '');
        if (daysMapped.length === 1) {
          clusterLengths.push(1);
          return daysMapped[0]; // Return the single day
        } else {
          clusterLengths.push(daysMapped.length)
          return daysMapped[0] + ' - ' + daysMapped[daysMapped.length - 1]; // Return the first and last day
        }
      });
      dayClusterInfoLength.value = clusterLengths[props.activeDayCluster]
      return formattedDayStrings;
    };



    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 6. State management and loading. //////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    watch(
      () => props.timeClusterTrigger,
      async () => {
        setTimeClusterInfo();
        updateTimeDayIdentifiers();
        await setCurrentPrediction();
        await runSchedule();
        handleChosenTimesInitialization();
        handleSaveToMemory();
      }
    );

    const handleOnMounted = async () => {
      setTimeClusterInfo();
      setDayClusterInfo();
      calculateAvailableTableHeigth();
      addEventListener('resize', calculateAvailableTableHeigth);
      updateTimeDayIdentifiers();
      setCurrentPrediction();
      await runSchedule();
      handleChosenTimesInitialization();
      handleSaveToMemory();

    };

    onMounted(handleOnMounted)

    const handleSaveToMemory = () => {
      if (chosenTimes.value.length > 1) {
        emit("update-chosen-times", getClusterId(), chosenTimes.value, totalsumchosen.value, props.chosenMemory, totalPercentageChosen.value);
      } else {
        console.warn("Tried to send empty chosenTimes into memory")
      }
    };


    const setPercentageButton = () => {
      emit("set-percentage", seasonIdentifier.value, dayIdentifier.value, timeIdentifier.value, totalPercentageChosen.value)
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    // 7. Pure UI visual functions. //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////

    const calculateAvailableTableHeigth = () => {
      if (timeClusterInfoDiv.value) {
        const rect = timeClusterInfoDiv.value.getBoundingClientRect();
        availableTableHeight.value = window.innerHeight - rect.bottom - 64;
      }
    };

    const determineButtonClassUp = (value: number) => {
      if (value >= 99) {
        return 'text-gray-200'
      } else {
        return ''
      }
    }

    const determineButtonClassDown = (value: number) => {
      if (value <= 1) {
        return 'text-gray-200'
      } else {
        return ''
      }
    }


    const computeRowClass = (route: Route, index: number): string => {
      if (collapseTable.value) {
        if (route.type === 'halteer') {
          return " bg-gray-50 text-gray-400 "
        }
        return "";
      }

      if (route.type === 'meethalte_totaal') {
        return " !bg-green-100 font-bold border-b-2 border-t-2 border-gray-800";
      }
      if (route.type === 'halteer_block') {
        return " !bg-yellow-100 font-bold border-b-2 border-gray-800"
      }
      if (route.type === 'tijdhalte_totaal') {
        return " !bg-orange-100 font-bold border-b-2 border-t-2 border-gray-800";
      }
      const chosenTime = chosenTimes.value.find((item) => item.blockId === route.prediction_id)

      if (chosenTime && chosenTime.missing && chosenTime.inputBlock === 0) {
        return " !bg-red-200";
      } else if (chosenTime && chosenTime.missing) {
        return " !bg-red-200"
      }

      if (index % 2 === 1) {
        return " !bg-gray-50"
      } else {
        return " !bg-white"
      }
    };

    const setDayClusterInfo = () => {
      dayClusterInfo.value = convertSettingsToDayStrings()[props.activeDayCluster];
    };

    const toggleCollapseTable = () => {
      collapseTable.value = !collapseTable.value;
    };

    const handleSubmit = (event: Event) => {
      event.preventDefault();
    };

    const handleEnter = (event: KeyboardEvent) => {
      (event.target as HTMLInputElement).blur();
    };

    const setTimeClusterInfo = () => {
      const timeArray = convertSettingsToTimeStrings(getActiveTimeClusters())[props.activeTimeCluster];
      if (timeArray.length === 1) {
          const timeParts = timeArray[0].split(':');
          const hours = (parseInt(timeParts[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
          const minutes = timeParts[1];
          timeClusterInfo.value = `${hours}:${minutes}`;
          timeClusterInfoLength.value = timeArray.length;
      } else {
        const timePartsStart = timeArray[0].split(':');
        const hoursStart = (parseInt(timePartsStart[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
        const minutesStart = timePartsStart[1];
        const timePartsEnd = timeArray[timeArray.length - 1].split(':');
        const hoursEnd = (parseInt(timePartsEnd[0]) % 24).toFixed(0).padStart(2, '0');  // Pad single-digit hours
        const minutesEnd = timePartsEnd[1];
        timeClusterInfo.value = `${hoursStart}:${minutesStart} - ${hoursEnd}:${minutesEnd}`;
        timeClusterInfoLength.value = timeArray.length;
      }
    };
    return {
      timeClusterInfo,
      timeClusterInfoLength,
      dayClusterInfo,
      dayClusterInfoLength,
      getActiveStops,
      aggregatedPrediction,
      activeStopCombinations,
      predictionLookup,
      halteerLookup,
      collapseTable,
      toggleCollapseTable,
      displayedStopCombinations,
      handleEnter,
      handleSubmit,
      chosenTimes,
      updateChosenTimeSum,
      computeRowClass,
      formatTimeInput,
      secondsToMMSS,
      startIncrementSum,
      stopIncrement,
      availableTableHeight,
      timeClusterInfoDiv,
      checkAvailableData,
      incrementPercentageSum,
      startIncrementPercentageSum,
      totalsum,
      totalsumchosen,
      lineNameLocal,
      journeyPatternCodeLocal,
      linePublicNumberLocal,
      formatPercentInput,
      determineButtonClassUp,
      determineButtonClassDown,
      beginAndEndStop,
      standardPercentage,
      updateChosenPercentSum,
      stopIncrementPercentageSum,
      totalPercentageChosen,
      getSchedule,
      totalSchedule,
      getScheduleSum,
      totalscheduledtime,
      getChosenTimeSum,
      getChosenPercentageSum,
      getChosenTimeBlock,
      getChosenPercentageBlock,
      updateTotalChosenTime,
      startIncrementTotal,
      startIncrementPercentageTotal,
      updateTotalPercentage,
      stopIncrementTotal,
      stopIncrementPercentageTotal,
      mmssToSeconds,
      updateChosenTimeBlock,
      getChosenTimeFromStart,
      getChosenPercentageFromStart,
      getChosenMeetFromStart,
      getChosenTimeBlockFromStart,
      predictionArrLookup,
      getChosenPercentageBlockArr,
      halteerLookupBlock,
      getChosenMeetPercentageFromStart,
      setPercentageButton,
      getChosenTimeSumTimeStop,
      updateChosenTimeBlockPercentage
    };
  },
});
</script>

<style scoped></style>
